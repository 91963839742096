import React, { useState } from 'react';
import { Typography, Grid, MenuItem, TextField } from '@material-ui/core';
import { getLocalizedString } from "@icarius-localization/strings";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DefaultImage from "@icarius-assets/images/no_profile_dark.png";
import { getAppColor } from "src/app/selectors";
import DotsMenu from "@icarius-common/dotsMenu";
import { useDispatch, useSelector } from 'react-redux';
import { updateNoteCommentAction, deleteNoteCommentAction } from "../../actions";

const CommentMobile = (props) => {

    const [isModifying, setIsModifying] = useState(false);
    const [commentText, setCommentText] = useState("");

    const {
        comment,
        author,
        isAuthor,
    } = props;

    const dispatch = useDispatch();
    const color = useSelector(getAppColor);

    const handleOpenEditDialog = () => {
        setIsModifying(true);
        setCommentText(comment.text || "");
    }

    const handleDelete = (code) => {
        dispatch(deleteNoteCommentAction(code))
    }

    const handleGenerate = () => {
        // Si tengo algo, entonces lo mando
        if (commentText !== "" && comment.code) {
            dispatch(updateNoteCommentAction(comment.code, commentText))
                .then((resp) => {
                    if (resp.data.status === "OK") {
                        setIsModifying(false);
                    }
                });

            setCommentText("");
        }
    }

    return (
        <Grid container item xs={12} style={{ paddingBottom: 20 }}>
            <Grid container item xs={12}>
                <Grid container alignItems="center" item xs={12}>
                    <Grid item xs={2}>
                        {
                            author && author["FOTOGRAFIA"] && author["FOTOGRAFIA"] !== "-" && author["FOTOGRAFIA"] !== "" ?
                                <img
                                    src={IMAGES_ENDPOINT + author["FOTOGRAFIA"]}
                                    onError={event => event.target.src = DefaultImage}
                                    alt={comment.author}
                                    className={"employee-image"}
                                    style={{ height: 50, width: 50 }}
                                />
                                :
                                <div className={"employee-image"} style={{ background: color }} />
                        }
                    </Grid>
                    <Grid item xs={9} style={{ paddingLeft: 20 }}>
                        <Typography className="text-14 ml-auto mr-4 whiteText" style={{ fontWeight: 400 }}>
                            {comment.author}
                        </Typography>
                    </Grid>
                    {
                        isAuthor &&
                        <Grid container direction="row" justify="flex-end" item xs={1}>
                            <DotsMenu>
                                <MenuItem onClick={() => handleOpenEditDialog(comment.code)}>
                                    {getLocalizedString("edit")}
                                </MenuItem>
                                <MenuItem onClick={() => handleDelete(comment.code)}>
                                    {getLocalizedString("delete")}
                                </MenuItem>
                            </DotsMenu>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {
                !isModifying &&
                <Grid container item xs={12}>
                    <Grid item xs={2} />
                    <Grid item xs={9} style={{ paddingLeft: 20 }}>
                        <Typography className="text-12 ml-auto mr-4 whiteText" style={{ fontWeight: 400, textAlign: "justify", wordBreak: "break-word" }}>
                            {comment.text}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={9} style={{ paddingTop: 5, paddingLeft: 20 }}>
                        <Typography className="text-14 ml-auto mr-4 whiteText" style={{ fontWeight: 300 }}>
                            {`${comment.date} ${comment.time}`}
                        </Typography>
                    </Grid>
                </Grid>
            }
            {
                isAuthor && isModifying &&
                <Grid container item xs={12} style={{ marginTop: -25 }}>
                    <Grid item xs={12} style={{ marginTop: 35 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    placeholder={getLocalizedString("writeHereYourComment")}
                                    multiline={true}
                                    rows={2}
                                    value={commentText}
                                    onChange={(e) => setCommentText(e.target.value.toString())}
                                />
                            </Grid>
                            <Grid container item justify="flex-end" xs={12} style={{ paddingTop: 10 }}>
                                <ButtonDialogAction
                                    onClick={handleGenerate}
                                    text={getLocalizedString("editCommentAction")}
                                    isAccept
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

export default CommentMobile;