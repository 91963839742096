import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import {
  createDateFromDDMMYYYY,
  formatTime,
  createDateTimeFromDDMMYYYY,
  formatDateYYYYMMDD,
} from "@icarius-utils/date";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { addSignerAction, editSignerAction } from "../../actions";

const gridStyle = { height: 75, padding: "0px 10px" };

const SignerContent = (props) => {

  const {
    docs,
    managersEmployers,
    signer,
    isForUpdate,
    handleClose,
  } = props;

  const dispatch = useDispatch();
  const shouldApplyMediaQuery = useMediaQuery('(max-width:600px)'); // TODO: revisar

  const [formData, setFormData] = useState(() => {
    if (signer && Object.keys(signer).length > 0) {
      return {
        user: signer.userCode,
        legalName: signer.legalName,
        documents: signer.typeDocs.map(doc => doc.id),
        dateEnd: signer.dateExp === "31/12/9999" ? "" : createDateFromDDMMYYYY(signer.dateExp),
        hourEnd: signer.dateExp === "31/12/9999" && signer.timeExp === "23:59" ? "" : createDateTimeFromDDMMYYYY(signer.dateExp, signer.timeExp),
        dateIni: createDateFromDDMMYYYY(signer.dateIni),
        hourIni: createDateTimeFromDDMMYYYY(signer.dateIni, signer.timeIni),
      };
    } else {
      return {
        user: "",
        legalName: "",
        documents: [],
        dateEnd: "",
        hourEnd: "",
        dateIni: "",
        hourIni: "",
      };
    }
  })

  const setFormValue = (value, fieldName) => {
    if (fieldName === "user") {
      const legalName = managersEmployers.find((subItem) => subItem.code === value);

      setFormData({
        ...formData,
        legalName: legalName ? legalName.name : formData.legalName,
        user: value,
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  const dataIsComplete = () => {
    if ((!formData.hourIni && formData.dateIni) || (formData.hourIni && !formData.dateIni)) return false;
    if ((!formData.hourEnd && formData.dateEnd) || (formData.hourEnd && !formData.dateEnd)) return false;
    if (formData.documents.length && formData.user && formData.legalName) return true;
    return false;
  };

  const validateDatesAreCorrect = () => {
    let datesAreCorrect = false;
    let dateEndWithTime, dateIniWithTime;

    if (!formData.hourEnd) return true;
    if (!formData.hourIni) {
      dateIniWithTime = new Date();
      dateEndWithTime = formData.dateEnd;
      dateEndWithTime.setHours(formData.hourEnd.getHours());
    } else {
      dateIniWithTime = formData.dateIni;
      dateIniWithTime.setHours(formData.hourIni.getHours());

      dateEndWithTime = formData.dateEnd;
      dateEndWithTime.setHours(formData.hourEnd.getHours());
    }

    datesAreCorrect = dateEndWithTime > dateIniWithTime;

    if (!datesAreCorrect) dispatch(openSnackbarAction({ msg: getLocalizedErrorString("verifyDateTime"), severity: "error" }));

    return datesAreCorrect;
  }

  const validateAndCreate = () => {

    if (dataIsComplete() && validateDatesAreCorrect()) {
      // limpiar el typedocs por si deja de venir algun doc desde back
      const typeDocs = formData.documents.filter((selectedDocs) => docs.find((doc) => doc.id === selectedDocs));

      const signerToSend = {
        "idSigner": formData.user,
        "legalName": formData.legalName,
        "typeDocs": typeDocs.join(", "),
        "fechaIni": formData.dateIni ? formatDateYYYYMMDD(formData.dateIni) : null,
        "tiempoIni": formData.hourIni ? formatTime(formData.hourIni) : null,
        "fechaExp": formData.dateEnd ? formatDateYYYYMMDD(formData.dateEnd) : null,
        "tiempoExp": formData.hourEnd ? formatTime(formData.hourEnd) : null,
      };

      dispatch(isForUpdate ? editSignerAction(signerToSend) : addSignerAction(signerToSend));
      handleClose();
    }
  };

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12}>
          <Typography
            className={!dataIsComplete() ? "errorColor" : "whiteText"}
            variant="caption"
            gutterBottom
          >
            {getLocalizedString("fieldRequeriment")}
          </Typography>
        </Grid>
        <Grid container item alignItems="center" sm={12} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-user`}>{getLocalizedString("user")}</InputLabel>
            <Select
              value={formData.user}
              labelId={`label-user`}
              id={`select-user`}
              onChange={(e) => setFormValue(e.target.value, "user")}
              margin={"none"}
            >
              {
                managersEmployers.map((subItem, index) => (
                  <MenuItem
                    className={"whiteText"}
                    key={index}
                    value={subItem.code}
                  >
                    {subItem.name}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, marginTop: 10 }}>
          <TextField
            required
            margin={"none"}
            label={getLocalizedString("nameSurnameLegalSigner")}
            value={formData.legalName}
            onChange={(e) => setFormValue(e.target.value.toString().slice(0, 84), "legalName")}
            helperText={getLocalizedString("captionLegalName")}
            fullWidth
          />
        </Grid>
        <Grid container item alignItems="center" sm={12} md={6} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-documents`}>{getLocalizedString("authorizedDocuments")}</InputLabel>
            <Select
              value={formData.documents}
              labelId={`label-documents`}
              id={`select-documents`}
              onChange={(e) => setFormValue(e.target.value, "documents")}
              margin={"none"}
              multiple
            >
              {
                docs.map((subItem, index) => (
                  <MenuItem
                    className={"whiteText"}
                    key={index}
                    value={subItem.id}
                  >
                    {subItem.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" sm={12} md={6} style={{ minHeight: 75, padding: "0px 10px" }}>
          <Grid container item xs={6} sm={3} style={{ paddingRight: 15 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                required
                label={getLocalizedString("dateFrom")}
                clearable
                clearLabel={getLocalizedString("clear")}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                disablePast
                format="dd/MM/yyyy"
                margin={"none"}
                invalidDateMessage=''
                minDateMessage=''
                maxDateMessage=''
                value={formData.dateIni}
                onChange={(e) => setFormValue(e, "dateIni")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item xs={6} sm={3} style={{ paddingRight: shouldApplyMediaQuery ? 0 : 15 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <TimePicker
                required
                label={getLocalizedString("hourFrom")}
                clearLabel={getLocalizedString("clear")}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                invalidDateMessage=""
                value={formData.hourIni}
                format="HH:mm"
                margin={"none"}
                ampm={false}
                onChange={(e) => setFormValue(e, "hourIni")}
                clearable
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item xs={6} sm={3} style={{ paddingRight: 15 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                required
                label={getLocalizedString("dateEnd")}
                clearable
                clearLabel={getLocalizedString("clear")}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                disablePast
                format="dd/MM/yyyy"
                margin={"none"}
                invalidDateMessage=''
                minDateMessage=''
                maxDateMessage=''
                value={formData.dateEnd}
                onChange={(e) => setFormValue(e, "dateEnd")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item xs={6} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <TimePicker
                required
                label={getLocalizedString("hourEnd")}
                clearLabel={getLocalizedString("clear")}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                invalidDateMessage=""
                value={formData.hourEnd}
                format="HH:mm"
                margin={"none"}
                ampm={false}
                clearable
                onChange={(e) => setFormValue(e, "hourEnd")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
          <ButtonDialogAction isAccept onClick={validateAndCreate} text={getLocalizedString("agree")} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SignerContent;