import React from "react";
import { Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import SectionContainer from "./sectionContainer";
import ImageInput from "../imageInput";

const LogosAndCustomization = (props) => {

    const {
        title,
        sectionFields,
        formData,
        setFormValue,
    } = props;

    return (
        <SectionContainer
            title={title}
        >
            <Grid container item xs={12}>
                {
                    sectionFields[0].map((item, itemIndex) => {
                        const field = Object.values(item)[0];
                        const fieldName = Object.keys(item)[0];

                        return (
                            <Grid
                                key={itemIndex}
                                container item alignItems="flex-start" xs={12} sm={6} lg={3}
                                style={{ height: 250 }}
                            >
                                <ImageInput
                                    name={fieldName}
                                    label={field.name || getLocalizedString(fieldName)}
                                    value={formData[fieldName]}
                                    handleChange={setFormValue}
                                />
                            </Grid>
                        );
                    })
                }
            </Grid>
        </SectionContainer>
    )
}

export default LogosAndCustomization;