import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
    numberComparatorMax2,
    numberFormatterMin0Max2,
    numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.vacations],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.vacations],
        config: {
            headerName: 'Estado',
            field: "ESTADO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.vacations],
        config: {
            headerName: "Personal de planta",
            field: "PERSONAL DE PLANTA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Ganados (legales y otros)",
            field: "creditLegal",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Ganados (adicionales o progresivos)",
            field: "creditAditional",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Total de días ganados",
            field: "creditTotal",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Utilizados (legales y otros)",
            field: "debitUsedLegal",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Utilizados (adicionales o progresivos)",
            field: "debitUsedAditional",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Total de días utilizados",
            field: "debitUsedTotal",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Por utilizar a futuro (legales y otros)",
            field: "creditUnusedLegal",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Por utilizar a futuro (adicionales o progresivos)",
            field: "creditUnusedAditional",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Total de días por utilizar a futuro",
            field: "creditUnusedTotal",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Saldo (legales y otros)",
            field: "debitAvailableLegal",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Saldo (adicionales o progresivos)",
            field: "debitAvailableAditional",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Saldo total disponible para solicitar",
            field: "debitAvailableTotalToRequest",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config:
        {
            headerName: "Saldo total disponible (si se desvincula)",
            field: "debitAvailableTotalIfFired",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacations],
        config: {
            headerName: "Tipo de funcionario",
            field: "functionaryType",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.vacations],
        config: {
            headerName: "Tipo de contrato",
            field: "contractType",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.vacations],
        config: {
            headerName: 'Lugar de trabajo',
            field: 'workplace',
            filter: 'agSetColumnFilter',
        }
    },
    {
        pages: [paths.vacations],
        config: {
            headerName: 'Obra o Faena',
            field: "workOrTask",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.vacations],
        config: {
            headerName: 'Gerencia',
            field: "management",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.vacations],
        config: {
            headerName: 'Departamento',
            field: "department",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.vacations],
        config: {
            headerName: 'Centro de costo de gestión',
            field: 'costCenter',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.vacations],
        config: {
            headerName: 'Cargo',
            field: "position",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.vacations],
        config: {
            headerName: 'Función',
            field: "function",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.vacations],
        config: {
            headerName: 'Fecha de actualización de la Cta.Cte.',
            field: 'vacationsUpdateDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },

    // vacationsDetailCredit
    {
        pages: [paths.vacationsDetailCredit],
        config:
        {
            headerName: getLocalizedString("year"),
            field: "year",
            filter: "agTextColumnFilter",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.vacationsDetailCredit],
        config:
        {
            headerName: getLocalizedString("month"),
            field: "months",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.vacationsDetailCredit],
        config:
        {
            headerName: getLocalizedString("legalDays"),
            field: "legal_days",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacationsDetailCredit],
        config:
        {
            headerName: getLocalizedString("additionalDays"),
            field: "additional_days",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacationsDetailCredit],
        config:
        {
            headerName: getLocalizedString("otherDays"),
            field: "other_days",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacationsDetailCredit],
        config:
        {
            headerName: getLocalizedString("total"),
            field: "total",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacationsDetailCredit],
        config:
        {
            headerName: getLocalizedString("description"),
            field: "description",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.vacationsDetailCredit],
        config:
        {
            headerName: getLocalizedString("usedLegalDays"),
            field: "used_legal_days",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacationsDetailCredit],
        config:
        {
            headerName: getLocalizedString("usedAdditionalDays"),
            field: "used_additional_days",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacationsDetailCredit],
        config:
        {
            headerName: getLocalizedString("pendingDays"),
            field: "pending_days",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacationsDetailDebit],
        config: {
            headerName: getLocalizedString("startDate"),
            field: "start_date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
            cellRenderer: "HolidaysDateRenderer",
        }
    },
    {
        pages: [paths.vacationsDetailDebit],
        config: {
            headerName: getLocalizedString("endDate"),
            field: "end_date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellRenderer: "HolidaysDateRenderer",
        }
    },
    {
        pages: [paths.vacationsDetailDebit],
        config:
        {
            headerName: getLocalizedString("movement"),
            field: "movement",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.vacationsDetailDebit],
        config:
        {
            headerName: getLocalizedString("dailyQuantity"),
            field: "daily_quantity",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacationsDetailDebit],
        config:
        {
            headerName: getLocalizedString("daysQuantity"),
            field: "days_quantity",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.vacationsDetailDebit],
        config:
        {
            headerName: getLocalizedString("comments"),
            field: "comments",
            filter: "agTextColumnFilter",
        }
    },
]