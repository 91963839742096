import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  DialogContent,
  DialogActions,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";
import { dateIsAfterToday, formatDate, createDateFromDDMMYYYY } from "@icarius-utils/date";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import moment from "moment-business-days";
import CantCreateRequestMessage from "./cantCreateRequestMessage";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { requestsIds } from "@icarius-utils/requestsIds";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";

const gridStyle = { minHeight: 75 };

const timesPerDayArrayTypeDay = [
  { FldValue: "0.5", Descr: requestsIds.id_time_per_day["0.5"] },
  { FldValue: "1", Descr: requestsIds.id_time_per_day["1.0"] },
];

const createDateFromHHMM = (value) => {
  let newTime = new Date();
  newTime.setHours(value.substring(0, 2));
  newTime.setMinutes(value.substring(3, 5));
  newTime.setSeconds(0);
  return newTime;
}

const decToMM = (decimalTimeString) => {
  let n = new Date(0, 0);
  n.setSeconds(decimalTimeString * 60 * 60);
  n.setMinutes(decimalTimeString * 60);
  return n.toTimeString().slice(3, 5);
}

const getIsClassC = (arrayToFind, type) => {
  if (type) return arrayToFind.find((item) => item.FldValue === type).class === "C";
  return false;
}

const NewPermissionContent = (props) => {

  const {
    isReadOnly,
    validateDateRange,
    typesPermission,
    permissionCalculationData,
    editData,
    isForEdit,
    requestCode,
    recipients,
    handleRequest,
    handleNoRecipient,
    handleViewBalance,
    handleClose,
  } = props;

  useEffect(() => {
    if (!isReadOnly && !isForEdit && recipients.permission.length === 0) {
      handleNoRecipient();
      handleClose();
    }
  }, [isReadOnly, isForEdit, recipients, handleNoRecipient, handleClose])

  const dispatch = useDispatch();
  const [formData, setFormData] = useState(() => {
    const timePerDay = (() => {
      if (!isForEdit && !isReadOnly) return "";
      return editData.unity === "H" ? createDateFromHHMM(editData.id_time_per_day) : editData.id_time_per_day;
    })();

    return {
      receptor: recipients?.permission?.length === 1 ? recipients.permission[0].code : "", //si tiene un valor solo, inicializar con ese
      type: editData?.id_subtype || "",
      date: editData?.start_date ? createDateFromDDMMYYYY(editData.start_date) : null,
      timePerDay: timePerDay,
      amountOfDays: editData?.duration_days?.toString() || "",
      description: editData?.comments || "",
      dateEnd: editData?.end_date ? createDateFromDDMMYYYY(editData.end_date) : null,
    };
  })

  const setFormValue = (value, fieldName) => {
    if (fieldName === "amountOfDays") {
      setFormData((prev) => ({
        ...formData,
        [fieldName]: value,
        dateEnd: calculateEndPermissionDate(prev.date, value, prev.type),
      }));
      return;
    }

    if (fieldName === "type") {
      setFormData({
        ...formData,
        [fieldName]: value,
        dateEnd: null,
        timePerDay: "",
        amountOfDays: "",
      });
      return;
    }

    if (fieldName === "date") {
      setFormData((prev) => ({
        ...formData,
        [fieldName]: value,
        dateEnd: calculateEndPermissionDate(value, prev.amountOfDays, prev.type)
      }));
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  const handleCloseAndReload = (shouldReloadTable) => {
    if (isForEdit && shouldReloadTable) {
      handleClose(true);
    } else {
      handleClose();
    }
  };

  const timePerDayIsValid = () => {
    const { timePerDay, type } = formData;

    if (!timePerDay || !type) return false;

    const typeSelected = getPermissionTypeSelected(type);

    if (!typeSelected) return false;

    let maxHour = null;
    let maxMinutes = null;

    if (editData && editData.hasOwnProperty("cant_max")) {
      maxHour = Math.floor(editData.cant_max);
      maxMinutes = parseInt(decToMM(editData.cant_max));
    } else {
      maxHour = Math.floor(typeSelected.cant_max);
      maxMinutes = parseInt(decToMM(typeSelected.cant_max));
    }

    if (typeSelected.unidad !== "H") return true;

    if (isForEdit) {
      const originalDate = createDateFromHHMM(editData.id_time_per_day);

      //si la fecha nueva tiene hora mayor, error
      if (originalDate.getHours() < timePerDay.getHours()) return false;

      //si las fechas tienen misma hora, pero la nueva tiene minutos mayor, error
      if (originalDate.getHours() === timePerDay.getHours() && originalDate.getMinutes() < timePerDay.getMinutes()) return false;
    }

    if (timePerDay.getHours() === 0 && timePerDay.getMinutes() < 10) return false; //si es menos de 00:10
    if (timePerDay.getHours() > maxHour) return false; //si es mas de max hour
    if (timePerDay.getMinutes() > maxMinutes && timePerDay.getHours() === maxHour) return false; //si es mas de max minutes
    return true;
  }

  const calculateNextDay = () => {
    // A partir del siguiente dia
    let i = 1;
    let fechaBase = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + i);

    return fechaBase;
  }

  const textIsValid = () => {
    if (isForEdit) return formData.description.length > 0 && formData.description.length <= 1024;
    return !formData.description || (formData.description.length >= 0 && formData.description.length <= 1024);
  };

  const dataIsComplete = () => {
    if (!isForEdit && !isReadOnly && !formData.receptor) return false;
    if (formData.type && formData.amountOfDays && formData.timePerDay && textIsValid() && dateIsAfterToday(formData.date)) return true;
    return false;
  };

  const validateAndCreate = () => {
    if (textIsValid() && dataIsComplete() && timePerDayIsValid()) {
      if (validateDateRange(formData.date, formData.dateEnd)) {
        const request = {
          code: requestCode,
          id_type: "PERM",
          id_status: "3",
          id_subtype: formData.type,
          id_time_per_day: getPermissionTypeSelected(formData.type).unidad === "H" ? formData.timePerDay.toTimeString().split(' ')[0].substring(0, 5) : formData.timePerDay,
          duration_days: formData.amountOfDays,
          start_date: formatDate(formData.date),
          end_date: formatDate(formData.dateEnd),
          comments: formData.description,
          receptor: formData.receptor,
        }

        if (!isForEdit) {
          delete request.code;
          delete request.id_status;
        } else {
          delete request.id_type;
          delete request.id_subtype;
          delete request.receptor;
        }

        handleRequest(request);
        if (!isForEdit) {
          handleCloseAndReload(true);
        }
      } else {
        dispatch(openSnackbarAction({ msg: getLocalizedString("datesCollide"), severity: "error" }));
      }
    } else {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("completeWithValidInformation"), severity: "error" }));
    }
  };

  const createTimesPerDayArray = () => {
    const type = getPermissionTypeSelected(formData.type);

    if (!type) return [];
    if (type.unidad === "D") return timesPerDayArrayTypeDay;
    return [...createTimesPerDayArrayTypeHour(isForEdit ? editData.id_time_per_day : type.cant_max)];
  }

  const createPermissionDaysArray = () => {
    const maxAmount = (() => {
      if (!isForEdit && !isReadOnly) {
        const type = getPermissionTypeSelected(formData.type);

        if (type) return type.unidad === "D" ? type.cant_max : 1;
        return [];
      }

      return editData.duration_days;
    })();

    let auxArray = [];
    let cont = 1;

    do {
      auxArray.push({ FldValue: cont, Descr: cont });
      cont++;
    } while (cont <= maxAmount)

    return auxArray;
  }

  const getPermissionTypeSelected = (id_type) => {
    return typesPermission.find((type) => type.code_type === id_type);
  }

  const calculateEndPermissionDate = (startDate, amountOfDays, id_type) => {
    if (startDate && amountOfDays) {
      if (permissionCalculationData.does_workweek_include_saturdays) {
        moment.updateLocale('es', {
          workingWeekdays: [1, 2, 3, 4, 5, 6]
        });
      } else {
        moment.updateLocale('es', {
          workingWeekdays: [1, 2, 3, 4, 5]
        });
      }

      const type = getPermissionTypeSelected(id_type);

      if (type.include_holidays_and_weekends) return moment(startDate, "DD/MM/YYYY").add(amountOfDays - 1, 'days').toDate();

      moment.updateLocale('es', {
        holidays: permissionCalculationData.holiday_calendar,
        holidayFormat: 'DD/MM/YYYY'
      });

      let initialDay = moment(startDate, "DD/MM/YYYY");
      // Tengo que avanzar hasta el proximo dia habil, en caso de que este no lo sea
      if (!initialDay.isBusinessDay()) {
        initialDay = initialDay.nextBusinessDay();
      }

      return initialDay.businessAdd(amountOfDays - 1, 'days').toDate();
    }

    return null;
  }

  const createTypesArray = () => {
    return typesPermission.map((type) => {
      return { FldValue: type.code_type, Descr: type.name, class: type.clase };
    })
  }

  const createTimesPerDayArrayTypeHour = (cantMax) => {
    let timesPerDayArrayTypeHour = [{ FldValue: "0.5", Descr: "Media Hora" }];

    for (let i = 1; i <= cantMax; i++) {
      timesPerDayArrayTypeHour.push({ FldValue: i, Descr: `${i} hrs` })
    }

    return timesPerDayArrayTypeHour;
  }

  const vacationsTypeArray = createTypesArray();
  const permissionDaysArray = createPermissionDaysArray();
  const timesPerDayArray = createTimesPerDayArray();

  if (!isForEdit && !isReadOnly && !permissionCalculationData.can_user_appeal) return <CantCreateRequestMessage />;

  return (
    <>
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container direction="column" justify="center">
          {
            getIsClassC(vacationsTypeArray, formData.type) &&
            <Typography className="whiteText" style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={handleViewBalance}>
              {"Ver saldos"}
            </Typography>
          }
          <Grid container direction="row" justify="center" item>
            {
              !isForEdit && !isReadOnly &&
              <Grid container item xs={12} alignItems="center" style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-receptor`}>{getLocalizedString("requestReceptor")}</InputLabel>
                  <Select
                    disabled={isForEdit || isReadOnly}
                    value={formData.receptor}
                    labelId={`label-receptor`}
                    id={`select-receptor`}
                    onChange={(e) => setFormValue(e.target.value, "receptor")}
                    margin={"none"}
                  >
                    {
                      recipients.permission.map((item) => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.code}
                          value={item.code}
                        >
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
            <Grid container item xs={12} alignItems="center" style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required={!isReadOnly} id={`label-type`}>{getLocalizedString("permissionType")}</InputLabel>
                <Select
                  disabled={isForEdit || isReadOnly}
                  value={formData.type}
                  labelId={`label-type`}
                  id={`select-type`}
                  onChange={(e) => setFormValue(e.target.value, "type")}
                  margin={"none"}
                >
                  {
                    vacationsTypeArray.map((item) => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.FldValue}
                        value={item.FldValue}
                      >
                        {item.Descr}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item xs={12} alignItems="center" style={{ marginTop: 10 }}>
            <Grid container item xs={12} sm={6} alignItems="flex-start" style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  required={!isReadOnly}
                  disabled={isReadOnly}
                  label={getLocalizedString("startDate")}
                  clearable
                  clearLabel={getLocalizedString("clear")}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  disablePast={!isForEdit}
                  minDate={!isReadOnly && calculateNextDay()}
                  format="dd/MM/yyyy"
                  margin="none"
                  invalidDateMessage=''
                  minDateMessage=''
                  maxDateMessage=''
                  value={formData.date}
                  onChange={(e) => setFormValue(e, "date")}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
              {
                !isReadOnly &&
                <Typography
                  className={!dateIsAfterToday(formData.date) ? "errorColor" : "whiteText"}
                  variant="caption"
                  gutterBottom>
                  {getLocalizedString("startDateValidation")}
                </Typography>
              }
            </Grid>
            <Grid container item xs={12} sm={6} alignItems="flex-start" className="secondFieldPadding" style={gridStyle}>
              {
                (formData.type && getPermissionTypeSelected(formData.type).unidad === "H") ?
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <TimePicker
                      disabled={isReadOnly}
                      required={!isReadOnly}
                      minutesStep={5}
                      label={getLocalizedString("timePerDay")}
                      clearable
                      clearLabel={getLocalizedString("clear")}
                      cancelLabel={getLocalizedString("cancel")}
                      okLabel={getLocalizedString("ok")}
                      value={formData.timePerDay || null}
                      onChange={(e) => setFormValue(e, "timePerDay")}
                      format="HH:mm"
                      margin="none"
                      ampm={false}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                  :
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel required={!isReadOnly} id={`label-timePerDay`}>{getLocalizedString("timePerDay")}</InputLabel>
                    <Select
                      disabled={isReadOnly}
                      value={formData.timePerDay}
                      labelId={`label-timePerDay`}
                      id={`select-timePerDay`}
                      onChange={(e) => setFormValue(e.target.value, "timePerDay")}
                      margin={"none"}
                    >
                      {
                        timesPerDayArray.map((item) => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.FldValue}
                            value={item.FldValue}
                          >
                            {item.Descr}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
              }
              {
                !isReadOnly && (formData.type && getPermissionTypeSelected(formData.type).unidad) === "H" &&
                <Typography
                  className={!timePerDayIsValid() ? "errorColor" : "whiteText"}
                  variant="caption"
                  gutterBottom
                >
                  {
                    getLocalizedString("permissionTimeRange")
                      .replace("{max}", formData.type ? String(Math.floor(getPermissionTypeSelected(formData.type).cant_max)).padStart(2, '0') : `00`)
                      .replace("{maxMin}", formData.type ? decToMM(getPermissionTypeSelected(formData.type).cant_max) : `00`)
                      .replace("es de 00:10", formData.type && Number(getPermissionTypeSelected(formData.type).cant_max) ? "es de 00:10" : `es de 00:00`)
                  }
                  {` ${isForEdit ? getLocalizedString("permissionTimeNotGreater") : ""}`}
                </Typography>
              }
            </Grid>
          </Grid>
          <Grid container item xs={12} alignItems="center">
            <Grid container item xs={12} sm={6} alignItems="center" style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required={!isReadOnly} id={`label-amountOfDays`}>{getLocalizedString("amountOfDays")}</InputLabel>
                <Select
                  disabled={isReadOnly}
                  value={formData.amountOfDays}
                  labelId={`label-amountOfDays`}
                  id={`select-amountOfDays`}
                  onChange={(e) => setFormValue(e.target.value, "amountOfDays")}
                  margin={"none"}
                >
                  {
                    permissionDaysArray.map((item) => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.FldValue}
                        value={item.FldValue}
                      >
                        {item.Descr}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} sm={6} className="secondFieldPadding">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  disabled
                  label={getLocalizedString("endDate")}
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData.dateEnd}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          {
            isForEdit && !isReadOnly &&
            <Grid container style={{ paddingBottom: 15 }}>
              <Typography
                style={{ paddingTop: 5 }}
                className={"whiteText"}
                variant="caption"
                gutterBottom>
                {getLocalizedString("howToSelectMoreDays")}
              </Typography>
            </Grid>
          }
          <Grid container item xs={12} alignItems="center" style={{ marginTop: 10 }}>
            <TextField
              required={isForEdit && !isReadOnly}
              label={getLocalizedString("detail")}
              disabled={isReadOnly}
              margin={"none"}
              value={formData.description}
              onChange={(e) => setFormValue(e.target.value.toString().slice(0, 1024), "description")}
              multiline={true}
              rows={1}
              rowsMax={3}
              fullWidth
            />
            {
              !isReadOnly &&
              <Typography
                style={{ paddingTop: 5 }}
                className={!textIsValid() ? "errorColor" : "whiteText"}
                variant="caption"
                gutterBottom>
                {!isForEdit && `${getLocalizedString("optionalField")}. `}
                {`${getLocalizedString("currentChar")} ${formData.description ? formData.description.length : 0}. ${getLocalizedString("maxCharRequests")}.`}
              </Typography>
            }
          </Grid>
        </Grid>
      </DialogContent>
      {
        isReadOnly &&
        <div style={{ marginTop: 32 }} />
      }
      {
        !isReadOnly &&
        <DialogActions>
          <ButtonDialogAction
            onClick={handleCloseAndReload}
            text={getLocalizedString("disagree")}
          />
          <ButtonDialogAction
            onClick={validateAndCreate}
            text={getLocalizedString("agree")}
            isAccept
          />
        </DialogActions>
      }
    </>
  );
}

export default NewPermissionContent;