import React from 'react';
import { List } from '@material-ui/core';
import TagFormListItem from './TagFormListItem';
import TagFormAddListItem from './TagFormAddListItem';

const TagFormList = (props) => {

    const {
        tags,
        addItem,
        editItem,
        removeItem,
        onCheckListChange,
        className,
    } = props;

    const handleListItemChange = (item) => {
        onCheckListChange(tags.map((tag) => tag.labelCode === item.labelCode ? item : tag));
    }

    const handleListItemRemove = (labelCode) => {
        onCheckListChange(tags.filter((tag) => tag.labelCode !== labelCode));
        removeItem(labelCode)
    }

    const handleListItemAdd = (item) => {
        return new Promise((resolve) => {
            addItem(item)
                .then((resp) => {
                    if (resp?.status === 200) {
                        let list = [...tags];

                        list.unshift(resp.data.label.data)
                        onCheckListChange(list)
                    }

                    return resolve(resp);
                });
        })
    }

    return (
        <div className={className}>
            <List dense style={{ padding: 0 }}>
                <TagFormAddListItem onListItemAdd={handleListItemAdd} />
                <div id="tag-list" style={{ maxHeight: "50vh", overflowY: "auto" }}>
                    {
                        tags.map((item, index) => (
                            <TagFormListItem
                                key={index}
                                item={item}
                                onBlur={editItem}
                                onListItemChange={handleListItemChange}
                                onListItemRemove={handleListItemRemove}
                            />
                        ))
                    }
                </div>
            </List>
        </div>
    );
}

export default TagFormList;