import { getLocalizedString } from "@icarius-localization/strings";
import React, { useState } from "react";
import { CloseIcon } from "@icarius-icons";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const DerivateDialog = (props) => {

    const {
        open,
        options,
        handleDerivate,
        handleCloseDialog,
    } = props;

    const [option, setOption] = useState("");

    const validateAndConfirm = () => {
        if (option === "") return;
        handleConfirm();
    };

    const handleConfirm = () => {
        handleDerivate && handleDerivate(option);
    };

    const handleClose = (shouldComponentUpdate) => {
        handleCloseDialog && handleCloseDialog(shouldComponentUpdate);
    };

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            open={open}
            maxWidth={"xs"}
            fullWidth={true}>
            <div className={"dialog-container"}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {getLocalizedString("derivateRequestTitle")}
                    <DialogTitleDivider />
                </DialogTitle>
                <CloseIcon className={"dialog-close-icon icon"} onClick={() => handleClose(false)} />
                <DialogContent style={{ paddingTop: 0 }}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" item>
                                <FormControl style={{ width: "100%" }}>
                                    <InputLabel required id={`label-addressee`}>
                                        {getLocalizedString("addressee")}
                                    </InputLabel>
                                    <Select
                                        value={option}
                                        labelId={`label-addressee`}
                                        id={`addressee`}
                                        onChange={(e) => setOption(e.target.value)}
                                        margin={"none"}
                                    >
                                        {
                                            options.map((item) => (
                                                <MenuItem
                                                    className={"whiteText"}
                                                    key={item.code}
                                                    value={item.code}
                                                >
                                                    {item.value}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <DialogActions>
                                <ButtonDialogAction
                                    onClick={() => handleClose(false)}
                                    text={getLocalizedString("disagree")}
                                />
                                <ButtonDialogAction
                                    onClick={validateAndConfirm}
                                    text={getLocalizedString("derivate")}
                                    isAccept
                                />
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
}

export default DerivateDialog;