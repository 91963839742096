import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  DialogContent,
  DialogActions,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getTomorrow, dateIsAfterToday, createDateFromDDMMYYYY, formatDate } from "@icarius-utils/date";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import CantCreateRequestMessage from "./cantCreateRequestMessage";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import NumberFormatCustom from './numberFormatCustom';
import ButtonDialogAction from "@icarius-common/buttonDialogAction";

const gridStyle = { minHeight: 75 };

const NewLoanContent = (props) => {

  const {
    isReadOnly,
    isForEdit,
    editData,
    typesAdvances,
    requestCode,
    recipients,
    advanceCalculationData,
    handleNoRecipient,
    handleRequest,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isReadOnly && !isForEdit && Object.keys(recipients.advance).length === 0) {
      handleNoRecipient();
      handleClose();
    }
  }, [isReadOnly, isForEdit, recipients, handleNoRecipient, handleClose])

  const [formData, setFormData] = useState(() => {
    return {
      date: editData?.end_date ? createDateFromDDMMYYYY(editData.end_date) : null,
      type: editData?.id_subtype || "",
      descripcion: editData?.comments || "",
      amount: editData?.value?.toString() || "",
      receptor: recipients?.advance?.length === 1 ? recipients.advance[0].code : "", //si tiene un valor solo, inicializar con ese
    };
  })

  const setFormValue = (value, fieldName) => {
    if (fieldName === "type") {
      setFormData({
        ...formData,
        [fieldName]: value,
        receptor: "",
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  const handleCloseAndReload = (shouldReloadTable) => {
    handleClose(Boolean(isForEdit && shouldReloadTable));
  }

  const textIsValid = () => {
    if (isForEdit) return formData.descripcion && formData.descripcion.length > 0 && formData.descripcion.length <= 1024;
    return formData.descripcion === undefined || (formData.descripcion !== undefined && formData.descripcion.length >= 0 && formData.descripcion.length <= 1024);
  };

  const dataIsComplete = () => {
    if (!isForEdit && !isReadOnly && !formData.receptor) return false;
    if (formData.type && isAmountInRange(formData) && textIsValid(formData) && dateIsAfterToday(formData.date)) return true;
    return false;
  };

  const calculateSalaryPercentage = (money, salary) => {
    if (!money) return 0;
    return (parseFloat(money) / salary * 100).toFixed(2);
  }

  const warnAboutMoney = (percentage, moneyIsOk) => {
    if (moneyIsOk) {
      dispatch(openSnackbarAction({
        msg: `Porcentaje del valor solicitado sobre el sueldo: ${percentage}`,
        severity: "info"
      }));
      return;
    }

    dispatch(openSnackbarAction({
      msg: `El valor que esta solicitando excede el porcentaje máximo permitido sobre su sueldo (${percentage}%)`,
      severity: "error"
    }));
  }

  const validateMoneyAndWarn = (money) => {
    const salary = isForEdit ? editData.current_salary : advanceCalculationData.salary_employee;

    const percentage = calculateSalaryPercentage(money, salary);
    const maxPercentage = advanceCalculationData.percent_max || 999999999999999;
    const moneyIsOk = percentage <= maxPercentage;

    warnAboutMoney(moneyIsOk ? percentage : advanceCalculationData.percent_max, moneyIsOk);

    return moneyIsOk;
  }

  const validateAndCreate = () => {
    if (textIsValid() && dataIsComplete()) {
      if (validateMoneyAndWarn(formData.amount)) {
        const request = {
          code: requestCode,
          id_status: "3",
          id_type: "ANT",
          id_subtype: formData.type,
          date: formatDate(formData.date),
          value: formData.amount.toString(),
          comments: formData.descripcion,
          receptor: formData.receptor,
        }

        if (!isForEdit) {
          delete request.code;
          delete request.id_status;
        } else {
          delete request.id_type;
          delete request.id_subtype;
          delete request.receptor;
        }

        handleRequest(request);

        if (!isForEdit) {
          handleCloseAndReload(true);
        }
      }
    } else {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("completeWithValidInformation"), severity: "error" }));
    }
  };

  const isAmountInRange = () => {
    return formData.amount && formData.amount >= 1;
  }

  if (!isForEdit && !isReadOnly && !advanceCalculationData.can_user_appeal) return <CantCreateRequestMessage />;

  return (
    <>
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container item xs={12} direction="row">
          <Grid container item xs={12} alignItems="center" style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required={!isReadOnly} id={`label-type`}>{getLocalizedString("loanType")}</InputLabel>
              <Select
                disabled={isForEdit || isReadOnly}
                value={formData.type}
                labelId={`label-type`}
                id={`select-type`}
                onChange={(e) => setFormValue(e.target.value, "type")}
                margin={"none"}
              >
                {
                  typesAdvances.map((item) =>
                    <MenuItem
                      className={"whiteText"}
                      key={item.code}
                      value={item.code}>
                      {item.name}
                    </MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </Grid>
          {
            !isForEdit && !isReadOnly &&
            <Grid container item xs={12} alignItems="center" style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-receptor`}>{getLocalizedString("requestReceptor")}</InputLabel>
                <Select
                  disabled={isForEdit || isReadOnly || !Boolean(formData.type)}
                  value={formData.receptor}
                  labelId={`label-receptor`}
                  id={`select-receptor`}
                  onChange={(e) => setFormValue(e.target.value, "receptor")}
                  margin={"none"}
                >
                  {
                    !Boolean(formData.type) &&
                    <MenuItem value={''}>
                      {''}
                    </MenuItem>
                  }
                  {
                    recipients.advance[formData.type]?.map((item) => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.code}
                        value={item.code}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid container item xs={12} sm={6} alignItems="center" style={gridStyle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                required={!isReadOnly}
                disabled={isReadOnly}
                label={getLocalizedString("expectedDateOfPay")}
                style={{ width: window.innerWidth > 959 ? "calc(100% - 15px)" : "100%" }}
                clearable
                clearLabel={getLocalizedString("clear")}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                disablePast
                minDate={getTomorrow()}
                format="dd/MM/yyyy"
                margin="normal"
                invalidDateMessage=''
                minDateMessage=''
                maxDateMessage=''
                value={formData.date}
                onChange={(e) => setFormValue(e, "date")}
              />
            </MuiPickersUtilsProvider>
            {
              !isReadOnly &&
              <Typography
                className={!dateIsAfterToday(formData.date) ? "errorColor" : "whiteText"}
                variant="caption"
                gutterBottom>
                {getLocalizedString("startDateValidation")}
              </Typography>
            }
          </Grid>
          <Grid container item xs={12} sm={6} alignItems="center" style={gridStyle}>
            <TextField
              required={!isReadOnly}
              label={getLocalizedString("amountLoanRequested")}
              disabled={isReadOnly}
              value={formData.amount}
              onChange={(e) => setFormValue(e.target.value, "amount")}
              InputProps={{ inputComponent: NumberFormatCustom, min: "1", step: "0.1" }}
              margin={"normal"}
              fullWidth
            />
            {
              !isReadOnly &&
              <Typography
                className={!isAmountInRange() ? "errorColor" : "whiteText"}
                variant="caption"
                gutterBottom
              >
                {'Valor minimo 1'}
              </Typography>
            }
          </Grid>
          {
            editData?.percentage &&
            <Grid container item xs={12} alignItems="center" style={gridStyle}>
              <TextField
                disabled
                label={'Porcentaje del valor solicitado sobre el sueldo'}
                value={`${isReadOnly ? editData?.percentage : calculateSalaryPercentage(formData.amount, editData.current_salary)}%`}
                margin={"none"}
                fullWidth
              />
            </Grid>
          }
          <Grid container item xs={12} alignItems="center">
            <TextField
              required={isForEdit && !isReadOnly}
              label={getLocalizedString("detail")}
              disabled={isReadOnly}
              margin={"normal"}
              value={formData.descripcion}
              onChange={(e) => setFormValue(e.target.value.toString().slice(0, 1024), "descripcion")}
              multiline={true}
              rows={1}
              rowsMax={3}
              fullWidth
            />
            {
              !isReadOnly &&
              <Typography
                style={{ paddingTop: 5 }}
                className={!textIsValid(formData) ? "errorColor" : "whiteText"}
                variant="caption"
                gutterBottom>
                {!isForEdit && `${getLocalizedString("optionalField")}. `}
                {`${getLocalizedString("currentChar")} ${formData.descripcion ? formData.descripcion.length : 0}. ${getLocalizedString("maxCharRequests")}.`}
              </Typography>
            }
          </Grid>
        </Grid>
      </DialogContent>
      {
        isReadOnly &&
        <div style={{ marginTop: 32 }} />
      }
      {
        !isReadOnly &&
        <DialogActions>
          <ButtonDialogAction onClick={handleCloseAndReload} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={validateAndCreate} isAccept text={getLocalizedString("agree")} />
        </DialogActions>
      }
    </>
  );
}

export default NewLoanContent;