import NAME from "./constants";

export const GET_INITIAL = NAME + "/GET_INITIAL";
export const GET_INITIAL_FULFILLED = NAME + "/GET_INITIAL_FULFILLED";
export const GET_INITIAL_REJECTED = NAME + "/GET_INITIAL_REJECTED";

export const GET_COLABORATORS = NAME + "/GET_COLABORATORS";
export const GET_COLABORATORS_FULFILLED = NAME + "/GET_COLABORATORS_FULFILLED";
export const GET_COLABORATORS_REJECTED = NAME + "/GET_COLABORATORS_REJECTED";

export const DOWNLOAD = NAME + "/DOWNLOAD";
export const DOWNLOAD_FULFILLED = NAME + "/DOWNLOAD_FULFILLED";
export const DOWNLOAD_REJECTED = NAME + "/DOWNLOAD_REJECTED";

export const SEND = NAME + "/SEND_RECEIPTS";
export const SEND_FULFILLED = NAME + "/SEND_RECEIPTS_FULFILLED";
export const SEND_REJECTED = NAME + "/SEND_RECEIPTS_REJECTED";

export const SIGN_AND_DOWNLOAD = NAME + "/SIGN_AND_DOWNLOAD";
export const SIGN_AND_DOWNLOAD_FULFILLED = NAME + "/SIGN_AND_DOWNLOAD_FULFILLED";
export const SIGN_AND_DOWNLOAD_REJECTED = NAME + "/SIGN_AND_DOWNLOAD_REJECTED";

export const RESET_STATE = NAME + "/RESET_STATE";