import NAME from "./constants";

export const getLocale = store => store[NAME].locale;
export const getDateFormat = store => store[NAME].dateFormat;
export const getReceipts = store => store[NAME].receipts;
export const getCalculationProcesses = store => store[NAME].calculationProcesses;
export const getCollaborators = store => store[NAME].collaborators;
export const getIsLoading = store =>
  store[NAME].isLoadingInitial ||
  store[NAME].isLoadingColaborators ||
  store[NAME].isLoadingDownloadSend ||
  store[NAME].isLoadingSign;