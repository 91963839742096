import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import { getGeneralSettingsAction } from "../actions";
import { loading, getGeneralSettings } from "../selectors";
import Form from "./form";

const GeneralSettings = () => {

  const dispatch = useDispatch();
  const isLoading = useSelector(loading);
  const generalSettings = useSelector(getGeneralSettings);

  useEffect(() => {
    dispatch(getGeneralSettingsAction());
  }, [dispatch])

  return (
    <CommonPage
      title={getLocalizedString("generalSettings")}
      isLoading={isLoading}
      isNotGridPage
    >
      {
        Boolean(generalSettings) &&
        <Form
          generalSettings={generalSettings}
        />
      }
    </CommonPage>
  )
}

export default GeneralSettings;
