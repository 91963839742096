import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  DialogContent,
  DialogActions,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getTomorrow, dateIsAfterToday, createDateFromDDMMYYYY, formatDate } from "@icarius-utils/date";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import UserSelectionDialog from "@icarius-common/userSelectionDialog";

const gridStyle = { minHeight: 75 };

const NewDisengagementContent = (props) => {

  const {
    fireCalculationData,
    colaborators,
    editData,
    isForEdit,
    isReadOnly,
    recipients,
    requestCode,
    handleNoRecipient,
    handleRequest,
    handleClose,
  } = props;

  useEffect(() => {
    if (!isReadOnly && !isForEdit && recipients.fire.length === 0) {
      handleNoRecipient();
      handleClose();
    }
  }, [isReadOnly, isForEdit, recipients, handleNoRecipient, handleClose])

  const dispatch = useDispatch();

  const [userSelectionDialogIsOpen, setUserSelectionDialogIsOpen] = useState(false);
  const [formData, setFormData] = useState(() => {
    return {
      date: editData?.expected_date ? createDateFromDDMMYYYY(editData.expected_date) : null,
      colaborator: editData?.colaborator_name || "",
      legalType: editData?.legal_reason_id || "",
      reasonToRegisterInDigitalFolder: editData?.reason_id || "",
      descripcion: editData?.comments || "",
      receptor: recipients?.fire?.length === 1 ? recipients.fire[0].code : "", //si tiene un valor solo, inicializar con ese
    };
  })

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  const handleCloseAndReload = (shouldReloadTable) => {
    if (isForEdit && shouldReloadTable) {
      handleClose(true);
    } else {
      handleClose();
    }
  };

  const textIsValid = () => {
    return formData.descripcion && formData.descripcion.length > 0 && formData.descripcion.length <= 1024;
  };

  const dataIsComplete = () => {
    if (isForEdit) {
      if (dateIsAfterToday(formData.date) && formData.reasonToRegisterInDigitalFolder && formData.legalType && textIsValid()) return true;
      return false;
    }

    if (!isForEdit && !isReadOnly && !formData.receptor) return false;
    if (formData.reasonToRegisterInDigitalFolder && formData.colaborator && dateIsAfterToday(formData.date) && textIsValid()) return true;
    return false;
  };

  const validateAndCreate = () => {
    if (dataIsComplete()) {
      const request = {
        code: requestCode,
        colaborator_id: formData.colaborator,
        date: formatDate(formData.date),
        reason_id: formData.reasonToRegisterInDigitalFolder,
        legal_reason_id: formData.legalType,
        comments: formData.descripcion,
        receptor: formData.receptor,
      }

      if (isForEdit) {
        delete request.receptor;
        delete request.colaborator_id;
      } else {
        delete request.code;
        delete request.legal_reason_id;
      }

      handleRequest(request);
      if (!isForEdit) {
        handleCloseAndReload(true);
      }
    } else {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("completeWithValidInformation"), severity: "error" }));
    }
  };

  return (
    <>
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container direction="column" justify="center">
          {
            !isForEdit && !isReadOnly &&
            <Grid container item xs={12} alignItems="center" style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-receptor`}>{getLocalizedString("requestReceptor")}</InputLabel>
                <Select
                  disabled={isForEdit || isReadOnly}
                  value={formData.receptor}
                  labelId={`label-receptor`}
                  id={`select-receptor`}
                  onChange={(e) => setFormValue(e.target.value, "receptor")}
                  margin={"none"}
                >
                  {
                    recipients.fire.map((subItem) => (
                      <MenuItem
                        className={"whiteText"}
                        key={subItem.code}
                        value={subItem.code}>
                        {subItem.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          }
          {
            !isForEdit && !isReadOnly &&
            <Grid container item xs={12} alignItems="center" style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-colaborator`}>{getLocalizedString("disengagementRequestedFor")}</InputLabel>
                <Select
                  onOpen={() => setUserSelectionDialogIsOpen(true)}
                  open={false}
                  disabled={isForEdit}
                  value={formData.colaborator}
                  labelId={`label-colaborator`}
                  id={`select-colaborator`}
                  margin={"none"}
                >
                  {
                    colaborators.map((subItem) => (
                      <MenuItem
                        className={"whiteText"}
                        key={subItem.code}
                        value={subItem.code}>
                        {subItem.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          }
          {
            (isForEdit || isReadOnly) &&
            <Grid container item xs={12} alignItems="center" style={gridStyle}>
              <TextField
                disabled
                label={getLocalizedString("disengagementRequestedFor")}
                value={formData.colaborator}
                margin={"none"}
                fullWidth
              />
            </Grid>
          }
          {
            isForEdit && !isReadOnly &&
            <Grid container item xs={12} alignItems="center" style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required={!isReadOnly} id={`label-legalType`}>{getLocalizedString("disengagementLegalType")}</InputLabel>
                <Select
                  disabled={!isForEdit || isReadOnly}
                  value={formData.legalType}
                  labelId={`label-legalType`}
                  id={`select-legalType`}
                  onChange={(e) => setFormValue(e.target.value, "legalType")}
                  margin={"none"}
                >
                  {
                    fireCalculationData.legal_reasons.map((subItem) => (
                      <MenuItem
                        className={"whiteText"}
                        key={subItem.code}
                        value={subItem.code}>
                        {subItem.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid container item xs={12} alignItems="center" style={gridStyle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                disabled={isReadOnly}
                required={!isReadOnly}
                label={getLocalizedString("expectedDateOfDisengagement")}
                clearable
                clearLabel={getLocalizedString("clear")}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                disablePast
                minDate={getTomorrow()}
                format="dd/MM/yyyy"
                margin="normal"
                invalidDateMessage=''
                minDateMessage=''
                maxDateMessage=''
                value={formData.date}
                onChange={(e) => setFormValue(e, "date")}
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item xs={12} alignItems="center" style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required={!isReadOnly} id={`label-reasonToRegisterInDigitalFolder`}>
                {getLocalizedString("reasonToRegisterInDigitalFolder")}
              </InputLabel>
              <Select
                disabled={isForEdit || isReadOnly}
                value={formData.reasonToRegisterInDigitalFolder}
                labelId={`label-reasonToRegisterInDigitalFolder`}
                id={`select-reasonToRegisterInDigitalFolder`}
                onChange={(e) => setFormValue(e.target.value, "reasonToRegisterInDigitalFolder")}
                margin={"none"}
              >
                {
                  fireCalculationData.reasons.map((subItem, index) => (
                    <MenuItem
                      className={"whiteText"}
                      key={subItem.code}
                      value={subItem.code}>
                      {subItem.value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} alignItems="center" style={gridStyle}>
            <TextField
              required={!isReadOnly}
              label={getLocalizedString("detailDisengagement")}
              disabled={isReadOnly}
              value={formData.descripcion}
              margin={"none"}
              onChange={(e) => setFormValue(e.target.value.toString().slice(0, 1024), "descripcion")}
              multiline={true}
              rows={1}
              rowsMax={5}
              fullWidth
            />
            {
              !isReadOnly &&
              <Typography
                style={{ paddingTop: 5 }}
                className={!textIsValid() ? "errorColor" : "whiteText"}
                variant="caption"
                gutterBottom
              >
                {`${getLocalizedString("currentChar")} ${formData.descripcion ? formData.descripcion.length : 0}. ${getLocalizedString("maxCharRequests")}.`}                        </Typography>
            }
          </Grid>
        </Grid>
      </DialogContent>
      {
        isReadOnly &&
        <div style={{ marginTop: 32 }} />
      }
      {
        !isReadOnly &&
        <DialogActions>
          <ButtonDialogAction
            onClick={handleCloseAndReload}
            text={getLocalizedString("disagree")}
          />
          <ButtonDialogAction
            onClick={validateAndCreate}
            text={getLocalizedString("agree")}
            isAccept
          />
        </DialogActions>
      }
      {
        userSelectionDialogIsOpen &&
        <UserSelectionDialog
          open={userSelectionDialogIsOpen}
          initialData={[formData.colaborator]}
          users={colaborators.map((colab) => {
            return {
              FOTOGRAFIA: colab["FOTOGRAFIA"],
              name: colab.value,
              key: colab.code,
            }
          })}
          title={getLocalizedString("selectColabFire")}
          selectedUsersTitle={getLocalizedString("selectedColab")}
          handleSubmit={(value) => setFormValue(value[0], "colaborator")}
          handleClose={() => setUserSelectionDialogIsOpen(false)}
          singleSelection
        />
      }
    </>
  );
}

export default NewDisengagementContent;