import { DOMAINS } from "@icarius-utils/properties";

export const BASE_URL = (() => {
    const BASE_DOMAIN = DOMAINS[0];
    if (BASE_DOMAIN.startsWith("http://") || BASE_DOMAIN.startsWith("https://")) return BASE_DOMAIN;
    return "http://" + BASE_DOMAIN;
})();

export const ASSISTANCE_PWA_URL = (() => {
    const ASSISTANCE_DOMAIN = DOMAINS[1];
    if (!ASSISTANCE_DOMAIN) return "";
    if (ASSISTANCE_DOMAIN.startsWith("http://") || ASSISTANCE_DOMAIN.startsWith("https://")) return ASSISTANCE_DOMAIN;
    return "http://" + ASSISTANCE_DOMAIN;
})();

export const IMAGES_ENDPOINT_NO_STATIC = BASE_URL + "/";
export const IMAGES_ENDPOINT = BASE_URL + "/static/";
export const RESOURCES_ENDPOINT = BASE_URL + "/static/";
export const AUDIO_ENDPOINT = BASE_URL + "/resources/audio";
export const CLIENT_CONFIG_ENDPOINT = BASE_URL + "/clientConfig/";

export const API_URL = BASE_URL + "/api";

export const VERSION_ENDPOINT = API_URL + "/version";
export const LOGIN_ENDPOINT = API_URL + "/login";
export const LOGOUT_ENDPOINT = API_URL + "/logout";

export const INITIAL_DATA_ENDPOINT = API_URL + "/initial";
export const HOME_ENDPOINT = API_URL + "/home";

export const FORGOT_PASSWORD_ENDPOINT = API_URL + "/forgotAccess";
export const CHANGE_PASSWORD_ENDPOINT = API_URL + "/modifyPin";
export const RESET_PASSWORD_ENDPOINT = API_URL + "/checkRegenPinCode";

export const DIRECTORY_ENDPOINT = API_URL + "/directory";
export const MY_PEOPLE_ENDPOINT = API_URL + "/myPeople";

export const LIKES_ENDPOINT = API_URL + "/likeOrDislike";

export const AVAILABLE_COLORS_ENDPOINT = API_URL + "/getColors";
export const SET_APP_COLOR_ENDPOINT = API_URL + "/setColor";

export const POLL_ENDPOINT = API_URL + "/poll";
export const ANSWER_POLL_ENDPOINT = API_URL + "/poll/answer";

export const ALERTS_ENDPOINT = API_URL + "/alerts";

export const TEMPLATES_ENDPOINT = API_URL + "/templates";
export const GET_ASSISTANCE_ENDPOINT = API_URL + "/getAssistance";
export const GET_MY_ASSISTANCE_ENDPOINT = API_URL + "/myAssistence";
export const GET_ASSISTANCE_MARKS_MAP_ENDPOINT = API_URL + "/marksForMap";
export const COMPLETE_ASSISTANCE_MARK_ENDPOINT = API_URL + "/completeAssistanceMark";

export const GET_MY_PERSONAL_DATA_ENDPOINT = API_URL + "/getMyData";
export const UPDATE_MY_PERSONAL_DATA_ENDPOINT = API_URL + "/editMyData";
export const UPLOAD_PROFILE_IMAGE_ENDPOINT = API_URL + "/uploadUserImage";

export const GET_REGIONS_BY_COUNTRY_ENDPOINT = API_URL + "/getRegionsByCountry";
export const GET_PROVINCES_BY_REGION_ENDPOINT = API_URL + "/getProvincesByRegion";
export const GET_COUNTIES_BY_PROVINCE_ENDPOINT = API_URL + "/getCountiesByProvince";

export const ANNOTATIONS_ENDPOINT = API_URL + "/annotations";
export const MY_ANNOTATIONS_ENDPOINT = API_URL + "/myAnnotations";

export const ANNEXES_ENDPOINT = API_URL + "/annexes";
export const DOWNLOAD_ANNEX_ENDPOINT = API_URL + "/annexes/download";
export const DOWNLOAD_ANNEX_FOLDER_ENDPOINT = API_URL + "/annexes/downloadFolder";

// my certificates
export const MY_CERTIFICATES_ENDPOINT = API_URL + "/myCertificates";
export const CONFIRM_MY_CERTIFICATE_ENDPOINT = API_URL + "/myCertificates/confirm";
export const DOWNLOAD_MY_CERTIFICATE_ENDPOINT = API_URL + "/myCertificates/download";

export const EXPORT_EXCEL_FOR_IOS = API_URL + "/exportMyPeople";

// certificates
export const REGULAR_CERTIFICATES_AND_COLLABORATORS_ENDPOINT = API_URL + "/certificates";
export const CERTIFICATES_DOWNLOAD_ENDPOINT = API_URL + "/certificates/download";
export const CERTIFICATES_WITH_SIGN_ENDPOINT = API_URL + "/certificates/signed";

// receipts
export const GET_RECEIPTS_ENDPOINT = API_URL + "/receipts";
export const GET_COLLABORATORS_FOR_RECEIPT_ENDPOINT = API_URL + "/receipts/collaborators";
export const DOWNLOAD_RECEIPTS_ENDPOINT = API_URL + "/receipts/download";
export const SEND_RECEIPTS_ENDPOINT = API_URL + "/receipts/send";

export const GET_COLLABORATORS_WITH_DOCUMENTS_TO_SIGN_ENDPOINT = API_URL + "/collaboratorsWithDocumentsToSign";
export const SIGN_AND_DOWNLOAD_ENDPOINT = API_URL + "/signDocuments";

// my receipts
export const GET_MY_RECEIPT_LIST_ENDPOINT = API_URL + "/getMyReceiptList";
export const CONFIRM_RECEIPT_ENDPOINT = API_URL + "/confirmReceipt";
export const DOWNLOAD_RECEIPT_ENDPOINT = API_URL + "/downloadReceipt";

export const GET_MY_CURRENT_ACCOUNT_LOANS_RESUME_ENDPOINT = API_URL + "/getMyCurrentAccountLoansResume";
export const GET_MY_CURRENT_ACCOUNT_LOANS_BY_TYPE_AND_CURRENCY_ENDPOINT = API_URL + "/getMyCurrentAccountLoansByTypeAndCurrency";
export const GET_EMPLOYEES_CURRENT_ACCOUNT_LOANS_RESUME_ENDPOINT = API_URL + "/getEmployeesCurrentAccountLoansResume";
export const GET_EMPLOYEES_CURRENT_ACCOUNT_LOANS_BY_TYPE_AND_CURRENCY_ENDPOINT = API_URL + "/getEmployeesCurrentAccountLoansByTypeAndCurrency";
export const GET_MY_REQUESTS_ENDPOINT = API_URL + "/getMyAppeals";
export const GET_MANAGER_PENDING_REQUESTS_ENDPOINT = API_URL + "/getManagerPendingAppeals";
export const GET_EMPLOYER_PENDING_REQUESTS_ENDPOINT = API_URL + "/getEmployerPendingAppeals";
export const DELEGATIONS_ENDPOINT = API_URL + "/delegations";
export const GET_MANAGERS_EMPLOYERS_BY_SOCIETY_ENDPOINT = API_URL + "/getManagersEmployersBySociety";
export const SET_COLABORATOR_APPEAL = API_URL + "/setColaboratorAppeal";
export const SET_COLABORATOR_APPEAL_PERMISSION = API_URL + "/setColaboratorAppealPermission";
export const SET_COLABORATOR_APPEAL_ADVANCE = API_URL + "/setColaboratorAppealAdvance";
export const MODIFY_VACATION_APPEAL_STATUS = API_URL + "/modifyVacationAppealStatus";
export const MODIFY_ADVANCE_APPEAL_STATUS = API_URL + "/modifyAdvanceAppealStatus";
export const MODIFY_PERMISSION_APPEAL_STATUS = API_URL + "/modifyPermissionAppealStatus";
export const SEND_REQUEST_MAIL_ENDPOINT = API_URL + "/alertAppealResponsibles";
export const DELETE_REQUEST_ENDPOINT = API_URL + "/deleteRequest";

export const QUERIES_GROUPS_ENDPOINT = API_URL + "/queries/groups";
export const QUERIES_ENDPOINT = API_URL + "/queries";
export const QUERIES_EXECUTE_ENDPOINT = API_URL + "/queries/execute";
export const QUERIES_EXPORT_ENDPOINT = API_URL + "/queries/export";
export const QUERIES_IMPORT_ENDPOINT = API_URL + "/queries/import";

export const GET_LOGO_ENDPOINT = API_URL + "/getLogo";

export const GET_LOCATION_ENDPOINT = API_URL + "/getLocationOptionsForUser";
export const GET_MANAGEMENT_DIVISION_ENDPOINT = API_URL + "/getManagementDivisionsForUser";
export const GET_DEPARTMENTS_BY_MANAGEMENT_ENDPOINT = API_URL + "/getDepartmentsByManagement";
export const GET_SECTIONS_BY_DEPARTMENT_ENDPOINT = API_URL + "/getSectionsByDepartment";

export const UPDATE_EMPLOYEE_ENDPOINT = API_URL + "/updateEmployee";
export const CREATE_EMPLOYEE_ENDPOINT = API_URL + "/saveNewEmployee";
export const GET_RECEIPTS_EMPLOYEE = API_URL + "/getAvailableReceiptsForEmployee";

// NOTAS
export const NOTES_ENDPOINT = API_URL + "/notes";
export const NOTES_LABELS_ENDPOINT = API_URL + "/notes/labels";
export const NOTES_LABEL_ENDPOINT = API_URL + "/notes/label";
export const NOTE_ENDPOINT = API_URL + "/note";
export const NOTE_COMMENTS_ENDPOINT = API_URL + "/note/comments";
export const NOTE_COMMENT_ENDPOINT = API_URL + "/note/comment";
export const NOTE_SHARE_ENDPOINT = API_URL + "/note/share";
export const NOTE_SEND_ENDPOINT = API_URL + "/note/send";
export const NOTE_CONFIRM_ENDPOINT = API_URL + "/note/confirm";
export const NOTE_ATTACH_ENDPOINT = API_URL + "/note/attach";
export const NOTE_REMINDER_ENDPOINT = API_URL + "/note/reminder";

//TotemMagement
export const TOTEMS_ENDPOINT = API_URL + "/totems";
export const REGISTER_ASSISTANCE_TOTEM_ENDPOINT = API_URL + "/registerAssistanceWeb";

//vacaciones
export const VACATIONS_ENDPOINT = API_URL + "/vacations";
export const EMPLOYEE_VACATIONS_ENDPOINT = API_URL + "/vacations/employee";
export const VACATIONS_MESSAGE_ENDPOINT = API_URL + "/vacations/message";
export const DOWNLOAD_VACATIONS_RECEIPT = API_URL + "/vacations/download/receipt";
export const DOWNLOAD_VACATION_SUMMARY_ENDPOINT = API_URL + "/vacations/download/summary";

//SIGNERS
export const SIGNERS_ENDPOINT = API_URL + "/signers";
export const GET_TYPE_DOCS_ENDPOINT = API_URL + "/getActiveTypeDocsToSign";
export const PREVIEW_DOCUMENTS_ENDPOINT = API_URL + "/downloadDocumentsPreview";
export const GET_LICENCE_PERIODS_ENDPOINT = API_URL + "/getLicencePeriods";

//Request Documents to sign
export const GET_DOCUMENTS_TO_REQUEST_ENDPOINT = API_URL + "/getDocumentsToRequest";
export const REQUEST_DOCUMENT_ENDPOINT = API_URL + "/addDocumentRequest";

export const GET_DOCUMENTS_REQUESTS_ENDPOINT = API_URL + "/getDocumentsRequests";
export const SIGN_DOCUMENTS_ENDPOINT = API_URL + "/signDocuments";
export const DELETE_DOCUMENTS_ENDPOINT = API_URL + "/deleteDocumentsRequests";

//Documentos Firmados
export const GET_SIGNED_DOCUMENTS_ENDPOINT = API_URL + "/getDocumentsSigned";
export const DOWNLOAD_SIGNED_DOCUMENTS_ENDPOINT = API_URL + "/downloadDocumentSigned";
export const DELETE_SIGNED_DOCUMENTS_ENDPOINT = API_URL + "/deleteDocument";

export const DIRECT_DOWNLOAD_DOCUMENT_DIGITAL_SIGN_ENDPOINT = API_URL + "/downloadDocumentSignedDirect";
export const DOWNLOAD_DOCUMENT_DIGITAL_SIGN_ENDPOINT = API_URL + "/downloadDocumentSigned";
export const ACCEPT_RECEIPT_WITH_DIGITAL_SIGN_ENDPOINT = API_URL + "/approveDocument";
export const REFUSE_RECEIPT_WITH_DIGITAL_SIGN_ENDPOINT = API_URL + "/refuseDocument";
export const SEND_SIGNED_DOCUMENTS_MAIL_ENDPOINT = API_URL + "/sendReminderDocToApprove";

// Requests Level 2
export const GET_MY_REQUESTS_MANAGER_ENDPOINT = API_URL + "/getMyManagerAppeals";
export const GET_MY_PENDING_EMPLOYER_ENDPOINT = API_URL + "/getPendingManagerAppeals";
export const DERIVATE_MANAGER_APPEAL_ENDPOINT = API_URL + "/derivateManagerAppeal";
export const MODIFY_RAISE_APPEAL_STATUS = API_URL + "/modifyRaiseAppealStatus";
export const MODIFY_FIRE_APPEAL_STATUS = API_URL + "/modifyFireAppealStatus";
export const SET_APPEAL_FIRE_ENDPOINT = API_URL + "/setAppealFire";
export const SET_APPEAL_RAISE_ENDPOINT = API_URL + "/setAppealRaise";

export const REGISTER_ASSISTANCE_ENDPOINT = API_URL + "/registerAssistancePadOrWeb";

//Fiscalizacion
export const GET_COUNTRIES = API_URL + "/getCountrys";
export const LOGIN_INSPECTOR = API_URL + "/loginInspector";
export const GET_COUNTRIES_SOCIETIES = API_URL + "/getCountrySocieties";
export const GET_SOCIETY_DOCS = API_URL + "/getSocietyDocs";
export const PREVIEW_DOCUMENT = API_URL + "/previewDocument";
export const CHANGE_PASSWORD = API_URL + "/changePassword";
export const LOST_PASSWORD = API_URL + "/lostPassword";

export const GET_SOCIETY_PEOPLE_AND_GROUPS_ENDPOINT = API_URL + "/groups";

//Grupos
export const GROUPS_ENDPOINT = API_URL + "/groups";
export const LEAVE_GROUP_ENDPOINT = API_URL + "/groups/leave";

//ABM 3 carpeta digital
export const GET_DIGITAL_FOLDER_GENERIC_FILES = API_URL + "/getGenericFilesForDigitalFolder";
export const GET_DIGITAL_FOLDER_COLABORATOR_FILES = API_URL + "/colaboratorFilesDigitalFolder";
export const GET_DIGITAL_FOLDER_FILES_BY_POSITION = API_URL + "/getFilesByPositionForDigitalFolder";
export const UPDATE_DIGITAL_FOLDER_GENERIC_FILES = API_URL + "/updateGenericFilesForDigitalFolder";
export const DELETE_FOR_POSITION_FILES = API_URL + "/deleteFileForPositionForDigitalFolder";
export const ADD_FOR_POSITION_FILES = API_URL + "/addFileForPositionForDigitalFolder";
export const UPDATE_FOR_POSITION_FILES = API_URL + "/updateFileForPositionForDigitalFolder";

//GALLERY
export const UPLOAD_IMAGE_TO_GALLERY = API_URL + "/uploadImageToGallery";
export const GET_IMAGES_FROM_GALLERY = API_URL + "/getGallery";
export const DELETE_IMAGE_FROM_GALLERY = API_URL + "/deleteImageFromGallery";

//EXTERNAL DOCUMENTS
export const GET_EXTERNAL_DOCUMENTS = API_URL + "/getExternalDocuments";
export const GET_EXTERNAL_DOCUMENTS_ROLES = API_URL + "/getExternalDocumentsRoles";
export const UPLOAD_EXTERNAL_DOCUMENTS = API_URL + "/uploadExternalDocuments";
export const DOWNLOAD_EXTERNAL_DOCUMENTS_PREVIEW = API_URL + "/downloadExternalPreview";
export const DELETE_EXTERNAL_DOCUMENTS = API_URL + "/deleteExternalDocuments";
export const SIGN_EXTERNAL_DOCUMENTS = API_URL + "/signExternalDocuments";

//Documentos Digitales
export const GET_DIGITAL_DOCUMENTS_TO_APPROVE = API_URL + "/getDocumentsToApprove";
export const CONFIRM_DIGITAL_DOCUMENT_ENDPOINT = API_URL + "/confirmDocumentSigned";

// General Settings
export const GENERAL_SETTINGS_ENDPOINT = API_URL + "/generalSettings";

//Poll Settings
export const POLLS_SETTINGS_ENDPOINT = API_URL + "/pollSettings";

//ACCESS SETTINGS
export const ACCESS_SETTINGS = API_URL + "/accessSettings";

// Send message
export const SEND_MESSAGE_ENDPOINT = API_URL + "/message";

//Annotations Analysis
export const GET_ANNOTATIONS_ANALYSIS = API_URL + "/getAnnotationAnalysis";

//Procesos
export const GET_CALCULATION_PROCESSES = API_URL + "/calculationProcess";
export const GET_PROCESSES_EMPLOYEES = API_URL + "/calculationProcessEmployees";
export const CREATE_MODIFY_CALCULATION_PROCESSES = API_URL + "/calculationProcess";
export const EXECUTE_R8 = API_URL + "/executeR8";
export const GET_CALCULATION_PROCESSES_TRACEABILITY = API_URL + "/trace";
export const GENERATE_THIRD_PARTY_FILE = API_URL + "/generateThirdPartyFile";
export const APPROVE_PROCESS_ENDPOINT = API_URL + "/approveProcess";

//Digital Folder Analysis
export const GET_DIGITAL_FOLDER_ANALYSIS = API_URL + "/getDigitalDocumentsInfo";
export const GET_DIGITAL_FOLDER_INSTANCES = API_URL + "/getInstancesDigitalFolder";

//Employees processes
export const GET_CALCULATION_PROCESS_COLABORATORS = API_URL + "/getCalculationProcessColaborators";
export const DELETE_PROCESS_COLABORATORS = API_URL + "/deleteCalculationProcessColaborator";
export const GET_EXTRA_CALCULATION_PROCESS_DATA = API_URL + "/getColaboratorCalculationProcessResults";
export const PUBLISH_PROCESS_ENDPOINT = API_URL + "/publicateCalculationProcess";
export const GET_PROCESS_DASHBOARD_FROM_SERVER = API_URL + "/calculationProcessDashboard";
export const GET_ANALYTICS_DASHBOARD_FROM_SERVER = API_URL + "/calculationProcessConceptsAnalytics";

//Geoanalysis
export const GET_GEOANALYSIS_GROUPS = API_URL + "/getGeoanalysisGroups";
export const GET_GEOANALYSIS_QUERIES = API_URL + "/getGeoanalysisQueries";
export const EXECUTE_GEOANALYSIS_QUERY = API_URL + "/executeGeoanalysisQuery";
export const EXPORT_GEOANALYSIS_QUERY = API_URL + "/exportGeoanalysisQuery";
export const IMPORT_GEOANALYSIS_QUERY = API_URL + "/importGeoanalysisQuery";

//Publication Settings
export const PUBLICATION_SETTINGS = API_URL + "/publications";
export const VERIFY_URL_HAS_IMAGE = API_URL + "/verifyURLHasImage";
export const ORDER_PUBLICATION_SETTINGS = API_URL + "/orderPublications";

//Filters
export const GET_FILTERS = API_URL + "/getFilters";
export const APPLY_FILTERS = API_URL + "/applyFilters";

// OrgChart
export const GET_ORG_CHART_WITH_BRANCH = API_URL + "/getOrgChart";
export const RECOMPOSE_POSITIONS_ENDPOINT = API_URL + "/recomposePositions";
export const GET_CLIENT_BRANCHES = API_URL + "/getClientBranches";
export const SAVE_ORG_CHART = API_URL + "/saveOrgChart";
export const GET_PEOPLE_ORGCHART = API_URL + "/peopleOrgChart";

//Centers settings
export const GET_CENTERS_SETTINGS = API_URL + "/getCentersSettings";
export const CREATE_CENTERS_SETTINGS = API_URL + "/createCentersSetting";
export const UPDATE_CENTERS_SETTINGS = API_URL + "/updateCentersSetting";
export const DELETE_CENTERS_SETTINGS = API_URL + "/deleteCentersSetting";

//Permissions settings
export const PERMISSIONS_SETTINGS = API_URL + "/permissionSettings";

//Family settings
export const FAMILY_SETTINGS = API_URL + "/familySettings";

//Functions settings
export const FUNCTIONS_SETTINGS = API_URL + "/functionsSettings";

//Advances settings
export const ADVANCES_SETTINGS = API_URL + "/advancesSettings";

//Document publication
export const DOCUMENT_PUBLICATION = API_URL + "/documentPublication";

//Contract types settings
export const CONTRACT_TYPES_SETTINGS = API_URL + "/contractTypes";

//Assistance voice
export const GET_ASSISTANCE_VOICE_TYPE = API_URL + "/getAssistantVoiceType";

//Concept groups
export const CONCEPT_GROUPS = API_URL + "/conceptGroups";

//Contract types settings
export const CONTRACTOR_SETTINGS = API_URL + "/contractors";

//Functionary types settings
export const FUNCTIONARY_TYPES_SETTINGS = API_URL + "/functionaryTypes";

//Location settings
export const LOCATION_SETTINGS_ENDPOINT = API_URL + "/locationSettings";

//Works and tasks
export const WORKS_AND_TASKS_SETTINGS_ENDPOINT = API_URL + "/worksAndTasks";

//Divisions settings
export const DIVISIONS_SETTINGS_ENDPOINT = API_URL + "/divisionsSettings";

//Concepts and formulas
export const CONCEPTS_AND_FORMULAS = API_URL + "/conceptsAndFormulas";
export const CONCEPTS_AND_FORMULAS_FILE = API_URL + "/conceptsAndFormulas/file";
export const CONCEPTS_AND_FORMULAS_DUPLICATE = API_URL + "/conceptsAndFormulas/duplicate";
export const CONCEPTS_AND_FORMULAS_SEARCH = API_URL + "/conceptsAndFormulas/search";
export const CONCEPTS_AND_FORMULAS_DETAILS = API_URL + "/conceptsAndFormulas/details";

//Concepts and formulas II (Funciones)
export const FUNCTIONS = API_URL + "/functions";
export const FUNCTIONS_SEARCH = API_URL + "/functions/search";
export const FUNCTIONS_DUPLICATE = API_URL + "/functions/duplicate";

//Accumulators settings
export const ACCUMULATORS_SETTINGS_ENDPOINT = API_URL + "/accumulatorSettings";

//Categorias y especialidades
export const CATEGORIES_AND_SPECIALTIES_ENDPOINT = API_URL + "/categoriesAndSpecialty";

//payroll types
export const PAYROLL_TYPES_SETTINGS_ENDPOINT = API_URL + "/payrollTypes";
export const DUPLICATE_PAYROLL_TYPES_SETTINGS_ENDPOINT = API_URL + "/duplicatePayrollType";

//allergies exams diseases
export const ALLERGIES_EXAMS_DISEASES_ENDPOINT = API_URL + "/allergiesExamsAndDiseases";

//calculation references
export const CALCULATION_REFERENCES_ENDPOINT = API_URL + "/calculationReferences";

//entry exit types
export const ENTRY_EXIT_TYPES_SETTINGS_ENDPOINT = API_URL + "/entryExitTypes";

//Estructura organizacional
export const ORGANIZATION_STRUCTURE_ENDPOINT = API_URL + "/getDepartmentStructure";
export const SAVE_ORGANIZATION_STRUCTURE_ENDPOINT = API_URL + "/saveDepartmentStructure";
export const ORGANIZATION_OCCUPATION_DATA = API_URL + "/getDepartmentOccupation";
export const POSITION_OCUPATION_DATA = API_URL + "/getPositionOccupation";

//Estructura organizacional
export const GEOGRAPHICAL_STRUCTURE_ENDPOINT = API_URL + "/geographicalStructure";
export const GEOGRAPHICAL_OCCUPATION_DATA = API_URL + "/geographicalOccupation";

//issue Types Settings
export const ABSENCE_SETTINGS_ENDPOINT = API_URL + "/absenceTypes";
export const PERMISSION_TYPES_SETTINGS_ENDPOINT = API_URL + "/permissionTypes";
export const LICENCE_TYPES_SETTINGS_ENDPOINT = API_URL + "/licenceTypes";

//Positions Classification Settings
export const POSITIONS_CLASSIFICATION_SETTINGS = API_URL + "/positionsClassification";

export const EMPLOYEE_EDITABLE_DATA = API_URL + "/employeeEditableData";

//Query definition
export const QUERY_DEFINITION_ENDPOINT = API_URL + "/queryDefinition";
export const DUPLICATE_QUERY_DEFINITION_ENDPOINT = API_URL + "/queryDefinition/duplicate";
export const QUERY_PARAMETERS_ENDPOINT = API_URL + "/queryParamDefinition";
export const DUPLICATE_QUERY_PARAMETERS_ENDPOINT = API_URL + "/queryParamDefinition/duplicate";

//Geo query
export const GEO_QUERY_DEFINITION_ENDPOINT = API_URL + "/geoQuery";
export const DUPLICATE_GEO_QUERY_DEFINITION_ENDPOINT = API_URL + "/geoQuery/duplicate";
export const GEO_QUERY_PARAMETERS_ENDPOINT = API_URL + "/geoParams";
export const DUPLICATE_GEO_QUERY_PARAMETERS_ENDPOINT = API_URL + "/geoParams/duplicate";

//General values
export const GENERAL_VALUES_ENDPOINT = API_URL + "/generalValues";

// kpis
export const GET_KPI_INDICATOR_ENDPOINT = API_URL + "/kpi";
export const GET_KPI_INDICATORS_ENDPOINT = API_URL + "/getAllKPIs";
export const GET_KPI_GRID_DATA_ENDPOINT = API_URL + "/getKPIGrid";
export const GET_KPI_ADDITIONAL_DATA_ENDPOINT = API_URL + "/kpiAdditionalData";

// Canal de comunicaciones
export const COMMUNICATION_CHANNEL_ENDPOINT = API_URL + "/communicationChannel";
export const ASSIGN_RESPONSIBLE_COMMUNICATION_CHANNEL_ENDPOINT = API_URL + "/communicationChannel/assignResponsible";
export const ANSWER_COMMUNICATION_CHANNEL_ENDPOINT = API_URL + "/communicationChannel/answer";
export const CLASSIFY_COMMUNICATION_CHANNEL_ENDPOINT = API_URL + "/communicationChannel/classify";

//ABM KPI
export const KPI_DEFINITION_ENDPOINT = API_URL + "/kpiDefinition";
export const KPI_DEFINITION_DUPLICATE_ENDPOINT = API_URL + "/kpiDefinition/duplicate";

//Alerts settings
export const ALERTS_SETTINGS_ENDPOINT = API_URL + "/alertsSettings";
export const ALERTS_SETTINGS_DUPLICATE_ENDPOINT = API_URL + "/alertsSettings/duplicate";
export const ALERTS_SETTINGS_ACTIVATION_ENDPOINT = API_URL + "/alertsSettings/activation";

// Concepts set
export const CONCEPTS_SET_ENDPOINT = API_URL + "/conceptsSet";
export const CONCEPTS_SET_DETAILS_ENDPOINT = API_URL + "/conceptsSet/detail";
export const CONCEPTS_SET_CONCEPTS_ENDPOINT = API_URL + "/conceptsSet/concepts";
export const DUPLICATE_CONCEPTS_SET_ENDPOINT = API_URL + "/conceptsSet/duplicate";

// Concept structure
export const CONCEPT_STRUCTURE_ENDPOINT = API_URL + "/conceptStructure";
export const IMPORT_STRUCTURES = API_URL + "/importConceptStructure";

// Third party classification
export const THIRD_PARTY_CLASSIFICATION = API_URL + "/thirdPartyClassification";
export const THIRD_PARTY = API_URL + "/thirdParty";

//Labor Union
export const LABOR_UNION = API_URL + "/laborUnions";

//Payment Types
export const PAYMENT_TYPES = API_URL + "/paymentTypes";

// Document templates
export const DOCUMENT_TEMPLATES = API_URL + "/documentTemplates";
export const UPLOAD_DOCUMENT_TEMPLATES = API_URL + "/documentTemplates/upload";
export const DOWNLOAD_DOCUMENT_TEMPLATES = API_URL + "/documentTemplates/download";

//Assets and discounts creation
export const ASSETS_DISCOUNTS_CREATION_PAYROLL_TYPES = API_URL + "/createAssetsDiscountsPayrollTypes";
export const ASSETS_DISCOUNTS_CREATION = API_URL + "/createAssetsDiscounts";
export const ASSETS_DISCOUNTS_CREATION_DUPLICATE = API_URL + "/duplicateCreateAssetsDiscounts";
export const ASSETS_DISCOUNTS_CREATION_ORDER = API_URL + "/orderAssetsDiscounts";

// Assets and discounts screen selector
export const ASSETS_DISCOUNTS_QUANTITY_PER_SCREEN = API_URL + "/getQuantityPerScreen";

//Digital warehouse file types
export const DIGITAL_WAREHOUSE_FILE_TYPES = API_URL + "/digitalWarehouseFileTypes";

// schooling
export const SCHOOLING = API_URL + "/schooling";

// studies
export const STUDIES = API_URL + "/studies";

// specializations
export const SPECIALIZATIONS = API_URL + "/specializations";

// languages
export const LANGUAGES = API_URL + "/languages";

// Holiday Table
export const HOLIDAY_TABLE_ENDPOINT = API_URL + "/holidayTable";

// Extra hours Table
export const EXTRA_HOURS_TABLE_ENDPOINT = API_URL + "/extraHoursTable";

// Festivities Table
export const FESTIVITIES_TABLE_ENDPOINT = API_URL + "/festivitiesTable";

//carga masiva carpeta digital
export const MASSIVE_UPLOAD_DIGITAL_FOLDER = API_URL + "/annexes/massiveUpload";

// retirement definition
export const RETIREMENT_DEFINITION = API_URL + "/retirementDefinition";

// health definition
export const HEALTH_DEFINITION = API_URL + "/healthDefinition";

// Assets discounts - entry per concept
export const ASSETS_DISCOUNTS_ENTRY_PER_CONCEPT = API_URL + "/entryPerConceptAssetsDiscounts";
export const ASSETS_DISCOUNTS_ENTRY_PER_CONCEPT_BASIC_ELEMENTS = API_URL + "/initialGetEntryPerConcept";
export const ENTRY_PER_CONCEPT = API_URL + "/entryPerConcept";
export const ENTRY_PER_CONCEPT_DUPLICATE = API_URL + "/entryPerConcept/duplicate";
export const ENTRY_PER_CONCEPT_INITIAL = API_URL + "/entryPerConceptInitial";
export const ENTRY_PER_CONCEPT_MYPEOPLE = API_URL + "/entryPerConceptMyPeople";

// Assets discounts - entry per society
export const ASSETS_DISCOUNTS_ENTRY_PER_SOCIETY = API_URL + "/entryPerSocietyAssetsDiscounts";
export const ASSETS_DISCOUNTS_ENTRY_PER_SOCIETY_BASIC_ELEMENTS = API_URL + "/initialGetEntryPerSociety";

// Actions
export const ACTIONS = API_URL + "/actions";

// digital warehouse
export const DIGITAL_WAREHOUSE = API_URL + "/digitalWarehouse";
export const DIGITAL_WAREHOUSE_DOWNLOAD = API_URL + "/digitalWarehouse/download";

// familiy asignations
export const FAMILY_ASIGNATIONS = API_URL + "/familyAsignations";

// indices
export const RATINGS = API_URL + "/ratings";
export const DUPLICATE_RATINGS = API_URL + "/ratings/duplicate";

//tax table
export const TAX_TABLE = API_URL + "/taxTable";

// visualizacion de resumen
export const ASSETS_DISCOUNTS_SUMMARY = API_URL + "/assetsDiscountsSummary";

export const ATTENDANCE_REPORT = API_URL + "/attendanceReport";

export const ASSETS_DISCOUNTS_ENTRY_PER_CONCEPT_SET = API_URL + "/entryPerConceptSetAssetsDiscounts";
export const ASSETS_DISCOUNTS_ENTRY_PER_CONCEPT_SET_BASIC_ELEMENTS = API_URL + "/initialGetEntryPerConceptSet";

export const DOWNLOAD_IMPORT_STRUCTURE = API_URL + "/downloadImportStructure";
export const ASSETS_DISCOUNTS_ENTRY_BY_IMPORT = API_URL + "/entryByImport";

// procesos automaticos
export const ASSETS_DISCOUNTS_AUTOMATIC_PROCESSES = API_URL + "/automaticProcesses";
export const VIEW_ASSETS_DISCOUNTS_AUTOMATIC_PROCESSES = API_URL + "/defaultProcessResults";

// imputaciones contables
export const ACCOUNTING_IMPUTATIONS = API_URL + "/accountingImputations";
export const DUPLICATE_ACCOUNTING_IMPUTATIONS = API_URL + "/accountingImputations/duplicate";

// special process definition
export const SPECIAL_PROCESS_DEFINITION_ENDPOINT = API_URL + "/specialProcessDefinition";
export const SPECIAL_PROCESS_PARAMETERS_DEFINITION_ENDPOINT = API_URL + "/specialProcessParamDefinition";
export const DUPLICATE_SPECIAL_PROCESS_PARAMETERS_DEFINITION_ENDPOINT = API_URL + "/specialProcessParamDefinition/duplicate";

//process Scheduling
export const PROCESS_SCHEDULING_ENDPOINT = API_URL + "/processScheduling";

// accounting entries
export const ACCOUNTING_ENTRIES_ENDPOINT = API_URL + "/accountingEntries";

export const DOWNLOAD_R8_DESKTOP_ENDPOINT = API_URL + "/downloadDesktopR8";

export const FAVOURITE_FUNCTIONS_ENDPOINT = API_URL + "/favoriteFunction";

// visited pages
export const VISITED_PAGE_ENDPOINT = API_URL + "/visitedPages";

export const MY_DESKTOP_ENDPOINT = API_URL + "/myDesktop";
export const ABSENT_COLLABORATORS_ENDPOINT = API_URL + "/absentCollaborators";

export const SPECIAL_PROCESS_EXECUTION = API_URL + "/specialProcessExecution";
export const SPECIAL_PROCESS_QUERY = API_URL + "/specialProcessQuery";

export const MY_PEOPLE_SCHEDULE = API_URL + "/myPeopleSchedule";
export const MY_SCHEDULE = API_URL + "/mySchedule";

export const REBUILD_ACCUMULATORS = API_URL + "/rebuildAccumulators";

export const TURN_DEFINITION_ENDPOINT = API_URL + "/turnDefinition";
export const SCHEDULES_DEFINITION_ENDPOINT = API_URL + "/schedulesDefinition";
export const SCHEDULES_PROGRAMMING_ENDPOINT = API_URL + "/schedulesProgramming";

export const MASSIVE_UPLOAD_PHOTOS_ENDPOINT = API_URL + "/uploadMassivePhotos";
export const MASSIVE_SALARY_CHANGE_ENDPOINT = API_URL + "/updateMassiveSalary";
export const MASSIVE_TERMINATION_ENDPOINT = API_URL + "/massTerminations";
export const MASSIVE_WORKING_DAY_ENDPOINT = API_URL + "/massiveWorkingDay";
export const MASSIVE_CONTRACT_ENDPOINT = API_URL + "/updateMassiveContracts";
export const MASSIVE_WORK_OR_CHORE_ENDPOINT = API_URL + "/updateMassiveWorkOrChore";
export const MASSIVE_POSITION_CHANGE_ENDPOINT = API_URL + "/updateMassivePositions";
export const MASSIVE_POSITION_RECOMPOSE_CHANGE_ENDPOINT = API_URL + "/updateMassivePositions/recompose";
export const MASSIVE_PAYROLL_ENDPOINT = API_URL + "/updateMassivePayroll";
export const MASSIVE_TURN_ENDPOINT = API_URL + "/updateMassiveTurns";
export const MASSIVE_MARKING_SYSTEM_ENDPOINT = API_URL + "/updateMassiveMarkingSystem";
export const MASSIVE_DISTRIBUTION_COST_UPLOAD_ENDPOINT = API_URL + "/distributionCost/upload";
export const MASSIVE_DISTRIBUTION_COST_DOWNLOAD_ENDPOINT = API_URL + "/distributionCost/download";
export const MASSIVE_REHIRING_ENDPOINT = API_URL + "/updateMassiveRehiring";
export const MASSIVE_FAMILY_GROUP_DOWNLOAD_ENDPOINT = API_URL + "/familyGroup/download";
export const MASSIVE_FAMILY_GROUP_UPLOAD_ENDPOINT = API_URL + "/familyGroup/upload";
export const MASSIVE_STRUCTURE_ENDPOINT = API_URL + "/updateMassiveDepartment";
export const UPDATE_CODE_ENDPOINT = API_URL + "/updateEmployeeCode";
export const MASSIVE_COST_CENTER_ENDPOINT = API_URL + "/updateMassiveCostCenter";
export const MASSIVE_ONBOARDING_ASSIGNMENT_ENDPOINT = API_URL + "/massiveOnboardingAssignment";

export const HISTORIC_ACCUMULATORS_ENDPOINT = API_URL + "/historicAccumulators";

export const HISTORIC_ACCUMULATORS_SPREADSHEET_ENDPOINT = API_URL + "/downloadImportStructureHistoricAccumulators";
export const HISTORIC_ACCUMULATORS_IMPORT_SPREADSHEET_ENDPOINT = API_URL + "/entryByImportHistoricAccumulators";
export const FIND_MATCH_HISTORIC_ACCUMULATORS_ENDPOINT = API_URL + "/historicAccumulators/findMatch";
export const GROUP_IMAGE_ORGCHART_ENDPOINT = BASE_URL + "/defaultGallery/orgChart/groupPicture.svg";
export const ASSETS_DISCOUNTS_ENTRY_BY_INSTALLMENTS = API_URL + "/assetsDiscountsEntryByInstallments";
export const ASSETS_DISCOUNTS_INITIAL_GET_ENTRY_BY_INSTALLMENTS = API_URL + "/initialGetEntryPerInstallments";

export const ALERTS_TEMPLATES_ENDPOINT = API_URL + "/alertsTemplates";

export const CALENDAR_DEFINITION_ENDPOINT = API_URL + "/calendarDefinition";
export const DOWNLOAD_CALENDAR_DEFINITION_ENDPOINT = API_URL + "/downloadImportStructureTimeCalendar";
export const IMPORT_CALENDAR_DEFINITION_ENDPOINT = API_URL + "/entryByImportTimeCalendar";
export const DOWNLOAD_ORGCHART_PDF_ENDPOINT = API_URL + "/generatePdfFromHtml";

// Autorizacion de horas extras
export const EXTRA_HOURS_AUTHORIZATION = API_URL + "/extraHoursAuthorization";
export const IMPORT_EXTRA_HOURS_AUTHORIZATION_STRUCTURE = API_URL + "/importExtraHoursAuthorizationStructure";
export const EXPORT_EXTRA_HOURS_AUTHORIZATION_STRUCTURE = API_URL + "/exportExtraHoursAuthorizationStructure";

//issue summary
export const ISSUE_SUMMARY_ENDPOINT = API_URL + "/issueSummary";
export const ISSUE_SUMMARY_MATRIX_ENDPOINT = API_URL + "/issueSummary/matrix";
export const ISSUE_SUMMARY_GROUPING_ENDPOINT = API_URL + "/issueSummary/grouping";

// issues licences
export const ISSUES_LICENCES_ENDPOINT = API_URL + "/issueLicences";
export const ISSUES_LICENCES_DOWNLOAD_ENDPOINT = API_URL + "/issueLicences/download";
export const ISSUES_LICENCES_UPLOAD_ENDPOINT = API_URL + "/issueLicences/upload";

// Nine box classification
export const NINE_BOX_CLASSIFICATION_INITIAL_ENDPOINT = API_URL + "/nineBoxClassification/initial";
export const NINE_BOX_CLASSIFICATION_ENDPOINT = API_URL + "/nineBoxClassification";
export const NINE_BOX_CLASSIFICATION_LIST_ENDPOINT = API_URL + "/nineBoxClassification/list";

// issues permissions
export const ISSUES_PERMISSIONS_ENDPOINT = API_URL + "/issuePermissions";
export const ISSUES_PERMISSIONS_DOWNLOAD_ENDPOINT = API_URL + "/issuePermissions/download";
export const ISSUES_PERMISSIONS_UPLOAD_ENDPOINT = API_URL + "/issuePermissions/upload";
export const ISSUES_PERMISSIONS_BALANCE_ENDPOINT = API_URL + "/issuePermissions/balance";
export const ISSUES_PERMISSIONS_GENERATE_FILE_ENDPOINT = API_URL + "/issuePermissions/generate";

// issues absences
export const ISSUES_ABSENCES_ENDPOINT = API_URL + "/issueAbsences";
export const ISSUES_ABSENCES_DOWNLOAD_ENDPOINT = API_URL + "/issueAbsences/download";
export const ISSUES_ABSENCES_UPLOAD_ENDPOINT = API_URL + "/issueAbsences/upload";

// issues permissions
export const ISSUES_VACATIONS_ENDPOINT = API_URL + "/issueVacations";
export const ISSUES_VACATIONS_DOWNLOAD_ENDPOINT = API_URL + "/issueVacations/download";
export const ISSUES_VACATIONS_UPLOAD_ENDPOINT = API_URL + "/issueVacations/upload";
export const ISSUES_VACATIONS_GENERATE_FILE_ENDPOINT = API_URL + "/issueVacations/generate";

// tipos de consentimiento
export const CONSENT_TYPES = API_URL + "/consentTypes";
export const UPLOAD_CONSENT_TYPES = API_URL + "/consentTypes/upload";
export const DOWNLOAD_CONSENT_TYPES = API_URL + "/consentTypes/download";

// benefits center
export const BENEFITS_CENTER_ENDPOINT = API_URL + "/benefitsCenter";

// projects
export const PROJECTS_ENDPOINT = API_URL + "/projects";

// consent publication
export const CONSENT_PUBLICATION_ENDPOINT = API_URL + "/consentPublication";

// family group
export const FAMILY_GROUP_ENDPOINT = API_URL + "/familyGroup";

export const PAYROLLS_ENDPOINT = API_URL + "/payrolls";
export const DISTRIBUTION_COST_ENDPOINT = API_URL + "/distributionCost";

export const HISTORICAL_PERIODS_ENDPOINT = API_URL + "/historicalPeriods";
export const HISTORICAL_PERIODS_BY_PERIOD_ENDPOINT = API_URL + "/historicalPeriods/period";

//my approved consents
export const MY_APPROVED_CONSENTS_ENDPOINT = API_URL + "/myApprovedConsents";

export const QUERIES_GIVEN_LEVEL_ENDPOINT = API_URL + "/getQueriesGivenUserLevel";

// my consents reception
export const ACCEPT_MY_CONSENT_ENDPOINT = API_URL + "/consentPublication/reception";
export const PREVIEW_MY_CONSENT_ENDPOINT = API_URL + "/consentPublication/preview";

// Processes by path
export const GET_PROCESSES_BY_PATH_ENDPOINT = API_URL + "/processesByPath";
export const EXECUTE_PROCESSES_BY_PATH_ENDPOINT = API_URL + "/processesByPath/execute";

// User tables definition
export const USER_TABLES_DEFINITION_ENDPOINT = API_URL + "/userTablesDefinition";
export const USER_TABLES_DEFINITION_DUPLICATE_ENDPOINT = API_URL + "/userTablesDefinition/duplicate";
export const USER_TABLES_DEFINITION_FIELDS_ENDPOINT = API_URL + "/userTablesDefinition/fields";
export const USER_TABLES_DEFINITION_EXPORT = API_URL + "/userTablesDefinition/export";
export const USER_TABLES_DEFINITION_IMPORT = API_URL + "/userTablesDefinition/import";

// catalogs
export const CATALOGS_ENDPOINT = API_URL + "/catalogs";
export const CATALOGS_FIELDS_ENDPOINT = API_URL + "/catalogs/fields";
export const ENABLE_SYSTEM_TABLE_EDITORS_ENDPOINT = API_URL + "/catalogs/systemTableEditors";

// User tables
export const USER_TABLES_ENDPOINT = API_URL + "/userTables";
export const USER_TABLES_FORM_ENDPOINT = API_URL + "/userTables/form";
export const USER_TABLES_FORM_CHANGE_CHECK_ENDPOINT = API_URL + "/userTables/form/check";
export const USER_TABLES_EXECUTE_ENDPOINT = API_URL + "/userTables/execute";
export const USER_TABLES_UPLOAD_ENDPOINT = API_URL + "/userTables/upload";
export const USER_TABLES_DOWNLOAD_ENDPOINT = API_URL + "/userTables/download";
export const USER_TABLES_FORM_UPLOAD_FILE_ENDPOINT = API_URL + "/userTables/form/file";

// related tables definition
export const RELATED_TABLES_DEFINITION_ENDPOINT = API_URL + "/relatedTablesDefinition";

// system tables definition
export const SYSTEM_TABLES_DEFINITION_ENDPOINT = API_URL + "/systemTablesDefinition";
export const SYSTEM_TABLES_DEFINITION_DUPLICATE_ENDPOINT = API_URL + "/systemTablesDefinition/duplicate";
export const SYSTEM_TABLES_DEFINITION_FIELDS_ENDPOINT = API_URL + "/systemTablesDefinition/fields";
export const SYSTEM_TABLES_DEFINITION_EXPORT = API_URL + "/systemTablesDefinition/export";
export const SYSTEM_TABLES_DEFINITION_IMPORT = API_URL + "/systemTablesDefinition/import";

// system tables
export const SYSTEM_TABLES_ENDPOINT = API_URL + "/systemTables";
export const SYSTEM_TABLES_FORM_ENDPOINT = API_URL + "/systemTables/form";
export const SYSTEM_TABLES_FORM_CHANGE_CHECK_ENDPOINT = API_URL + "/systemTables/form/check";
export const SYSTEM_TABLES_EXECUTE_ENDPOINT = API_URL + "/systemTables/execute";
export const SYSTEM_TABLES_UPLOAD_ENDPOINT = API_URL + "/systemTables/upload";
export const SYSTEM_TABLES_DOWNLOAD_ENDPOINT = API_URL + "/systemTables/download";
export const SYSTEM_TABLES_FORM_UPLOAD_FILE_ENDPOINT = API_URL + "/systemTables/form/file";

// transaction log
export const TRANSACTION_LOG_ENDPOINT = API_URL + "/transactionLog";
export const TRANSACTION_LOG_SEARCH_ENDPOINT = API_URL + "/transactionLog/search";

// assistance analysis
export const GET_ASSISTANCE_ANALYSIS_ENDPOINT = API_URL + "/assistanceAnalysis";
export const GET_ASSISTANCE_ANALYSIS_TOTALS_ENDPOINT = API_URL + "/assistanceAnalysis/totals";
export const GET_ASSISTANCE_ANALYSIS_TOTALS_DASHBOARD_ENDPOINT = API_URL + "/assistanceAnalysis/totals/dashboard";
export const GET_ASSISTANCE_ANALYSIS_INDIVIDUAL_ENDPOINT = API_URL + "/assistanceAnalysis/individual";
export const EXECUTE_ASSISTANCE_ANALYSIS_TOTALS_ENDPOINT = API_URL + "/assistanceAnalysis/totals/execute";
export const EXECUTE_ASSISTANCE_ANALYSIS_INDIVIDUAL_ENDPOINT = API_URL + "/assistanceAnalysis/individual/execute";
export const ASSISTANCE_ANALYSIS_INDIVIDUAL_UPDATE_OBSERVED_ENDPOINT = API_URL + "/assistanceAnalysis/individual/updateObserved";
export const ASSISTANCE_ANALYSIS_INDIVIDUAL_CHANGE_SCHEDULE_ENDPOINT = API_URL + "/assistanceAnalysis/individual/changeSchedule";
export const ASSISTANCE_ANALYSIS_INDIVIDUAL_AUTH_EXTRA_HOURS_ENDPOINT = API_URL + "/assistanceAnalysis/individual/authExtraHours";
export const ASSISTANCE_ANALYSIS_INDIVIDUAL_JUSTIFY_ABSENCE_ENDPOINT = API_URL + "/assistanceAnalysis/individual/justifyAbsence";

export const AVAILABLE_SOCIETIES_ENDPOINT = API_URL + "/availableSocieties";

// Types of Benefits
export const TYPES_OF_BENEFITS = API_URL + "/typesOfBenefits";

// Types of Score
export const TYPES_OF_SCORE = API_URL + "/typesOfScore";

// Rating scales
export const RATING_SCALES = API_URL + "/ratingScales";

// Benefits for plans
export const BENEFITS_FOR_PLANS = API_URL + "/benefitsForPlans";

// Organizational benefits
export const ORGANIZATIONAL_BENEFITS = API_URL + "/organizationalBenefits";

// Feedback purpose
export const FEEDBACK_PURPOSE_ENDPOINT = API_URL + "/feedbackPurpose";

// Group classification
export const GROUP_CLASSIFICATION_ENDPOINT = API_URL + "/groupClassification";

// ReasonForPointAwardin
export const REASON_FOR_POINT_AWARDING_ENDPOINT = API_URL + "/reasonForPointAwarding";

// poll review
export const POLL_REVIEW_ENDPOINT = API_URL + "/pollReview";
export const POLL_REVIEW_QUESTIONS_ENDPOINT = API_URL + "/pollReview/questions";
export const POLL_REVIEW_ANSWERS_ENDPOINT = API_URL + "/pollReview/answers";
export const DOWNLOAD_POLL_REVIEW_ENDPOINT = API_URL + "/pollReview/download";
export const EXECUTE_POLL_REVIEW_ENDPOINT = API_URL + "/pollReview/execute";

// medal table
export const MEDAL_TABLE_ENDPOINT = API_URL + "/medalTable";
export const MEDAL_TABLE_ADJUSTMENT_ENDPOINT = API_URL + "/medalTable/adjustment";

// Recognition plans assignment
export const RECOGNITION_PLANS_ENDPOINT = API_URL + "/recognitionPlans";
export const RECOGNITION_PLANS_DUPLICATE_ENDPOINT = API_URL + "/recognitionPlans/duplicate";
export const RECOGNITION_PLANS_ASSIGNMENT_ENDPOINT = API_URL + "/recognitionPlans/assignment";
export const RECOGNITION_PLANS_ASSIGNMENT_ACTIVATION_ENDPOINT = API_URL + "/recognitionPlans/assignment/activation";
export const RECOGNITION_PLANS_ASSIGNMENT_CHANGE_PLAN_ENDPOINT = API_URL + "/recognitionPlans/assignment/changePlans";

// Performance forms
export const PERFORMANCE_FORMS_ENDPOINT = API_URL + "/performanceForms";
export const PERFORMANCE_FORMS_DUPLICATE_ENDPOINT = API_URL + "/performanceForms/duplicate";

// Performance evaluation
export const PERFORMANCE_EVALUATIONS_INITIAL_ENDPOINT = API_URL + "/performanceEvaluations/initial";
export const PERFORMANCE_EVALUATIONS_RESUME_ENDPOINT = API_URL + "/performanceEvaluations/resume";
export const PERFORMANCE_EVALUATIONS_ENDPOINT = API_URL + "/performanceEvaluations";
export const PERFORMANCE_EVALUATIONS_SEND_EMAIL_ENDPOINT = API_URL + "/performanceEvaluations/sendMail";

// Performance summary and results
export const PERFORMANCE_SUMMARY_RESULTS_ENDPOINT = API_URL + "/performanceSummaryAndResults";
export const PERFORMANCE_SUMMARY_PERSONAL_RESULTS_ENDPOINT = API_URL + "/performanceSummaryAndResults/resume";
export const ACCEPT_REJECT_PERFORMANCE_SUMMARY_RESULTS_ENDPOINT = API_URL + "/performanceSummaryAndResults/acceptReject";
export const PREVIEW_PERFORMANCE_SUMMARY_RESULTS_ENDPOINT = API_URL + "/performanceSummaryAndResults/preview";

// Performance evaluation calibration
export const PERFORMANCE_EVALUATIONS_CALIBRATION_INITIAL_ENDPOINT = API_URL + "/performanceEvaluations/calibration/initial";
export const PERFORMANCE_EVALUATIONS_CALIBRATION_LIST_ENDPOINT = API_URL + "/performanceEvaluations/calibration/list";
export const PERFORMANCE_EVALUATIONS_CALIBRATION_GRAPHICS_ENDPOINT = API_URL + "/performanceEvaluations/calibration/graphics";
export const PERFORMANCE_EVALUATIONS_CALIBRATION_SKILLS_ENDPOINT = API_URL + "/performanceEvaluations/calibration/skills";
export const PERFORMANCE_EVALUATIONS_CALIBRATION_FORM_ENDPOINT = API_URL + "/performanceEvaluations/calibration";
export const PERFORMANCE_EVALUATIONS_CALIBRATION_MAIL_ENDPOINT = API_URL + "/performanceEvaluations/calibration/message";

// my benefits
export const MY_BENEFITS_ENDPOINT = API_URL + "/myBenefits";
export const REQUEST_MY_BENEFITS_ENDPOINT = API_URL + "/myBenefits/request";
export const MY_BENEFITS_HISTORICAL_ENDPOINT = API_URL + "/myBenefits/historical";

// Nine Box
export const NINE_BOX_SETTINGS_ENDPOINT = API_URL + "/nineBoxSettings";
export const NINE_BOX_SETTINGS_ORDER_ENDPOINT = API_URL + "/nineBoxSettings/order";

// recognition analysis
export const RECOGNITION_ANALYSIS_ENDPOINT = API_URL + "/recognitionAnalysis";

// Action Type Settings
export const ACTION_TYPES_ENDPOINT = API_URL + "/actionTypes";

// poll comparison
export const POLL_COMPARISON_ENDPOINT = API_URL + "/pollComparison";
export const POLL_COMPARISON_QUESTIONS_ENDPOINT = API_URL + "/pollComparison/questions";
export const POLL_COMPARISON_ANSWERS_ENDPOINT = API_URL + "/pollComparison/answers";

// feed
export const FEED_ENDPOINT = API_URL + "/feed";
export const FEED_INITIAL_ENDPOINT = API_URL + "/feed/initial";
export const FEED_STATUS_ENDPOINT = API_URL + "/feed/status";
export const FEED_LIKES_ENDPOINT = API_URL + "/feed/likes";
export const FEED_PARTICIPATION_ENDPOINT = API_URL + "/feed/participation";
export const FEED_PARTICIPATION_DOWNLOAD_ENDPOINT = API_URL + "/feed/participation/download";
export const FEED_COMMENTS_ENDPOINT = API_URL + "/feed/comments";
export const FEED_COMMENTS_STATUS_ENDPOINT = API_URL + "/feed/comments/status";

// feedback
export const FEEDBACK_ENDPOINT = API_URL + "/feedback";
export const FEEDBACK_FILTER_ENDPOINT = API_URL + "/feedback/filter";
export const FEEDBACK_PRIVACY_OPTIONS_ENDPOINT = API_URL + "/feedback/privacyOptions";
export const FEEDBACK_LIKES_ENDPOINT = API_URL + "/feedback/likes";
export const FEEDBACK_COMMENTS_ENDPOINT = API_URL + "/feedback/comments";
export const FEEDBACK_GOALS_ENDPOINT = API_URL + "/feedback/goals";

// user menus
export const USER_MENUS_ENDPOINT = API_URL + "/userMenus";

// social blacklist
export const SOCIAL_BLACKLIST_ENDPOINT = API_URL + "/socialBlacklist";

// typesOfObjectives
export const TYPES_OF_OBJECTIVES_ENDPOINT = API_URL + "/typesOfObjectives";

// positionGroupsForEvaluations
export const POSITION_GROUPS_FOR_EVALUATIONS_ENDPOINT = API_URL + "/positionGroupsForEvaluations";

//periodsForObjectives
export const PERIODS_FOR_OBJECTIVES_ENDPOINT = API_URL + "/periodsForObjectives";

// types of skill transversal
export const TYPES_OF_SKILLS_TRANSVERSAL_ENDPOINT = API_URL + "/typesOfSkills/transversal";
export const TYPES_OF_SKILLS_TRANSVERSAL_DUPLICATE_ENDPOINT = API_URL + "/typesOfSkills/transversal/duplicate";
export const TYPES_OF_SKILLS_TRANSVERSAL_INDICATORS_ENDPOINT = API_URL + "/typesOfSkills/transversal/indicators";
export const TYPES_OF_SKILLS_TRANSVERSAL_INDICATOR_ENDPOINT = API_URL + "/typesOfSkills/transversal/indicator";

// types of skill specific
export const TYPES_OF_SKILLS_SPECIFIC_ENDPOINT = API_URL + "/typesOfSkills/specific";
export const TYPES_OF_SKILLS_SPECIFIC_DUPLICATE_ENDPOINT = API_URL + "/typesOfSkills/specific/duplicate";
export const TYPES_OF_SKILLS_SPECIFIC_DUPLICATE_GROUP_ENDPOINT = API_URL + "/typesOfSkills/specific/duplicate/group";
export const TYPES_OF_SKILLS_SPECIFIC_INDICATORS_ENDPOINT = API_URL + "/typesOfSkills/specific/indicators";
export const TYPES_OF_SKILLS_SPECIFIC_INDICATOR_ENDPOINT = API_URL + "/typesOfSkills/specific/indicator";
export const TYPES_OF_SKILLS_SPECIFIC_VERIFY_ENDPOINT = API_URL + "/typesOfSkills/specific/verify";

// goals and objectives
export const GOALS_AND_OBJECTIVES_ENDPOINT = API_URL + "/goalsAndObjectives";
export const GOALS_AND_OBJECTIVES_VERIFY_ENDPOINT = API_URL + "/goalsAndObjectives/verify";
export const GOALS_AND_OBJECTIVES_TASKS_ENDPOINT = API_URL + "/goalsAndObjectives/tasks";
export const GOALS_AND_OBJECTIVES_ONE_ENDPOINT = API_URL + "/goalsAndObjectives/one";

// evaluation process definition
export const EVALUATION_PROCESS_DEFINITION_ENDPOINT = API_URL + "/evaluationProcessDefinition";
export const EVALUATION_PROCESS_DEFINITION_DUPLICATE_ENDPOINT = API_URL + "/evaluationProcessDefinition/duplicate";
export const CHECK_COLLABORATORS_EVALUATION_PROCESS_DEFINITION_ENDPOINT = API_URL + "/evaluationProcessDefinition/checkCollaborators";
export const SEND_MAIL_EVALUATION_PROCESS_DEFINITION_ENDPOINT = API_URL + "/evaluationProcessDefinition/message";
export const EVALUATION_PROCESS_DEFINITION_ASSIGNMENT_ENDPOINT = API_URL + "/evaluationProcessDefinition/assignment";
export const DOWNLOAD_EVALUATION_PROCESS_DEFINITION_ASSIGNMENT_ENDPOINT = API_URL + "/evaluationProcessDefinition/assignment/download";
export const UPLOAD_EVALUATION_PROCESS_DEFINITION_ASSIGNMENT_ENDPOINT = API_URL + "/evaluationProcessDefinition/assignment/upload";
export const EVALUATORS_LIST_EVALUATION_PROCESS_DEFINITION_ASSIGNMENT_ENDPOINT = API_URL + "/evaluationProcessDefinition/assignment/evaluatorsList";
export const SET_EVALUATOR_EVALUATION_PROCESS_DEFINITION_ASSIGNMENT_ENDPOINT = API_URL + "/evaluationProcessDefinition/assignment/evaluator";

// performance analytics evaluation
export const PERFORMANCE_ANALYTICS_EVALUATION_ENDPOINT = API_URL + "/performanceAnalytics/evaluations";
export const PERFORMANCE_ANALYTICS_EVALUATION_SCORE_ENDPOINT = API_URL + "/performanceAnalytics/evaluations/score";
export const PERFORMANCE_ANALYTICS_EVALUATION_SUMMARY_ENDPOINT = API_URL + "/performanceAnalytics/evaluations/summary";

// performance analytics evaluation
export const PERFORMANCE_ANALYTICS_TALENT_ENDPOINT = API_URL + "/performanceAnalytics/talent";
export const PERFORMANCE_ANALYTICS_TALENT_QUADRANT_ENDPOINT = API_URL + "/performanceAnalytics/talent/quadrant";
export const PERFORMANCE_ANALYTICS_TALENT_SUMMARY_ENDPOINT = API_URL + "/performanceAnalytics/talent/summary";

// communication Classification
export const COMMUNICATION_CLASSIFICATION_ENDPOINT = API_URL + "/communicationClassification";

// ClassificationApplicantsTypes
export const CLASSIFICATION_APPLICANTS_TYPES_ENDPOINT = API_URL + "/classificationApplicantsTypes";

// TypesOfPublicationChannels
export const TYPES_OF_PUBLICATION_CHANNELS = API_URL + "/typesOfPublicationChannels";

// TypesOfPublicationFormats
export const TYPES_OF_PUBLICATION_FORMATS = API_URL + "/typesOfPublicationFormats";

// TypesOfOnboardingActions
export const TYPES_OF_ONBOARDING_ACTIONS = API_URL + "/typesOfOnboardingActions";

// Onboarding Profiles
export const ONBOARDING_PROFILES_ENDPOINT = API_URL + "/onboardingProfiles";
export const ONBOARDING_PROFILES_DUPLICATE_ENDPOINT = API_URL + "/onboardingProfiles/duplicate";
export const ONBOARDING_PROFILES_TREE_VIEW_ENDPOINT = API_URL + "/onboardingProfiles/materialTree";


// Onboarding materials
export const ONBOARDING_MATERIALS_ENDPOINT = API_URL + "/onboardingMaterials";

// utils
export const UTILS_ENDPOINT = API_URL + "/utils";

// onboarding processes
export const ONBOARDING_PROCESSES_ENDPOINT = API_URL + "/onboardingProcesses";
export const ONBOARDING_PROCESSES_ASSIGNMENT_ENDPOINT = API_URL + "/onboardingProcesses/assignment";

// onboarding tracking
export const ONBOARDING_TRACKING_ENDPOINT = API_URL + "/onboardingTracking";
export const ONBOARDING_TRACKING_MOMENTS_ENDPOINT = API_URL + "/onboardingTracking/moments";
export const ONBOARDING_TRACKING_PROGRESS_ENDPOINT = API_URL + "/onboardingTracking/progress";
export const ONBOARDING_TRACKING_TREE_ENDPOINT = API_URL + "/onboardingTracking/tree";
export const ONBOARDING_TRACKING_ACTIONS_ENDPOINT = API_URL + "/onboardingTracking/actions";
export const ONBOARDING_TRACKING_ACTION_ENDPOINT = API_URL + "/onboardingTracking/action";
export const ONBOARDING_TRACKING_ACTION_REPLICATE_ENDPOINT = API_URL + "/onboardingTracking/action/replicate";
export const ONBOARDING_TRACKING_ACTION_RESPONSIBLE_ENDPOINT = API_URL + "/onboardingTracking/action/responsible";
export const ONBOARDING_TRACKING_COLLABORATORS_ENDPOINT = API_URL + "/onboardingTracking/collaborators";
export const ONBOARDING_TRACKING_COLLABORATOR_ENDPOINT = API_URL + "/onboardingTracking/collaborator";
export const ONBOARDING_TRACKING_COMMENTS_ENDPOINT = API_URL + "/onboardingTracking/action/comments";
export const ONBOARDING_TRACKING_FILES_ENDPOINT = API_URL + "/onboardingTracking/action/files";
export const ONBOARDING_TRACKING_FILE_ENDPOINT = API_URL + "/onboardingTracking/action/file";

// my onboarding tracking
export const MY_ONBOARDING_TRACKING_ENDPOINT = API_URL + "/myOnboardingTracking";
export const MY_ONBOARDING_TRACKING_MOMENTS_ENDPOINT = API_URL + "/myOnboardingTracking/moments";
export const MY_ONBOARDING_TRACKING_PROGRESS_ENDPOINT = API_URL + "/myOnboardingTracking/progress";
export const MY_ONBOARDING_TRACKING_ACTIONS_ENDPOINT = API_URL + "/myOnboardingTracking/actions";
export const MY_ONBOARDING_TRACKING_MATERIAL_ENDPOINT = API_URL + "/myOnboardingTracking/material";
export const MY_ONBOARDING_TRACKING_COMMENTS_ENDPOINT = API_URL + "/myOnboardingTracking/action/comments";
export const MY_ONBOARDING_TRACKING_FILES_ENDPOINT = API_URL + "/myOnboardingTracking/action/files";
export const MY_ONBOARDING_TRACKING_FILE_ENDPOINT = API_URL + "/myOnboardingTracking/action/file";

// performance Analytics historic
export const PERFORMANCE_ANALYTICS_HISTORIC_ENDPOINT = API_URL + "/performanceAnalytics/historic";