import React from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createBenefitsCenterAction, updateBenefitsCenterAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, handleClose, dimensionList } = props;
  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createBenefitsCenterAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateBenefitsCenterAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={'Código'}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Nombre'}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
      </Grid>
      <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* dimension */}
        <FormControl style={{ width: "100%" }}>
          <InputLabel id={`label-dimension-${data ? data.code : "new"}`} required>{"Dimensión"}</InputLabel>
          <Select
            value={formData.dimension}
            labelId={`label-dimension-${data ? data.code : "new"}`}
            id={`select-dimension-${data ? data.code : "new"}`}
            onChange={(e) => setFormValue(e.target.value, "dimension")}
            margin={"none"}
          >
            {
              dimensionList && dimensionList.map(item => (
                <MenuItem
                  className={"whiteText"}
                  key={item.key}
                  value={item.key}>
                  {item.value}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;
