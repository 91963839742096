import React from "react";
import { CloseIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle } from "@material-ui/core";
import NewVacationContent from "./newVacationContent";
import NewPermissionContent from "./newPermissionContent";
import NewLoanContent from "./newLoanContent";
import { getLocalizedString } from "@icarius-localization/strings";
import { requestsIds } from "@icarius-utils/requestsIds";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const RequestDialog = (props) => {

  const {
    open,
    isForEdit,
    isReadOnly,
    editData,
    requestCode,
    advanceCalculationData,
    permissionCalculationData,
    vacationCalculationData,
    typesAdvances,
    typesPermission,
    requestType,
    hideViewAccountability,
    recipients,
    usedDates,
    allowHalfDayHolidays,
    startHolidaysOnNonWorkingDay,
    handleRequest,
    handleNoRecipient,
    handleViewBalance,
    handleCloseViewDialog,
    handleClose,
  } = props;

  const handleCloseDialog = (value) => {
    if (isReadOnly) {
      handleCloseViewDialog();
    }

    if (isForEdit && typeof value === "boolean" && value) {
      handleClose(true);
    } else {
      handleClose();
    }
  };

  const validateDateRange = (start, end) => {
    const formatedStart = start.setHours(0, 0, 0, 0);
    const formatedEnd = end.setHours(0, 0, 0, 0);

    let resp = true;

    if (usedDates) {
      usedDates.forEach((date) => {
        let formatedDate = createDateFromDDMMYYYY(date);

        if (formatedDate >= formatedStart && formatedDate <= formatedEnd) {
          resp = false;
        }
      })
    }

    return resp;
  }

  const renderContent = () => {
    switch (requestType) {
      case requestsIds.id_type.VAC:
        return (
          <NewVacationContent
            isForEdit={isForEdit}
            isReadOnly={isReadOnly}
            editData={editData}
            requestCode={requestCode}
            startHolidaysOnNonWorkingDay={startHolidaysOnNonWorkingDay}
            allowHalfDayHolidays={allowHalfDayHolidays}
            validateDateRange={validateDateRange}
            vacationCalculationData={vacationCalculationData}
            recipients={recipients}
            handleNoRecipient={handleNoRecipient}
            hideViewAccountability={hideViewAccountability}
            handleRequest={handleRequest}
            handleClose={handleCloseDialog}
          />
        );
      case requestsIds.id_type.PERM:
        return (
          <NewPermissionContent
            isForEdit={isForEdit}
            isReadOnly={isReadOnly}
            editData={editData}
            requestCode={requestCode}
            validateDateRange={validateDateRange}
            typesPermission={typesPermission}
            permissionCalculationData={permissionCalculationData}
            recipients={recipients}
            handleViewBalance={handleViewBalance}
            handleNoRecipient={handleNoRecipient}
            handleRequest={handleRequest}
            handleClose={handleCloseDialog}
          />
        );
      case requestsIds.id_type.ANT:
        return (
          <NewLoanContent
            isForEdit={isForEdit}
            isReadOnly={isReadOnly}
            editData={editData}
            advanceCalculationData={advanceCalculationData}
            typesAdvances={typesAdvances}
            requestCode={requestCode}
            recipients={recipients}
            handleNoRecipient={handleNoRecipient}
            handleRequest={handleRequest}
            handleClose={handleCloseDialog}
          />
        );
      default:
        return "";
    }
  };

  const StyledDialog = withStyles({
    paper: {
      maxWidth: requestType === requestsIds.id_type.ANT ? "820px" : "90%",
      minWidth: requestType === requestsIds.id_type.ANT && window.innerWidth > 959 ? "820px" : "50%",
    },
  })(Dialog);

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {(!isForEdit || isReadOnly) && `${getLocalizedString("requestCreateTitle")} ${requestType}`}
        {isForEdit && !isReadOnly && `${getLocalizedString("requestEditTitle")} ${requestType}`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleCloseDialog} />
      {renderContent()}
    </StyledDialog>
  );
}

export default RequestDialog;