import { getLocalizedString } from "@icarius-localization/strings";

//Breakpoints
export const XS_DEVICE_WIDTH_BREAKPOINT = 600;
export const HIDE_LOGO_HEADER_WIDTH_BREAKPOINT = XS_DEVICE_WIDTH_BREAKPOINT;
export const HIDE_SEARCH_HEADER_WIDTH_BREAKPOINT = 1000;
export const MD_DEVICE_WIDTH_BREAKPOINT = 960;
export const LG_DEVICE_WIDTH_BREAKPOINT = 1280;

//LOGIN
export const MIN_PIN_LENGTH = 4;
export const MAX_PIN_LENGTH = 10;
export const MIN_CLIENT_LENGTH = 1;
export const MAX_CLIENT_LENGTH = 30;
export const MIN_USER_LENGTH = 1;
export const MAX_USER_LENGTH = 20;

//TEMPLATES
export const MIN_TEMPLATE_LENGTH = 4;
export const MAX_TEMPLATE_LENGTH = 60;

//Animation Direction
export const ANIMATION_DIRECTION = "left";

//Hover opacity
export const HOVER_OPACITY = 0.70;
export const HOVER_OPACITY_LIGHT = 0.50;

export const MIN_MOTIVE_LENGTH = 1;
export const MAX_MOTIVE_LENGTH = 254;

//////
export const roles = {
  employer: "E",
  manager: "M",
  collaborator: "C",
};

export const roleNames = {
  [roles.employer]: getLocalizedString("employer"),
  [roles.manager]: getLocalizedString("manager"),
  [roles.collaborator]: getLocalizedString("collaborator"),
};

export const customFileTypes = {
  icarius: 'icarius',
  icariusSmart: 'IcariusSmart',
}

export const TABS = {
  personal: 0,
  location: 1,
  contract: 2,
  remuneration: 3,
  time: 4,
  specialClassification: 5,
  others: 6,
}

export const fieldTypes = {
  text: 0,
  imageUrl: 1,
  email: 4,
  address: 5,
  textHeader: 6,
  empty: 7,
  phone: 8,
  number: 9,
  check: 10,
  date: 11,
};

export const likeTypes = {
  like: "L",
  unlike: "D",
  unset: "N",
};

export const contactCols = {
  phone: "GO_TELEFONO",
  email: "GO_EMAIL",
  cellphone: "GO_CELULAR",
  twitter: "GO_TWITTER",
  linkedin: "GO_LINKEDIN",
  instagram: "GO_INSTAGRAM",
  facebook: "GO_FACEBOOK",
  address: "GO_ADDRESS",
};

export const templatesOptions = [
  {
    code: "EX",
    label: getLocalizedString("templateExclusiveToMe"),
    profiles: ["MANAGER", "EMPLEADOR", "COLABORADOR"],
  },
  {
    code: "TD",
    label: getLocalizedString("templateAllCollaborators"),
    profiles: ["COLABORADOR", "EMPLEADOR"],
  },
  {
    code: "TM",
    label: getLocalizedString("templateAllManagers"),
    profiles: ["MANAGER", "EMPLEADOR"],
  },
  {
    code: "TE",
    label: getLocalizedString("templateAllEmployers"),
    profiles: ["EMPLEADOR"],
  },
  {
    code: "SP",
    label: getLocalizedString("templateOnlyEmployersPlantPersonnel"),
    profiles: ["EMPLEADOR"],
  },
];

export const yesNoArray = [
  { key: "Y", value: 'Si' },
  { key: "N", value: 'No' },
];