import React from "react";
import {
    Grid,
    Select,
    FormControl,
    TextField,
    InputLabel,
    InputAdornment,
    MenuItem,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import Loader from "@icarius-common/loader";
import NoteList from "./noteList/noteList";
import NoteDialog from "./NoteDialog";
import LabelDialog from "./label/LabelDialog";
import { useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { getAppColor } from "src/app/selectors";
import TasksGridDialog from "./tasksGridDialog";
import { getTheme } from "@icarius-pages/login/selectors";
import DuplicateDialog from "./duplicateDialog";
import MyToDo from "./myToDoScreen";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import {
    PieChartIcon,
    SearchIcon,
    EditIcon,
} from "@icarius-icons";
import SwapVertIcon from '@material-ui/icons/SwapVert';
import ArchiveIcon from '@material-ui/icons/Archive';
import ListIcon from '@material-ui/icons/List';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import NoData from "@icarius-common/abmComponents/noData";
import HomeHeader from "@icarius-pages/home/components/homeHeader";
import useNotes from "./useNotes";
import { getDateFormat } from "../selectors";
import useNotesProgress from "./useNotesProgress";

const emptyNote = {
    "id": "",
    "title": "",
    "description": "",
    "archive": false,
    "image": "",
    "time": "",
    "reminder": null,
    "checklist": [],
    "labels": [],
    "sharedWith": "",
}

const Notes = () => {

    const color = useSelector(getAppColor);
    const gridTheme = useSelector(getTheme);
    const dateFormat = useSelector(getDateFormat);

    const {
        notesData,
        notesFunctions,
    } = useNotes();

    const {
        notesProgressData,
        notesProgressFunctions,
    } = useNotesProgress(notesData.notes, notesData.societyPeople, notesData.userData);

    const createButton = () => (
        <CustomIconButton
            title={'Crear tarea'}
            onClick={() => notesFunctions.setSelectedNote(emptyNote)}
            type={"add"}
        />
    )

    const progressButton = () => (
        <CustomIconButton
            title={'Progreso'}
            onClick={() => notesProgressFunctions.setGridDialogIsOpen(true)}
        >
            <ListIcon />
        </CustomIconButton>
    )

    const moreDataButton = () => (
        <CustomIconButton
            title={'Mi avance'}
            onClick={() => notesFunctions.setMyToDoScreenIsOpen(true)}
        >
            <PieChartIcon />
        </CustomIconButton>
    )

    const archivarButton = () => (
        <CustomIconButton
            title={notesData.showArchived ? 'Ver todas' : 'Ver archivadas'}
            onClick={() => notesFunctions.setShowArchived(prev => !prev)}
        >
            {notesData.showArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
        </CustomIconButton>
    )

    const orderButton = () => (
        <CustomIconButton
            title={'Invertir orden'}
            onClick={() => notesFunctions.setOrder(prev => prev === "0" ? "1" : "0")}
        >
            <SwapVertIcon />
        </CustomIconButton>
    )

    const searchAndTagHeader = () => {
        return (
            <>
                <div style={{ width: 200, minWidth: 200 }}>
                    <TextField
                        fullWidth
                        onChange={e => notesFunctions.setSearchInput(e.target.value)}
                        label={getLocalizedString("searchLabel")}
                        type="text"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ fill: color, cursor: "pointer" }} />
                                </InputAdornment>
                            ),
                        }}
                        value={notesData.searchInput}
                    />
                </div>
                <div style={{ width: 200, minWidth: 200, paddingLeft: 10 }}>
                    <FormControl fullWidth>
                        <InputLabel id={`label`}>{'Etiquetas'}</InputLabel>
                        <Select
                            value={notesData.filteredType}
                            labelId={`label`}
                            id={`select`}
                            onChange={(e) => notesFunctions.setFilteredType(e.target.value)}
                            margin={"none"}
                        >
                            <MenuItem className={"whiteText"} value={''}>
                                {'Todos'}
                            </MenuItem>
                            {
                                notesData.labels.map((item) => (
                                    <MenuItem
                                        className={"whiteText"}
                                        key={item.labelCode}
                                        value={item.labelCode}
                                    >
                                        {item.nombre}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
                <Tooltip title={'Editar etiquetas'}>
                    <IconButton onClick={() => notesFunctions.setCreateLabelDialogIsOpen(true)} style={{ marginTop: 12 }}>
                        <EditIcon htmlColor={color} />
                    </IconButton>
                </Tooltip>
            </>
        )
    }

    return (
        <>
            <Loader open={notesData.loading} />
            <HomeHeader />
            {
                !notesData.myToDoScreenIsOpen &&
                <CommonPage
                    isNotGridPage
                    customHeader={searchAndTagHeader}
                    menuItems={[createButton, orderButton, archivarButton, progressButton, moreDataButton]}
                >
                    <Grid container direction="row" item style={{ marginTop: 20 }}>
                        {
                            notesData?.notes?.length === 0 ?
                                (
                                    !notesData.loading &&
                                    <NoData
                                        title={'No se encontraron tareas'}
                                        createTitle={'Crear tarea'}
                                        onClick={() => notesFunctions.setSelectedNote(emptyNote)}
                                    />
                                )
                                :
                                <NoteList
                                    searchInput={notesData.searchInput}
                                    order={notesData.order}
                                    showArchived={notesData.showArchived}
                                    filteredType={notesData.filteredType}
                                    items={notesData.notes}
                                    labels={notesData.labels}
                                    openNoteDialog={(note) => notesFunctions.setSelectedNote(note)}
                                    societyPeople={notesData.societyPeople}
                                    groups={notesData.groups}
                                    color={color}
                                    acceptNote={notesFunctions.acceptNote}
                                    refuseNote={notesFunctions.refuseNote}
                                />
                        }
                    </Grid>
                </CommonPage>
            }
            {
                notesData.myToDoScreenIsOpen &&
                <Grid
                    style={{ width: "100%", minWidth: 300, margin: "0 auto" }}
                    direction={"row"}
                    alignItems="flex-start"
                    justify="center"
                    container
                >
                    <MyToDo
                        notes={notesData.notes}
                        openNoteDialog={(codNota) => notesFunctions.openNoteFromMyToDo(codNota)}
                        societyPeople={notesData.societyPeople}
                        color={color}
                        groups={notesData.groups}
                        handleGoBack={() => notesFunctions.setMyToDoScreenIsOpen(false)}
                        gridTheme={gridTheme}
                    />
                </Grid>
            }
            {
                !notesData.loading && Boolean(notesData.selectedNote) &&
                <NoteDialog
                    open={Boolean(notesData.selectedNote)}
                    selectedNote={notesData.selectedNote}
                    labels={notesData.labels}
                    handleOnRemove={notesFunctions.handleDeleteNote}
                    handleClose={() => notesFunctions.setSelectedNote(null)}
                    handleCreateNote={notesFunctions.createNewNote}
                    handleEditNote={notesFunctions.handleEditNote}
                    handleDuplicateNote={notesFunctions.handleDuplicate}
                    societyPeople={notesData.societyPeople}
                    groups={notesData.groups}
                    color={color}
                    userCode={notesData.userData?.code}
                    societySelected={notesData.societySelected}
                    dateFormat={dateFormat}
                    gridData={notesProgressData.gridData}
                    handleSendMail={notesProgressFunctions.handleSendMail}
                />
            }
            {
                !notesData.loading && Boolean(notesData.createLabelDialogIsOpen) &&
                <LabelDialog
                    open={Boolean(notesData.createLabelDialogIsOpen)}
                    tags={notesData.labels}
                    handleCreateLabel={notesFunctions.createNewLabel}
                    handleEditLabel={notesFunctions.editLabel}
                    handleRemoveLabel={notesFunctions.removeLabel}
                    handleClose={() => notesFunctions.setCreateLabelDialogIsOpen(false)}
                />
            }
            {
                notesProgressData.gridDialogIsOpen && !notesData.loading &&
                <TasksGridDialog
                    open={notesProgressData.gridDialogIsOpen}
                    data={notesProgressData.gridData}
                    dateFormat={dateFormat}
                    handleClose={() => notesProgressFunctions.setGridDialogIsOpen(false)}
                    handleSendMail={notesProgressFunctions.handleSendMail}
                />
            }
            {
                !notesData.loading && notesData.duplicationDialogIsOpen &&
                <DuplicateDialog
                    open={notesData.duplicationDialogIsOpen}
                    data={notesData.duplicationData}
                    handleSubmit={notesFunctions.createNewNote}
                    handleClose={notesFunctions.handleCloseDuplicationDialog}
                />
            }
        </>
    )
}

export default Notes;
