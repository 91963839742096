import React from 'react';
import { IconButton, ListItem, Input } from '@material-ui/core';
import { DeleteIcon } from "@icarius-icons";
import clsx from 'clsx';

const TagFormListItem = (props) => {

    const {
        item,
        onBlur,
        onListItemChange,
        onListItemRemove,
    } = props;

    const handleChange = (event) => {
        onListItemChange({ labelCode: item.labelCode, nombre: event.target.value });
    }

    const handleBlur = (event) => {
        onBlur({ labelCode: item.labelCode, nombre: event.target.value });
    }

    return (
        <ListItem className="p-0" dense>
            <Input
                className={clsx("flex flex-1 mx-8")}
                value={item.nombre}
                onChange={handleChange}
                onBlur={handleBlur}
                disableUnderline
            />
            <IconButton
                className="w-32 h-32 mx-4 p-0"
                aria-label="Delete"
                onClick={() => onListItemRemove(item.labelCode)}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
        </ListItem>
    );
}

export default TagFormListItem;