import React, { useState, useEffect, useMemo } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import {
  List,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import CreateEditDialog from "@icarius-pages/annexesPage/components/dialogs/createEditDialog";
import Timeline from '@icarius-pages/annexesPage/components/timeline';
import useAnnexes from "@icarius-pages/annexesPage/components/useAnnexes";
import { AccountTreeIcon } from "@icarius-icons/index";
import paths from "@icarius-localization/paths";
import { useSelector } from "react-redux";
import { getUserData } from "src/app/selectors";

const useForceFirstRender = () => {
  const [count, setCount] = useState(0)
  useEffect(() => {
    if (!count) setCount(1);
  }, [count])
}

const MyAnnexesPage = () => {

  useForceFirstRender();
  const userData = useSelector(getUserData);

  const {
    state,
    handlers,
    arrayToRender,
    isLoading,
    colabModifiableDocuments,
    year,
    setYear,
    yearsArray,
    getAnnexInstanceName,
  } = useAnnexes();

  const instancesToUse = useMemo(() => {
    return colabModifiableDocuments.map(item => ({ "key": item.key, "value": item.name }));
  }, [colabModifiableDocuments])

  const typesToUse = useMemo(() => {
    let dataToReturn = {};

    colabModifiableDocuments.forEach((item) => {
      const types = item.values.map(doc => ({ "key": doc.keyTipoDocumento, "value": doc.valueTipoDocumento }));
      dataToReturn[item["key"]] = types;
    });

    return dataToReturn;
  }, [colabModifiableDocuments])

  const showTimeline = year !== '';
  const title = getLocalizedString("myAnnexesTitle");

  const downloadItem = (gridRef) =>
    <CustomIconButton
      type={'download'}
      title={'Descargar'}
      onClick={() => handlers.handleOpenDownloadDialog(gridRef, { Codigo: userData.code })}
    />

  const previewItem = (gridRef) =>
    <CustomIconButton
      type={'preview'}
      title={'Previsualizar PDF'}
      onClick={() => handlers.handlePreview(gridRef)}
    />

  const addItem = () =>
    colabModifiableDocuments.length > 0 &&
    <CustomIconButton
      type={'add'}
      title={getLocalizedString("iconNewAnnex")}
      onClick={handlers.handleOpenCreateDialog}
    />

  const editItem = (gridRef) =>
    <CustomIconButton
      type={'edit'}
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      type={'delete'}
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
    />

  const dataTreeButton = (gridRef) => (
    <CustomIconButton
      title={state.treeViewIsOpen ? "Ver como lista" : "Ver como árbol"}
      onClick={() => handlers.handleGrouping(gridRef)}
    >
      <AccountTreeIcon />
    </CustomIconButton>
  )

  const filterItem = () =>
    <FormControl style={{ width: 200, marginRight: 10 }}>
      <InputLabel shrink id={'label-type'}>{'Línea de tiempo'}</InputLabel>
      <Select
        labelId={'label-type'}
        id={'select-type'}
        value={year}
        onChange={e => setYear(e.target.value)}
        displayEmpty
      >
        <MenuItem value="">{'Desactivada'}</MenuItem>
        {
          yearsArray.map(item => (
            <MenuItem key={item.key} value={item.key}>
              {item.value}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>

  return (
    <>
      <div style={{ display: showTimeline ? "block" : "none" }}>
        <CommonPage
          title={title}
          isLoading={isLoading}
          audioName={"Mi carpeta digital"}
          customHeader={filterItem}
          isNotGridPage
        >
          <List className="list-round-container" style={{ overflowX: 'hidden', marginBottom: 10 }}>
            <Timeline
              data={arrayToRender}
              getAnnexInstanceName={getAnnexInstanceName}
            />
          </List>
        </CommonPage>
      </div>
      <div style={{ display: !showTimeline ? "block" : "none" }}>
        <CommonPage
          title={title}
          isLoading={isLoading}
          audioName={"Mi carpeta digital"}
          menuItems={[addItem, editItem, deleteItem, previewItem, downloadItem, dataTreeButton]}
          customHeader={filterItem}
          columnDefPage={paths.myDigitalFolder}
          rowData={arrayToRender}
          hasHelp
          hasExpand
        />
      </div>
      {
        state.createDialogIsOpen &&
        <CreateEditDialog
          open={state.createDialogIsOpen}
          types={typesToUse}
          instances={instancesToUse}
          employeeCode={userData.code}
          handleClose={handlers.handleCloseCreateDialog}
        />
      }
      {
        state.editDialogIsOpen &&
        <CreateEditDialog
          open={state.editDialogIsOpen}
          annex={state.annex}
          types={typesToUse}
          instances={instancesToUse}
          employeeCode={userData.code}
          handleClose={handlers.handleCloseEditDialog}
        />
      }
    </>
  );
}

export default MyAnnexesPage;