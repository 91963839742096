import React, { useState } from "react";
import {
  Grid,
  Tooltip,
  IconButton,
  Card,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import { isKeyPresent, getValueFromKey } from "@icarius-utils/localStorage";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const gridStyle = { height: 75, padding: "0px 10px" };

const TotemItem = (props) => {

  const {
    isEdit,
    data,
    deleteHandle,
    workplaces,
    index,
    color,
    updateTotem,
    addTotem,
  } = props;

  const [isOpen, setIsOpen] = useState(!isEdit);
  const [code, setCode] = useState(data.code || "");
  const [workplace, setWorkplace] = useState(data.workplace_id || "");

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (workplace && code) {
      if (isEdit) {
        updateTotem({ code, workplace })
      } else {
        addTotem({ code, workplace });
      }
      handleClosePublication();
    } else {
      dispatch(openSnackbarAction({ msg: "Todos los datos son obligatorios", severity: 'warning' }));
    }
  }

  const handleClosePublication = () => {
    isEdit && setIsOpen(prev => !prev);
  }

  return (
    <Grid container item xs={12} style={{ margin: "10px 0px" }}>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || !isEdit) ? color : "", color: (isOpen || !isEdit) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={11} style={{ cursor: isEdit ? "pointer" : "" }} onClick={handleClosePublication}>
            {
              !isEdit ?
                <>
                  <DataContainer lg={12} sm={12} md={12} text={'Creación de tótem'} />
                </>
                :
                <>
                  <DataContainer sm={12} md={3} lg={3} text={data.code} />
                  <DataContainer sm={12} md={3} lg={3} text={workplaces.find(item => item.id === data.workplace_id)?.value || ''} />
                  {
                    isKeyPresent("assistance-totem-id") && getValueFromKey("assistance-totem-id") === data.code &&
                    <DataContainer sm={12} md={6} lg={6} text={'Asociado a este equipo'} />
                  }
                </>
            }
          </Grid>

          <Grid container direction="row" item xs={1} justify="center">
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton
                size={"small"}
                disableRipple
                onClick={() => {
                  if (isEdit) {
                    deleteHandle(data, isEdit, index);
                    return;
                  }
                  deleteHandle({ code, workplace, isEdit, index }, false, index);
                }}
              >
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <form onSubmit={handleSubmit} noValidate style={{ width: "100%" }}>
          <Grid container item xs={12} style={{ paddingTop: 10 }}>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                fullWidth
                required
                label={getLocalizedString("totemID")}
                disabled={isEdit ? true : false}
                value={code}
                onChange={(e) => setCode(e.target.value.toString().slice(0, 19))}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-workplace`}>
                  {getLocalizedString("workplace")}
                </InputLabel>
                <Select
                  value={workplace}
                  labelId={`label-workplace`}
                  id={`workplace`}
                  onChange={(e) => setWorkplace(e.target.value)}
                  margin={"none"}
                >
                  {
                    workplaces?.map((subItem, index) => (
                      <MenuItem
                        className={"whiteText"}
                        key={index}
                        value={subItem.id}>
                        {subItem.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
              <ButtonDialogAction isAccept onClick={handleSubmit} text={getLocalizedString("save")} />
            </Grid>
          </Grid>
        </form>
      }
    </Grid>
  );
}

export default TotemItem;