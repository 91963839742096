import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { downloadBlob, previewFromURL } from "@icarius-utils/download";
import {
  getReceiptAPI,
  getCollaboratorsForReceiptAPI,
  downloadReceiptsAPI,
  getCollaboratorsWithDocumentsToSignAPI,
  signAndDownloadApi,
  previewDocumentsAPI,
  sendReceiptsAPI
} from "@icarius-connection/api";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";

export const getInitialReceiptData = (isSignable) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_INITIAL });
  try {
    // traer recibos y procesos de calculo
    const responseReceiptList = await getReceiptAPI(isSignable ? `?signable=${isSignable}` : "");
    const receipts = responseReceiptList.data.receipts;
    const calculationProcesses = responseReceiptList.data.calculation_processes;

    // traer colaboradores default (sin query)
    const responseCollaborators = await getCollaboratorsForReceiptAPI("");
    const collaborators = responseCollaborators.data && responseCollaborators.data.colaborators;
    const dateFormat = responseCollaborators.data.date_format;
    const locale = responseCollaborators.data.currency_localization;

    if (collaborators.length <= 0) {
      dispatch({ type: actionTypes.GET_INITIAL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("noEmployeesFound"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_INITIAL_FULFILLED,
      payload: {
        collaborators,
        locale,
        dateFormat,
        receipts,
        calculationProcesses,
      },
    });
    return responseReceiptList;
  } catch (e) {
    dispatch({ type: actionTypes.GET_INITIAL_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getColaboratorsForReceiptsAction = (receipt, process, isRegularReceipt, rol) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_COLABORATORS });

  const shouldNotUseSign = (isRegularReceipt || receipt === "" || process === "");
  const endpointToUse = shouldNotUseSign ? getCollaboratorsForReceiptAPI : getCollaboratorsWithDocumentsToSignAPI;

  try {
    let response = await endpointToUse(shouldNotUseSign ? `?code=${receipt}&reference=${process}` : `?code=${receipt}&reference=${process}&role=${rol}`);
    let collaborators = response.data && (response.data.colaborators || response.data.destinataries);
    let dateFormat = response.data.date_format;
    let locale = response.data.currency_localization;
    let status = response.data && response.data.status;

    if (status === "OK") {
      if (collaborators.length <= 0) {
        dispatch({ type: actionTypes.GET_COLABORATORS_FULFILLED, payload: { colaborators: [] } });
        isRegularReceipt && dispatch(openSnackbarAction({ msg: getLocalizedErrorString("noEmployeesFound"), severity: "error" }));
        return;
      }

      dispatch({
        type: actionTypes.GET_COLABORATORS_FULFILLED,
        payload: {
          collaborators,
          locale,
          dateFormat,
        },
      });
    } else {
      dispatch({ type: actionTypes.GET_COLABORATORS_REJECTED });
    }

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_COLABORATORS_REJECTED, payload: e });
    if (e.response?.statusText) {
      switch (e.response.statusText) {
        case "Unauthorized":
          dispatch(openSnackbarAction({ msg: getLocalizedString("receiptUnauth"), severity: "error" }));
          break;
        default:
          dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
      }
    }
    return e.response.status;
  }
};

export const downloadReceiptAction = (code, reference, colaborator_codes, disable_socket) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    let response = await downloadReceiptsAPI({
      code,
      reference,
      colaborator_codes,
      disable_socket,
    });

    let documents = response.data;
    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FULFILLED,
      payload: {
        documents: documents,
      },
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const sendReceiptsAction = (code, reference, colaborator_codes) => async (dispatch) => {
  dispatch({ type: actionTypes.SEND });
  try {
    let response = await sendReceiptsAPI({
      code,
      reference,
      colaborator_codes,
    });

    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: actionTypes.SEND_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    if (response.status !== 200) {
      dispatch({ type: actionTypes.SEND_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.SEND_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: "Recibo enviado con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SEND_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const signAndDownloadAction = (docs, password) => async (dispatch) => {
  dispatch({ type: actionTypes.SIGN_AND_DOWNLOAD });
  try {
    dispatch(openSnackbarAction({ msg: getLocalizedString("settingDocuments"), severity: "info", duration: null }));

    let response = await signAndDownloadApi({ docs, password });
    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: actionTypes.SIGN_AND_DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response.status;
    }

    if (response.status !== 200) {
      dispatch({ type: actionTypes.SIGN_AND_DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response.status;
    }

    dispatch({
      type: actionTypes.SIGN_AND_DOWNLOAD_FULFILLED,
      payload: { docs }
    });

    return response.status;
  } catch (e) {
    dispatch({ type: actionTypes.SIGN_AND_DOWNLOAD_REJECTED, payload: e });

    if (e?.response?.data?.status === "VALIDATION_ERROR" && e.response?.data?.rejectedValues[0].error === "TEMPLATE_NOT_FOUND") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("documentInvalidTemplate"), severity: "error" }));
    } else {
      switch (e.response?.statusText) {
        case "Unauthorized": //pin incorrecto
          dispatch(openSnackbarAction({ msg: getLocalizedString("receiptWrongPing"), severity: "error" }));
          break;
        case "Forbidden": //usuario no habilitado
          dispatch(openSnackbarAction({ msg: getLocalizedString("receiptUnauth"), severity: "error" }));
          break;
        case "Unprocessable Entity":
          dispatch(openSnackbarAction({ msg: getLocalizedString("receiptWrongDocument"), severity: "error" }));
          break;
        default:
          dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
      }
    }
    return e.response.status;
  }
};

export const previewDocumentsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    let response = await previewDocumentsAPI({ docs: data });
    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({ type: actionTypes.DOWNLOAD_FULFILLED });
    previewFromURL(RESOURCES_ENDPOINT + response.data.path);

    return response;
  } catch (e) {
    if (e?.response?.data?.status === "VALIDATION_ERROR" && e?.response?.data?.error === "TEMPLATE_NOT_FOUND") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("documentInvalidTemplate"), severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }

    dispatch({ type: actionTypes.DOWNLOAD_REJECTED, payload: e });
  }
};