import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const CustomButtonYes = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "#16520b",
        },
        backgroundColor: "#268a15",
        marginRight: 10,
        color: "white",
    },
})(Button);

const CustomButtonNo = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "#871313",
        },
        backgroundColor: "#df1b1b",
        color: "white",
    },
})(Button);

const ReplyDialog = (props) => {

    const {
        open,
        title,
        editData,
        vacationCalculationData,
        handleAccept,
        handleCloseDialog,
    } = props;

    const [comment, setComment] = useState("");

    const textIsInvalid = () => {
        return comment.length > 1023;
    };

    const validateAndConfirm = (accept) => {
        if (textIsInvalid()) return;
        handleConfirm(accept);
    };

    const handleConfirm = (accept) => {
        handleAccept(comment, accept);
    };

    const handleClose = (shouldComponentUpdate) => {
        handleCloseDialog(shouldComponentUpdate);
    };

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            open={open}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <div className={"dialog-container"}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                    <DialogTitleDivider />
                </DialogTitle>
                <CloseIcon className={"dialog-close-icon icon"} onClick={() => handleClose(false)} />
                <DialogContent>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12}>
                            {
                                editData &&
                                vacationCalculationData &&
                                editData.id_type === "VAC" && editData.id_subtype === "UV" &&
                                editData.duration_days > vacationCalculationData.available_legal_vacation_days &&
                                <Grid container item style={{ paddingBottom: 15 }}>
                                    <Typography
                                        style={{ paddingTop: 5, color: 'red', fontWeight: 700 }}
                                        variant="caption"
                                        gutterBottom>
                                        {
                                            editData.duration_days === 1 ?
                                                `El día solicitado supera el saldo disponible en la cuenta corriente en ${editData.duration_days - vacationCalculationData.available_legal_vacation_days} día${(editData.duration_days - vacationCalculationData.available_legal_vacation_days) > 1 ? "s" : ""}` :
                                                `Los ${editData.duration_days} días solicitados superan el saldo disponible en la cuenta corriente en ${editData.duration_days - vacationCalculationData.available_legal_vacation_days} día${(editData.duration_days - vacationCalculationData.available_legal_vacation_days) > 1 ? "s" : ""}`
                                        }
                                    </Typography>
                                </Grid>
                            }

                            <TextField
                                fullWidth
                                label={getLocalizedString("insertComment")}
                                multiline={true}
                                rows={1}
                                rowsMax={3}
                                value={comment}
                                onChange={(e) => setComment(e.target.value.toString().slice(0, 1024))}
                            />
                            <Typography
                                style={{ paddingTop: 5 }}
                                className={"whiteText"}
                                variant="caption"
                                gutterBottom
                            >
                                {`${getLocalizedString("optionalField")}. ${getLocalizedString("currentChar")} ${comment ? comment.length : 0}. ${getLocalizedString("maxCharRequests")}.`}
                            </Typography>
                            <DialogActions>
                                <CustomButtonYes name="yesButton" variant="contained" onClick={() => validateAndConfirm(true)}>
                                    {getLocalizedString("approveRequest")}
                                </CustomButtonYes>
                                <CustomButtonNo name="noButton" variant="contained" onClick={() => validateAndConfirm(false)}>
                                    {getLocalizedString("rejectRequest")}
                                </CustomButtonNo>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
}

export default ReplyDialog;