
import React from "react";
import DataGrid, {
    Column,
    Editing,
    Paging,
    PatternRule,
    RequiredRule,
    StringLengthRule,
    NumericRule,
    Selection,
    Lookup,
    CustomRule,
    Button,
    FormItem,
    Scrolling,
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import esMessages from "./esMessages.json";
import { locale, loadMessages } from "devextreme/localization";
import EmployeeTagBoxComponent from "./EmployeeTagBoxComponent.js"
import { createDateFromYYYYMM } from "@icarius-utils/date";
import moment from "moment";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const MONTHS = ["Enero", "Febrero", "Marzo", "Abril", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const dateEditorOptions = {
    useMaskBehavior: true,
};
const notesEditorOptions = { height: 100 };

const dateFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
}

const formatNumberOptions = {
    type: "fixedPoint",
    precision: 3
};

const formatNumberOptionsMax2 = {
    type: "fixedPoint",
    precision: 2
};

const formatNumberOptionsMax0 = {
    type: "fixedPoint",
    precision: 0
};

const amountEditorOptions = {
    format: formatNumberOptions,
    showClearButton: true
};

const amountEditorOptionsMax2 = {
    format: formatNumberOptionsMax2,
    showClearButton: true
};

const getAmountEditorOptions = (decimals) => {
    return {
        format: {
            type: "fixedPoint",
            precision: decimals
        },
        showClearButton: true
    };
}

const amountEditorOptionsMax0 = {
    format: formatNumberOptionsMax0,
    showClearButton: true
};

const decimals = {
    "Valor informado bruto": 4,
    "Valor informado líquido": 3,
    "Porcentaje": 0,
    "Cantidad": 3,
    "Base de cálculo": 5,
}

const lookupColumnEditorOptions = { showClearButton: true }

class EditablGridV2 extends React.Component {
    constructor(props) {
        super(props);
        loadMessages(esMessages);
        locale("es");
        this.dataGrid = null;
        this.state = {
            originalData: [],
            selectedRowIndex: -1,
        }
        this.compareData = this.compareData.bind(this);
        if (this.props.selectDataSources) {
            this.concepts = this.props.selectDataSources("Concepto", "1");
        }
    }

    componentDidMount() {
        this.setState({ originalData: this.props.rowData })
    }

    compareData = () => {
        // Comparo la data actual y la original
        const dataChanged = this.compareDataSources(this.state.originalData, this.getVisibleRows());
        if (this.props.setDataChanged) {
            this.props.setDataChanged(dataChanged)
        }
    }

    getVisibleRows = () => {
        return this.refs.dataGrid._instance.getVisibleRows();
    }

    compareDataSources = (o1, o2) => {
        for (let p in o1) {
            if (o1.hasOwnProperty(p)) {
                if (o1[p] !== o2[p]) {
                    return true;
                }
            }
        }
        for (let p in o2) {
            if (o2.hasOwnProperty(p)) {
                if (o1[p] !== o2[p]) {
                    return true;
                }
            }
        }
        return false;
    };

    componentDidUpdate() {
        if (this.props.shouldReloadGrid) {
            this.setState({ originalData: this.props.rowData });

            if (this.props.shouldReloadGrid) {
                this.props.setShouldReloadGrid(false);
            }
        }
    }

    sortStringsConsideringCulture(value1, value2) {
        if (this.lookup && this.lookup.valueExpr === "key") {
            if (this.lookup.valueMap && this.lookup.valueMap.hasOwnProperty(value1)) {
                value1 = this.lookup.valueMap[value1];
            }
            if (this.lookup.valueMap && this.lookup.valueMap.hasOwnProperty(value2)) {
                value2 = this.lookup.valueMap[value2];
            }
        }

        if (!isNaN(value1) && !isNaN(value2)) {
            value1 = Number(value1);
            value2 = Number(value2);
            return value1 - value2;
        }
        // Handling null values
        if (!value1 && value2) return -1;
        if (!value1 && !value2) return 0;
        if (value1 && !value2) return 1;
        // Determines whether two strings are equivalent in the current locale
        return value1.localeCompare(value2);
    }

    getFormat = (gridName, item) => {
        if (item.isDate) {
            return dateFormat;
        }

        if (item.isNumeric) {
            if (gridName === "holidayTable") return formatNumberOptionsMax0;
        }

        return null;
    }

    getEditorOptions = (gridName, item) => {
        if (item.isDate) {
            return dateEditorOptions;
        }

        if (item.isNumeric) {
            if (gridName === "holidayTable") return amountEditorOptionsMax0;
            if ((gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety") && item.headerName !== "Cantidad") return amountEditorOptionsMax2;

            if (
                gridName === "entryPerConcept" && (
                    item.headerName === "Cantidad" ||
                    item.headerName === "Valor informado bruto" ||
                    item.headerName === "Valor informado líquido" ||
                    item.headerName === "Porcentaje" ||
                    item.headerName === "Base de cálculo")) {
                return getAmountEditorOptions(decimals[item.headerName]);
            }

            return amountEditorOptions
        }
        if (item.isSelect) {
            return lookupColumnEditorOptions;
        }

        return null;
    }

    customCallbackInformedValueType = e => {
        const informedValueIsObligatory = e?.data?.informedValue === "B";
        const informedValueTypeIsSelected = !(e.value === undefined || e.value === null);

        return !(informedValueIsObligatory === true && informedValueTypeIsSelected === false);
    }

    customCallbackSeniorityTo = e => {
        if (isNaN(e.value) || isNaN(e?.data?.seniorityFrom)) {
            return false;
        }

        return e.value >= e.data.seniorityFrom;
    }

    customCallbackHolidayTableGreaterEqualZero = e => {
        return e.value >= 0
    }

    timeToDecimal(t) {
        if (t) {
            let arr = t.split(':');
            let dec = parseInt((arr[1] / 6) * 10, 10);

            return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
        }
    }

    customCallbackEntryPerConceptHoursMinutesValidSyntax = e => {
        if (e.value !== undefined && e.value !== null && e.value !== "" && e.value !== "") {
            return /^(\d|0\d|1\d|2[0-3]):[0-5]\d$/.test(e.value)
        }
        return true;
    }

    customCallbackEntryPerConceptHoursMinutes = e => {
        if (e.value !== undefined && e.value !== null && e.value !== "" && e.value !== "" && e.data["Código de empleado"]) {
            const data = this.props.getColaboratorData(e.data["Código de empleado"]);
            if (data && data["HORAS DIARIAS CONTRATO"] !== null && data["HORAS DIARIAS CONTRATO"] !== "") {
                return this.timeToDecimal(e.value) <= parseFloat(data["HORAS DIARIAS CONTRATO"]);
            }
        }
        return true;
    }

    customCallbackEntryPerConceptHoursMinutesNoData = e => {
        if (e.value !== undefined && e.value !== null && e.value !== "" && e.value !== "") {
            return this.timeToDecimal(e.value) <= this.timeToDecimal("23:59");
        }
        return true;
    }

    customCallbackMax2Decimals = e => {
        if (parseFloat(String(e.value).replace(',', '.')) === 0) return false;
        if (e.value !== undefined && e.value !== null && e.value !== "") {
            return /^\d+(\.\d{3})*(,\d{0,2})*$/.test(e.value);
        }
        return true;
    }

    customCallbackMax2DecimalsAllowZero = e => {
        if (e.value !== undefined && e.value !== null && e.value !== "") {
            return /^\d+(\.\d{3})*(,\d{0,2})*$/.test(e.value);
        }
        return true;
    }

    customCallbackMax2DecimalsAllowNegative = e => {
        if (parseFloat(String(e.value).replace(',', '.')) === 0) return false;
        if (e.value !== undefined && e.value !== null && e.value !== "") {
            return /^-?\d+(\.\d{3})*(,\d{0,2})*$/.test(e.value);
        }
        return true;
    }

    customCallbackMax2DecimalsAllowZeroAllowNegative = e => {
        if (e.value !== undefined && e.value !== null && e.value !== "") {
            return /^-?\d+(\.\d{3})*(,\d{0,2})*$/.test(e.value);
        }
        return true;
    }

    customCallbackExtraHoursTableCode = e => {
        if (e.value !== undefined && e.value !== null && e.value !== "") {
            return /^[A-Z]+[A-Z0-9]*$/.test(e.value);
        }
        return true;
    }

    customCallbackMax3Decimals = e => {
        if (parseFloat(String(e.value).replace(',', '.')) === 0) return false;
        if (e.value !== undefined && e.value !== null && e.value !== "" && e.value !== "") {
            return /^\d+(\.\d{3})*(,\d{0,3})*$/.test(e.value);
        }
        return true;
    }

    customCallbackMax3DecimalsAllowZero = e => {
        if (e.value !== undefined && e.value !== null && e.value !== "" && e.value !== "") {
            return /^\d+(\.\d{3})*(,\d{0,3})*$/.test(e.value);
        }
        return true;
    }

    customCallbackMax5Decimals = e => {
        if (parseFloat(String(e.value).replace(',', '.')) === 0) return false;
        if (e.value !== undefined && e.value !== null && e.value !== "" && e.value !== "") {
            return /^([1-9]\d{0,2}(\.?\d{3})*)(,(\d{1,5}))?$/.test(e.value);
        }
        return true;
    }

    customCallbackMax5DecimalsAllowZero = e => {
        if (e.value !== undefined && e.value !== null && e.value !== "" && e.value !== "") {
            return /^([1-9]\d{0,2}(\.?\d{3})*|0)(,(\d{1,5}))?$/.test(e.value);
        }
        return true;
    }

    customCallbackPeriodInverted = e => {
        if (e.value !== undefined && e.value !== null && e.value !== "") {
            return /20\d{2}\/(0[1-9]|10|11|12)$/.test(e.value);
        }
        return true;
    }

    customCallbackPercentage = e => {
        if (parseFloat(String(e.value).replace(',', '.')) === 0) return false;
        if (e.value !== undefined && e.value !== null && e.value !== "") {
            return /(^100(\.0{1,3})?$)|(^([1-9](\d)?|0)(,\d{1,3})?$)/.test(e.value);
        }
        return true;
    }

    customCallbackPercentageAllowZero = e => {
        if (e.value !== undefined && e.value !== null && e.value !== "") {
            return /(^100(\.0{1,2})?$)|(^([1-9](\d)?|0)(,\d{1,3})?$)/.test(e.value);
        }
        return true;
    }
    customCallbackYear4Digits = e => {
        if (e.value !== undefined && e.value !== null && e.value !== "") {
            return /(^[1-2]\d{3}$)/.test(e.value);
        }
        return true;
    }

    customCallbackPeriodFromTo = e => {
        const periodMin = e.data["Período desde"];
        const periodMax = e.data["Período hasta"];
        if (periodMin && periodMax) {
            // Convierto a date y veo si fallan
            const datePeriodMin = moment(createDateFromYYYYMM(periodMin));
            const datePeriodMax = moment(createDateFromYYYYMM(periodMax));

            return datePeriodMin.isSameOrBefore(datePeriodMax);
        }
        return true;
    }

    customCallbackDateStartEnd = e => {
        const startDateObject = e.data["startDate"];
        const endDateObject = e.data["endDate"];
        if (startDateObject && endDateObject) {
            // Convierto a date y veo si fallan
            const startDate = moment(startDateObject);
            const endDate = moment(endDateObject);
            return startDate.isSameOrBefore(endDate);
        }
        return true;
    }

    customCallbackDateStartEndYear = e => {
        const startDateObject = e.data["startDate"];
        const endDateObject = e.data["endDate"];
        const year = e.data["year"];
        if (startDateObject && endDateObject) {
            const startDateYear = moment(startDateObject).year();
            const endDateYear = moment(endDateObject).year();
            return Number(startDateYear) === Number(year) && Number(endDateYear) === Number(year);
        }
        return true;
    }

    customCallbackEntryPerSocietyDuplicatedConcept = e => {
        const conceptType = e.data["Tipo de concepto"];
        const concept = e.data["Concepto"];
        if (conceptType && concept) {
            return this.getVisibleRows().some(row => row.data["Concepto"] === concept &&
                row.data["Tipo de concepto"] === conceptType
                && row.isEditing === false) === false;
        }
        return true;
    }
    customCallbackExtraHoursAuthorizationDuplicatedEmployeeDate = e => {
        const codeName = e.data["Código de empleado"];
        const date = e.data["Fecha"];
        if (codeName && date) {
            return this.getVisibleRows().filter(row => row.data["Código de empleado"] === codeName &&
                moment(row.data["Fecha"]).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY")
                && row.isEditing === false).length < 2;
        }
        return true;
    }

    customCallbackProjectBenefitCenter = e => {
        const project = e.data["Proyecto"];
        const benefitCenter = e.data["Centro de costo"];
        const benefitCenter2 = e.data["Dimensión 2"];
        const benefitCenter3 = e.data["Dimensión 3"];
        const benefitCenter4 = e.data["Dimensión 4"];
        const benefitCenter5 = e.data["Dimensión 5"];

        return Boolean(project) || Boolean(benefitCenter) || Boolean(benefitCenter2) || Boolean(benefitCenter3) || Boolean(benefitCenter4) || Boolean(benefitCenter5);
    }

    customCallbackValidatePaymentTypeBank = e => {
        const paymentType = e.data["Forma de pago"];

        if (paymentType === "D") {
            return Boolean(e.value)
        }

        return true;
    }

    customCallbackValidatePaymentTypeCC = e => {
        const paymentType = e.data["Forma de pago"];

        if (paymentType === "D") {
            return Boolean(e.value)
        }

        return true;
    }

    onEditorPreparing(options) {
        if (options.dataField === "En fecha" && options.value === undefined) {
            options.editorOptions.value = new Date();
            options.setValue(options.editorOptions.value)
        }

        if (MONTHS.includes(options.dataField) && options.value === undefined) {
            // Pongo por defecto el valor N
            options.editorOptions.value = "N";
            options.setValue(options.editorOptions.value)
        }

        if (options.dataField === "periodicity" && options.value === undefined) {
            // Pongo por defecto el valor F
            options.editorOptions.value = "F";
            options.setValue(options.editorOptions.value)
        }

        if (options.dataField === "code" && options.value !== undefined) {
            options.editorOptions.disabled = true;
        }

        if (options.dataField === "rate" && options.value !== undefined) {
            options.editorOptions.disabled = true;
        }

        if (options.dataField === "Centro de costo" ||
            options.dataField === "Dimensión 2" ||
            options.dataField === "Dimensión 3" ||
            options.dataField === "Dimensión 4" ||
            options.dataField === "Dimensión 5") {
            const selectValues = this.props.selectDataSources(options.dataField);
            if (selectValues.length === 0) {
                options.editorOptions.disabled = true;
            }
        }

        if (options.dataField === "Concepto") {
            const { rowIndex } = options.row;
            const instance = options.component;
            const seccalValues = this.props.selectDataSources("Secuencia de Cálculo");

            let onValueChanged = options.editorOptions.onValueChanged;

            options.editorOptions.onValueChanged = function (e) {
                onValueChanged.call(this, e);
                // Voy a setear el nombre de concepto porque cambie el valor del select
                window.setTimeout(function () {
                    instance.cellValue(rowIndex, "Nombre del concepto", e.component._changedValue);
                }, 100);

                if (seccalValues && seccalValues.length > 0) {
                    const element = seccalValues.find(el => el.key === e.value);

                    if (element) {
                        // También aca voy a setear la seciuencia de calculo
                        window.setTimeout(function () {
                            instance.cellValue(rowIndex, "Secuencia de Cálculo", element.key);
                        }, 100);
                    }
                }
            }

        }

        if (options.dataField === "Hrs Extras Autorizadas") {
            const { rowIndex } = options.row;
            const instance = options.component;
            const that = this;
            let onValueChanged = options.editorOptions.onValueChanged;

            options.editorOptions.onValueChanged = function (e) {
                onValueChanged.call(this, e);
                // Voy a setear el nombre de concepto porque cambie el valor del select
                window.setTimeout(function () {
                    const hoursDone = options.row.data["Hrs Extras Realizadas"];
                    const authHours = options.row.data["Hrs Extras Autorizadas"];
                    let unAuthHours = "00:00";
                    if (hoursDone && hoursDone !== "0:00" && hoursDone !== "00:00") {
                        // Paso a minutos el tiempo autorizado
                        const minutesAuthHours = that.props.getMinutesForHHMM(authHours);

                        // Paso a minutos el tiempo a autorizar
                        const minutesDoneHours = that.props.getMinutesForHHMM(hoursDone);

                        // Obtengo la diferencia de minutos
                        const minutesAuthDifference = minutesDoneHours - minutesAuthHours;

                        // Vuelvo a pasarlo a hh:mm
                        if (!minutesAuthHours) {
                            unAuthHours = that.props.getHHMMforMinutes(minutesDoneHours);
                        } else if (minutesAuthDifference <= 0) {
                            unAuthHours = "00:00";
                        } else {
                            unAuthHours = that.props.getHHMMforMinutes(minutesAuthDifference);
                        }
                    }

                    instance.cellValue(rowIndex, "Hrs Extras Sin Autorizar", unAuthHours);
                }, 100);
            }
        }


        if (options.dataField === "Fecha") {
            const { rowIndex } = options.row;
            const instance = options.component;
            const that = this;
            let onValueChanged = options.editorOptions.onValueChanged;

            options.editorOptions.onValueChanged = function (e) {
                onValueChanged.call(this, e);
                // Voy a setear el nombre de concepto porque cambie el valor del select
                window.setTimeout(function () {
                    const date = options.row.data["Fecha"];
                    const employeeCode = options.row.data["Código de empleado"];
                    const unAuthElement = that.props.getUnAuthData(date, employeeCode);
                    let unAuthHours = "00:00";
                    if (unAuthElement) {
                        unAuthHours = unAuthElement.hhmm;
                    }

                    instance.cellValue(rowIndex, "Hrs Extras Realizadas", unAuthHours);
                    instance.cellValue(rowIndex, "Hrs Extras Sin Autorizar", unAuthHours);
                }, 100);
            }
        }

        if (options.dataField === "Indicador de cálculo" && options.value !== undefined && options.validationRules.some(el => el.type === "required")) {
            const values = this.props.selectDataSources("Indicador de cálculo");
            if (values && values.length === 1) {
                // Pongo por defecto el valor
                options.editorOptions.value = values[0].key;
                options.setValue(options.editorOptions.value)
            }
        }

        if (options.dataField === "Código de referencia" && options.value !== undefined && options.validationRules.some(el => el.type === "required")) {
            const values = this.props.selectDataSources("Código de referencia");
            if (values && values.length === 1) {
                // Pongo por defecto el valor
                options.editorOptions.value = values[0].key;
                options.setValue(options.editorOptions.value)
            }
        }

        if (options.parentType === 'dataRow' && options.dataField === 'Concepto') {
            const test = Number(options.row.data["Tipo de concepto"]);
            options.editorOptions.disabled = isNaN(test) || test === 0;
        }

        if (options.dataField === 'Banco' || options.dataField === "Cuenta bancaria") {
            const paymentTypeIsDeposit = options.row.data["Forma de pago"] === "D";
            if (this?.props?.selectDataSources && paymentTypeIsDeposit) {
                const paymentTypes = this.props.selectDataSources("Forma de pago");
                const element = paymentTypes.find(el => el.key === "D");

                if (element && element.requiresBank === "Y") {
                    options.editorOptions.disabled = false;
                }
            } else {
                options.editorOptions.disabled = true;
            }
        }

        if (options.parentType === 'dataRow' && options.dataField) {
            const data = options.row.data[options.dataField];
            const isRequired = options.validationRules?.some(el => el.type === "required");
            if (isRequired && (data === "" || data === undefined) && options.setValue) {
                setTimeout(() => options.setValue(null))
            }
        }
    }

    getFilteredConcepts = (options) => {
        return {
            store: this.props.selectDataSources("all-concepts"),
            filter: options.data ? ['Tipo de concepto', '=', options.data["Tipo de concepto"]] : null,
        };
    }

    setStateValue(rowData, value) {
        rowData["Concepto"] = null;
        this.defaultSetCellValue(rowData, value);
    }

    setBankValue(rowData, value) {
        rowData["Banco"] = null;
        rowData["Cuenta bancaria"] = null;
        this.defaultSetCellValue(rowData, value);
    }

    setHoursMinutes(rowData, value) {
        if (value !== undefined && value !== null && /^(\d|0\d|1\d|2[0-3]):[0-5]\d$/.test(value)) {
            let arr = value.split(':');
            let dec = parseInt((arr[1] / 6) * 10, 10);

            const newValue = parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);

            rowData["Cantidad"] = formatNumberExactDecimals(newValue, 3);
        }

        this.defaultSetCellValue(rowData, value);
    }

    onFocusedCellChanged(e) {
        if (e.cellElement[0].id.includes("Cantidad")) {
            const nodeId = e.cellElement[0].id.replace("Cantidad", "Horas y Minutos (hh:mm)");
            const node = document.getElementById(nodeId);
            if (node?.value) {
                const { value } = node;
                let arr = value.split(':');
                let dec = parseInt((arr[1] / 6) * 10, 10);

                let newValue = parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);

                newValue = formatNumberExactDecimals(newValue, 3);

                // Tengo que buscar el rowIndex
                const rowsQuantity = e.component.getVisibleRows().length;
                for (let i = 0; i < rowsQuantity; i++) {
                    const rows = e.component.getRowElement(i);

                    if (rows[0].className.includes("dx-datagrid-edit-form")) {
                        window.setTimeout(function () {
                            e.component.cellValue(i, "Cantidad", newValue);
                        }, 100);
                    }
                }
            }
        }
    }

    onEditorPrepared(options, selectDataSources) {
        if (options.dataField === "Concepto") {
            const htmlElement = document.getElementById(options.id);
            const arrElem = selectDataSources("all-concepts");
            const foundElement = arrElem.find(el => el.key === options.value)
            if (htmlElement && foundElement) {
                htmlElement.addEventListener('mousemove', function () {
                    htmlElement.setAttribute('title', foundElement.comment);
                });
            }
        }
    }

    onToolbarpreparing(e) {
        let toolbarItems = e.toolbarOptions.items;

        toolbarItems.forEach(function (item) {
            item.options = {
                visible: false
            }
        });
    }

    render() {
        const { columns, selectDataSources, gridName, batchMode, nestedGrid } = this.props;
        const { rowIndex, originalData } = this.state;
        let customProperties = {};

        if (batchMode) {
            customProperties.height = gridName === "extraHoursAuthorization" ? "32vw" : 440;
            customProperties.columnMinWidth = 60;
        } else {
            customProperties.columnResizingMode = "nextColumn"
            customProperties.wordWrapEnabled = true;
            customProperties.columnMinWidth = 30;
        }

        return (
            <div id="data-grid-demo" style={{ marginBottom: 20 }}>
                <DataGrid
                    ref="dataGrid"
                    dataSource={originalData}
                    onRowInserted={this.compareData}
                    onToolbarPreparing={batchMode ? this.onToolbarpreparing : null}
                    onRowUpdated={this.compareData}
                    onRowRemoved={this.compareData}
                    showBorders={false}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    width={"91vw"}
                    onEditorPreparing={(options) => this.onEditorPreparing(options, rowIndex)}
                    onFocusedCellChanged={this.onFocusedCellChanged}
                    onEditorPrepared={(options) => this.onEditorPrepared(options, selectDataSources)}
                    rowAlternationEnabled={true}
                    style={{ margin: "0 auto" }}
                    {...customProperties}
                >
                    {!nestedGrid && <Selection mode="multiple" showCheckBoxesMode="always" />}
                    <Paging enabled={false} />
                    {batchMode && <Scrolling mode="virtual" />}
                    <Editing
                        mode={batchMode ? "batch" : "form"}
                        allowUpdating={true}
                        allowDeleting={false}
                        selectTextOnEditStart={Boolean(batchMode)}
                    />
                    {
                        columns.map((item, index) => {
                            const isRequired = item.isObligatory ? <RequiredRule /> : null;
                            const lengthRule = item.minLength !== null && item.maxLength ? <StringLengthRule min={item.minLength} max={item.maxLength} /> : null
                            const patternRule = item.pattern ? <PatternRule pattern={item.pattern} /> : null;
                            const numericRule = item.isNumeric && !item.validationUnrequired ? <NumericRule /> : null;
                            const lookupColumn = item.isSelect ? <Lookup dataSource={selectDataSources(item.field)} valueExpr="key" displayExpr="value" /> : null;

                            let isVisible = true;
                            if (
                                (gridName === "entryPerConcept" || gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety") && (
                                    item.headerName === "Comentarios" ||
                                    item.headerName === "Fecha de contratación" ||
                                    item.headerName === "Fecha de egreso" ||
                                    item.headerName === "Comentarios del pago al tercero")
                            ) {
                                isVisible = false;
                            }

                            let multipleSelectDisplayValue = null;
                            if (item.isMultipleSelect) {
                                switch (item.field) {
                                    case "codeRef":
                                        multipleSelectDisplayValue = this.displayMultipleSelectDataCodeRef;
                                        break;
                                    case "calculationIndicator":
                                        multipleSelectDisplayValue = this.displayMultipleSelectDataCalculationIndicator;
                                        break;
                                    case "entryMode":
                                        multipleSelectDisplayValue = this.displayMultipleSelectDataEntryMode;
                                        break;
                                    case "concepts":
                                        multipleSelectDisplayValue = this.displayMultipleSelectDataConcepts;
                                        break;
                                    default: break;
                                }
                            }

                            let props = {
                                key: index,
                                caption: item.headerName,
                                dataField: item.field,
                                dataType: item.dataType,
                                format: this.getFormat(gridName, item),
                                editorOptions: this.getEditorOptions(gridName, item),
                                editCellComponent: item.isMultipleSelect ? EmployeeTagBoxComponent : null,
                                calculateDisplayValue: multipleSelectDisplayValue,
                                fixed: item.isFixed ? true : false,
                                fixedPosition: "left",
                                allowEditing: item.disabled === true ? false : true,
                                visible: isVisible,
                            }

                            if (batchMode) {
                                if (nestedGrid) {
                                    if (item.headerName === "Cuota") {
                                        props.width = 50;
                                    } else if (item.headerName === "Estado" || item.headerName === "Valor cuota") {
                                        props.width = 150;
                                    } else {
                                        props.width = 300;
                                    }
                                } else {
                                    if (gridName === "extraHoursAuthorization" && (
                                        item.headerName === "Fecha" ||
                                        item.headerName === "Hrs. Extras Autorizadas" ||
                                        item.headerName === "Hrs. Extras Realizadas" ||
                                        item.headerName === "Hrs. Extras Sin Autorizar")
                                    ) {
                                        props.width = 180;
                                    } else if (item.isNumeric) {
                                        props.width = 200;
                                    } else if (item.headerName === "Código de empleado") {
                                        props.width = 160;
                                    } else {
                                        props.width = 300;
                                    }
                                }
                            } else {
                                if (item.headerName === "Código de hora extra") {
                                    props.width = 200;
                                }
                            }

                            if (item.headerName === "Tipo de concepto") {
                                props.setCellValue = this.setStateValue
                            }

                            if (item.headerName === "Forma de pago") {
                                props.setCellValue = this.setBankValue
                            }

                            if (item.headerName === "Horas y Minutos (hh:mm)" || item.headerName.includes("(hhmm)") || item.headerName.includes("Hrs. ")) {
                                props.setCellValue = this.setHoursMinutes
                            }

                            if (gridName === "entryPerConceptSet" &&
                                props.dataField !== "Código de empleado" &&
                                props.dataField !== "En fecha" &&
                                props.dataField !== "Apellido y nombres") {
                                props.customizeText = function (cellInfo) {
                                    if (cellInfo.value === null ||
                                        cellInfo.value === "0" ||
                                        cellInfo.value === 0.00 ||
                                        cellInfo.value === "0:00" ||
                                        cellInfo.value === "00:00" ||
                                        cellInfo.value === 0) {
                                        return ''
                                    } else {
                                        return cellInfo.value
                                    }
                                }
                            }

                            return (
                                <Column
                                    {...props}
                                    sortingMethod={this.sortStringsConsideringCulture}
                                >
                                    {
                                        (item.headerName === "Comentarios" || item.headerName === "Comentarios del pago al tercero") &&
                                        <FormItem colSpan={2} editorType="dxTextArea" editorOptions={notesEditorOptions} />
                                    }
                                    {isRequired}
                                    {patternRule}
                                    {lengthRule}
                                    {numericRule}
                                    {
                                        item.headerName === "Concepto" ?
                                            <Lookup dataSource={(options) => this.getFilteredConcepts(options)} valueExpr="key" displayExpr="value" /> :
                                            lookupColumn
                                    }
                                    {
                                        item.headerName === "Tipo de valor informado" && <CustomRule
                                            message="Tipo de valor informado es Obligatorio"
                                            validationCallback={this.customCallbackInformedValueType}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        gridName === "holidayTable" && item.headerName === "Antiguedad hasta (Años)" &&
                                        <CustomRule
                                            message='La "antiguedad hasta" debe ser mayor o igual a la "antiguedad desde"'
                                            validationCallback={this.customCallbackSeniorityTo}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "holidayTable") && item.headerName !== "Tipo de tabla" &&
                                        <CustomRule
                                            message='El valor debe ser mayor o igual a 0'
                                            validationCallback={this.customCallbackHolidayTableGreaterEqualZero}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "extraHoursAuthorization" || gridName === "entryPerConcept" || gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety" || gridName === "entryPerConceptSet") && (item.headerName === "Horas y Minutos (hh:mm)" || item.headerName.includes("(hh:mm)") || item.headerName.includes("(hhmm)") || item.headerName.includes("Hrs. ")) &&
                                        <CustomRule
                                            message='Verifique el formato. El mismo debe ser, por ejemplo, 09:00.'
                                            validationCallback={this.customCallbackEntryPerConceptHoursMinutesValidSyntax}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "entryPerConcept" || gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety") &&
                                        (item.headerName === "Horas y Minutos (hh:mm)" || item.headerName.includes("(hhmm)")) &&
                                        <CustomRule
                                            message='La hora ingresada supera la jornada del colaborador'
                                            validationCallback={this.customCallbackEntryPerConceptHoursMinutes}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "extraHoursAuthorization") &&
                                        (item.headerName === "Hrs. Extras Autorizadas") &&
                                        <CustomRule
                                            message='La hora ingresada supera el máximo (23:59)'
                                            validationCallback={this.customCallbackEntryPerConceptHoursMinutesNoData}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "distributionCost" || gridName === "entryByInstallments" || gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety" || gridName === "entryPerConceptSet") && (
                                            item.headerName === "Porcentaje"
                                        ) &&
                                        <CustomRule
                                            message={item.isObligatory ?
                                                'Solo se permiten 3 decimales. Se debe usar la coma como separador. No se puede ingresar 0.' :
                                                'Solo se permiten 3 decimales. Se debe usar la coma como separador.'}
                                            validationCallback={item.isObligatory ?
                                                this.customCallbackMax3Decimals : this.customCallbackMax3DecimalsAllowZero}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "distributionCost" || gridName === "entryByInstallments" || gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety" || gridName === "entryPerConceptSet") && (
                                            item.headerName === "Cantidad" || item.isQuantityEntryPerConceptSet
                                        ) &&
                                        <CustomRule
                                            message={item.isObligatory ?
                                                'Solo se permiten 5 decimales. Se debe usar la coma como separador. No se puede ingresar 0.' :
                                                'Solo se permiten 5 decimales. Se debe usar la coma como separador.'}
                                            validationCallback={item.isObligatory ?
                                                this.customCallbackMax5Decimals : this.customCallbackMax5DecimalsAllowZero}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety" || gridName === "entryPerConceptSet") && item.headerName !== "Cantidad" && !item.isQuantityEntryPerConceptSet && (
                                            item.headerName === "Valor informado bruto" &&
                                            (item.isValueEntryPerConceptSet ||
                                                item.isNumeric)
                                        ) &&
                                        <CustomRule
                                            message={item.isObligatory ?
                                                'Solo se permiten 2 decimales. Se debe usar la coma como separador. No se puede ingresar 0.' :
                                                'Solo se permiten 2 decimales. Se debe usar la coma como separador.'}
                                            validationCallback={item.isObligatory ?
                                                this.customCallbackMax2DecimalsAllowNegative : this.customCallbackMax2DecimalsAllowZeroAllowNegative}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety" || gridName === "entryPerConceptSet") && item.headerName !== "Cantidad" && !item.isQuantityEntryPerConceptSet && (
                                            item.headerName === "Valor informado líquido" ||
                                            item.headerName === "Porcentaje" ||
                                            item.headerName === "Base de cálculo" ||
                                            item.headerName === "Valor cuota" ||
                                            (item.isValueEntryPerConceptSet && item.headerName !== "Valor informado bruto") ||
                                            (item.isNumeric && item.headerName !== "Valor informado bruto")
                                        ) &&
                                        <CustomRule
                                            message={item.isObligatory ?
                                                'Solo se permiten 2 decimales. Se debe usar la coma como separador. No se puede ingresar 0.' :
                                                'Solo se permiten 2 decimales. Se debe usar la coma como separador.'}
                                            validationCallback={item.isObligatory ?
                                                this.customCallbackMax2Decimals : this.customCallbackMax2DecimalsAllowZero}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "entryPerConcept") && (
                                            item.headerName === "Cantidad" ||
                                            item.headerName === "Valor informado bruto" ||
                                            item.headerName === "Valor informado líquido" ||
                                            item.headerName === "Porcentaje" ||
                                            item.headerName === "Base de cálculo")
                                        &&
                                        <CustomRule
                                            message={item.isObligatory ?
                                                `Solo se permiten ${decimals[item.headerName]} decimales. Se debe usar la coma como separador. No se puede ingresar 0.` :
                                                `Solo se permiten ${decimals[item.headerName]} decimales. Se debe usar la coma como separador.`}
                                            validationCallback={item.isObligatory ?
                                                () => this.customCallbackMax2Decimals(decimals[item.headerName]) : () => this.customCallbackMax2DecimalsAllowZero(decimals[item.headerName])}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "extraHoursTable" && item.headerName === "Código de hora extra"
                                        ) &&
                                        <CustomRule
                                            message={'El código debe comenzar con una letra. Solo se pueden ingresar letras mayúsculas.'}
                                            validationCallback={this.customCallbackExtraHoursTableCode}
                                            reevaluate={true}
                                        />
                                    }

                                    {
                                        (gridName === "entryPerConcept" || gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety") && (
                                            item.headerName === "Período de reliquidación" ||
                                            item.headerName === "Período de imputación" ||
                                            item.headerName === "Período desde" ||
                                            item.headerName === "Período hasta" ||
                                            item.headerName.includes("Período") ||
                                            item.headerName.includes("Periodo")
                                        ) &&
                                        <CustomRule
                                            message='Ingrese un período válido (YYYY/MM)'
                                            validationCallback={this.customCallbackPeriodInverted}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "entryPerConcept" || gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety") && item.headerName.includes("Período desde")
                                        &&
                                        <CustomRule
                                            message='El "Período desde" debe ser menor o igual al "Período hasta"'
                                            validationCallback={this.customCallbackPeriodFromTo}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "entryPerConcept" || gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety") && item.headerName.includes("Período hasta")
                                        &&
                                        <CustomRule
                                            message='El "Período hasta" debe ser mayor o igual al "Período desde"'
                                            validationCallback={this.customCallbackPeriodFromTo}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "festivitiesTable") && item.headerName === "Año"
                                        &&
                                        <CustomRule
                                            message='El Año ingresado no es válido'
                                            validationCallback={this.customCallbackYear4Digits}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "festivitiesTable") && item.headerName === "Fecha de inicio"
                                        &&
                                        <CustomRule
                                            message='La "Fecha de inicio" debe ser menor o igual a la "Fecha final"'
                                            validationCallback={this.customCallbackDateStartEnd}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "festivitiesTable") && (item.headerName === "Fecha de inicio" || item.headerName === "Fecha final")
                                        &&
                                        <CustomRule
                                            message='Verifique el año de las fechas ingresadas'
                                            validationCallback={this.customCallbackDateStartEndYear}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "festivitiesTable") && item.headerName === "Fecha final"
                                        &&
                                        <CustomRule
                                            message='La "Fecha final" debe ser mayor o igual a la "Fecha de inicio"'
                                            validationCallback={this.customCallbackDateStartEnd}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "distributionCost" || gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety" || gridName === "entryPerConceptSet")
                                        && (item.headerName === "Porcentaje" || item.isPercentageEntryPerConceptSet) &&
                                        <CustomRule
                                            message='Ingrese un porcentaje válido'
                                            validationCallback={item.isObligatory ? this.customCallbackPercentage : this.customCallbackPercentageAllowZero}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        gridName === "entryPerSociety" && item.headerName === "Concepto" &&
                                        <CustomRule
                                            message='Ya existe un registro con el Tipo de concepto y Concepto seleccionados'
                                            validationCallback={this.customCallbackEntryPerSocietyDuplicatedConcept}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        gridName === "extraHoursAuthorization" && item.headerName === "Código de empleado" &&
                                        <CustomRule
                                            message='Ya existe un registro con el Código de empleado y Fecha seleccionados'
                                            validationCallback={this.customCallbackExtraHoursAuthorizationDuplicatedEmployeeDate}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        gridName === "extraHoursAuthorization" && item.headerName === "Fecha" &&
                                        <CustomRule
                                            message='Ya existe un registro con el Código de empleado y Fecha seleccionados'
                                            validationCallback={this.customCallbackExtraHoursAuthorizationDuplicatedEmployeeDate}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "entryPerConcept" || gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety") &&
                                        item.headerName === "Banco" &&
                                        <CustomRule
                                            message='Se requiere Banco para la forma de pago "Depósito"'
                                            validationCallback={this.customCallbackValidatePaymentTypeBank}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        (gridName === "entryPerConcept" || gridName === "dataCapture" || gridName === "massEntry" || gridName === "entryPerSociety") &&
                                        item.headerName === "Cuenta bancaria" &&
                                        <CustomRule
                                            message='Se requiere Cuenta Bancaria para la forma de pago "Depósito"'
                                            validationCallback={this.customCallbackValidatePaymentTypeCC}
                                            reevaluate={true}
                                        />
                                    }
                                    {
                                        gridName === "distributionCost" && (item.headerName === "Centro de costo" || item.headerName === "Proyecto" || item.headerName === "Dimensión 2" || item.headerName === "Dimensión 3" || item.headerName === "Dimensión 4" || item.headerName === "Dimensión 5") &&
                                        <CustomRule
                                            message='Centro de costo, Proyecto o Dimensión deben contener un valor'
                                            validationCallback={this.customCallbackProjectBenefitCenter}
                                            reevaluate={true}
                                        />
                                    }
                                </Column>
                            )
                        })
                    }
                    {!batchMode && <Column type="buttons" caption="Acciones" fixed={true} fixedPosition="right">
                        <Button name="edit" />
                    </Column>}
                </DataGrid>
            </div >
        )
    }

    displayMultipleSelectDataCodeRef(rowData) {
        return rowData.codeRef ? rowData.codeRef.map(item => item.value ? item.value : item).filter(item => item).join(", ") : "";
    }

    displayMultipleSelectDataCalculationIndicator(rowData) {
        return rowData.calculationIndicator ? rowData.calculationIndicator.map(item => item.value ? item.value : item).filter(item => item).join(", ") : "";
    }

    displayMultipleSelectDataEntryMode(rowData) {
        return rowData.entryMode ? rowData.entryMode.map(item => item.value ? item.value : item).filter(item => item).join(", ") : "";
    }

    displayMultipleSelectDataConcepts(rowData) {
        return rowData.concepts ? rowData.concepts.map(item => item?.value || item).filter(item => item).join(", ") : "";
    }
}

export default EditablGridV2;