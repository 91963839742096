import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getVacationsAPI,
  getEmployeeVacationsAPI,
  deleteEmployeeVacationsAPI,
  createEmployeeVacationsAPI,
  updateEmployeeVacationsAPI,
  downloadVacationsReceiptAPI,
  downloadVacationsSummaryAPI,
  sendVacationsMessageAPI,
} from "@icarius-connection/api";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { getLocalizedErrorString } from "@icarius-localization/strings";

export const getVacationDataAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getVacationsAPI();
    const result = response.data && response.data.result;
    const dateFormat = response.data && response.data.date_format;

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        result,
        dateFormat,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getEmployeeVacationsAction = (level, code) => async (dispatch) => {
  dispatch({ type: actionTypes.EMPLOYEE_VACATIONS });
  try {
    let response = await getEmployeeVacationsAPI(level === "C" ? '' : `?code=${code}`);
    let creditData = response.data && response.data.credit_grid;
    let debitData = response.data && response.data.debit_grid;
    let miscData = response.data && response.data.misc;
    let vacationsSchema = response && response.data && response.data.vacations_schema;
    let locale = response && response.data && response.data.currency_localization;
    let dateFormat = response.data.date_format;
    let contractDate = response.data && response.data.contractDate;
    let vacationsUpdateDate = response.data && response.data.vacationsUpdateDate;

    dispatch({
      type: actionTypes.EMPLOYEE_VACATIONS_FULFILLED,
      payload: {
        creditData,
        debitData,
        miscData,
        vacationsSchema,
        locale,
        dateFormat,
        contractDate,
        vacationsUpdateDate,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.EMPLOYEE_VACATIONS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const deleteEmployeeVacationsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.EMPLOYEE_VACATIONS });
  try {
    let response = await deleteEmployeeVacationsAPI(dataToSend);
    let creditData = response.data && response.data.credit_grid;
    let debitData = response.data && response.data.debit_grid;
    let miscData = response.data && response.data.misc;
    let vacationsSchema = response && response.data && response.data.vacations_schema;
    let locale = response && response.data && response.data.currency_localization;
    let dateFormat = response.data.date_format;

    dispatch({
      type: actionTypes.EMPLOYEE_VACATIONS_FULFILLED,
      payload: {
        creditData,
        debitData,
        miscData,
        vacationsSchema,
        locale,
        dateFormat,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Registro eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.EMPLOYEE_VACATIONS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const createEmployeeVacationsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.EMPLOYEE_VACATIONS });
  try {
    let response = await createEmployeeVacationsAPI(dataToSend);
    let creditData = response.data && response.data.credit_grid;
    let debitData = response.data && response.data.debit_grid;
    let miscData = response.data && response.data.misc;
    let vacationsSchema = response && response.data && response.data.vacations_schema;
    let locale = response && response.data && response.data.currency_localization;
    let dateFormat = response.data.date_format;

    dispatch({
      type: actionTypes.EMPLOYEE_VACATIONS_FULFILLED,
      payload: {
        creditData,
        debitData,
        miscData,
        vacationsSchema,
        locale,
        dateFormat,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Registro creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.EMPLOYEE_VACATIONS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const updateEmployeeVacationsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.EMPLOYEE_VACATIONS });
  try {
    let response = await updateEmployeeVacationsAPI(dataToSend);
    let creditData = response.data && response.data.credit_grid;
    let debitData = response.data && response.data.debit_grid;
    let miscData = response.data && response.data.misc;
    let vacationsSchema = response && response.data && response.data.vacations_schema;
    let locale = response && response.data && response.data.currency_localization;
    let dateFormat = response.data.date_format;

    dispatch({
      type: actionTypes.EMPLOYEE_VACATIONS_FULFILLED,
      payload: {
        creditData,
        debitData,
        miscData,
        vacationsSchema,
        locale,
        dateFormat,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Registro modificado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.EMPLOYEE_VACATIONS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const downloadVacationsReceiptAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    let response = await downloadVacationsReceiptAPI(dataToSend);
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const downloadVacationsSummaryAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    let response = await downloadVacationsSummaryAPI(dataToSend);
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const sendVacationsMessageAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.SEND_MAIL });
  try {
    let response = await sendVacationsMessageAPI(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.SEND_MAIL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.SEND_MAIL_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: 'Mail enviado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SEND_MAIL_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};