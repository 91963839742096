import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getIsExportingGrid } from "src/app/selectors";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { getProgressIsOpen } from "@icarius-common/circularProgress/selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  getInitialReceiptData,
  downloadReceiptAction,
  getColaboratorsForReceiptsAction,
  signAndDownloadAction,
  previewDocumentsAction,
  sendReceiptsAction
} from "../actions";
import {
  getCollaborators,
  getIsLoading,
  getDateFormat,
  getLocale,
  getCalculationProcesses,
  getReceipts,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";

const useReceipts = (isDigitalSignVersion) => {

  const [selectedReceiptCode, setSelectedReceiptCode] = useState("");
  const [selectedCalculationProcessCode, setSelectedCalculationProcessCode] = useState("");
  const [calculationProcessesForDigitalSign, setCalculationProcessesForDigitalSign] = useState([]); // depende del recibo con firma digital seleccionado

  const [userDialogEmployeeCode, setUserDialogEmployeeCode] = useState(null);

  const [documentsToSign, setDocumentsToSign] = useState([]);
  const [pinDialogIsOpen, setPinDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const exportingGridStatus = useSelector(getIsExportingGrid);
  const isProgressLoading = useSelector(getProgressIsOpen);
  const collaborators = useSelector(getCollaborators);
  const receipts = useSelector(getReceipts);
  const calculationProcessesForRegular = useSelector(getCalculationProcesses); // de recibos SIN firma
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);

  //si no es version con firma digital, usar los que vienen del selector
  const calculationProcesses = isDigitalSignVersion ? calculationProcessesForDigitalSign : calculationProcessesForRegular;

  useEffect(() => {
    /**
      si es version con firma digital Y puede firmar, O no es version con firma digital:
      pegarle al getPeopleRowsAction que le pega a:
      - getAvailableReceiptList para traer los recibos y procesos de calculo
      - getAvailableColaboratorsForReceipt sin params para traer los user genericos
    **/

    const validateHasFiles = (isDigitalSignVersion, res) => {
      const conditionIfSignVersion = res?.data?.receipts?.length > 0;
      const conditionIfRegularVersion = res?.data?.receipts?.length > 0 && res?.data?.calculation_processes?.length > 0;

      if ((isDigitalSignVersion && !conditionIfSignVersion) || (!isDigitalSignVersion && !conditionIfRegularVersion)) {
        dispatch(openSnackbarAction({ msg: getLocalizedString("noDocumentsToGenerate"), duration: null, severity: "warning" }));
      }
    }

    dispatch(getInitialReceiptData(isDigitalSignVersion))
      .then((res) => validateHasFiles(isDigitalSignVersion, res));

    return () => dispatch({ type: RESET_STATE });
  }, [dispatch, isDigitalSignVersion])

  const handleSelectReceiptClick = (e, gridRef) => {
    if (e.target.value === selectedReceiptCode) return;

    setSelectedReceiptCode(e.target.value);
    setSelectedCalculationProcessCode("");
    fetchCollaborators(e.target.value, "");

    setFilterForGrid(gridRef);
    gridRef.api.clearRangeSelection();
    gridRef.api.deselectAll();
  };

  const handleSelectCalculationProcess = (e, gridRef) => {
    if (e.target.value === selectedCalculationProcessCode) return;

    setSelectedCalculationProcessCode(e.target.value);
    fetchCollaborators(selectedReceiptCode, e.target.value);

    setFilterForGrid(gridRef);
    gridRef.api.clearRangeSelection();
    gridRef.api.deselectAll();
  };

  const setFilterForGrid = (gridRef) => {
    let filterInstance = gridRef.api.getFilterInstance("PERSONAL DE PLANTA");
    filterInstance.setModel({ type: "set", values: ["Si"] });

    let filterInstanceVigencia = gridRef.api.getFilterInstance("ESTADO");
    filterInstanceVigencia.setModel({ type: "set", values: ["Vigente"] });

    gridRef.api.onFilterChanged();
  };

  const fetchCollaborators = (receiptCode, calculationProcessCode) => {
    if (!isDigitalSignVersion) {
      dispatch(getColaboratorsForReceiptsAction(receiptCode, calculationProcessCode, true));
      return;
    }

    // agarrar el selectedReceiptCode, traer ese recibo, y setear sus calculationProcesses
    const receipt = receipts.find((receipt) => receipt.code === receiptCode);
    if (receipt) {
      setCalculationProcessesForDigitalSign(receipt.calculation_processes);
      dispatch(getColaboratorsForReceiptsAction(receiptCode, calculationProcessCode, false, receipt.role));
    }
  }

  const handleSign = (password, nextSigner) => {
    let dataToSend;

    if (nextSigner) {
      // agrego el firmante al array
      dataToSend = documentsToSign.map((item) => ({
        ...item,
        approvants: [...item.approvants, nextSigner],
      }));
    } else {
      dataToSend = documentsToSign;
    }

    dispatch(signAndDownloadAction(dataToSend, password))
      .then(() => {
        setDocumentsToSign([]);
        setUserDialogEmployeeCode(null);
      });
  }

  const handlePreviewReceipts = (gridRef) => {
    if (selectedReceiptCode === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectReceiptType"), severity: "warning" }));
      return;
    }
    if (selectedCalculationProcessCode === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectCalcProcess"), severity: "warning" }));
      return;
    }

    const selectedRows = gridRef.api.getSelectedRows()
      .map((item) => {
        return {
          "code": item.dataDoc.code,
          "codRef": item.dataDoc.codRef,
          "dataCode": item.dataDoc.dataCode,
          "role": item.dataDoc.destRole,
        }
      });

    if (!selectedRows.length) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    dispatch(previewDocumentsAction(selectedRows));
  };

  const handleSendReceipts = (gridRef) => {
    let selectedEmployeesCode = gridRef.api.getSelectedRows().map((item) => item["CODIGO DE EMPLEADO"]);

    if (selectedEmployeesCode.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    if (selectedReceiptCode === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectReceiptType"), severity: "warning" }));
      return;
    }

    if (selectedCalculationProcessCode === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectCalcProcess"), severity: "warning" }));
      return;
    }

    dispatch(sendReceiptsAction(selectedReceiptCode, selectedCalculationProcessCode, selectedEmployeesCode));
  };

  const handleDownloadDocuments = (gridRef) => {
    let selectedEmployeesCode = gridRef.api.getSelectedRows().map((item) => item["CODIGO DE EMPLEADO"]);

    if (selectedEmployeesCode.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    handleGenerate(selectedEmployeesCode);
  };

  const handleGenerate = (selectedEmployeesCode) => {
    if (selectedReceiptCode === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectReceiptType"), severity: "warning" }));
      return;
    }

    if (selectedCalculationProcessCode === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectCalcProcess"), severity: "warning" }));
      return;
    }

    if (isDigitalSignVersion) {
      handleSetDataToSign(selectedEmployeesCode);
    } else {
      dispatch(downloadReceiptAction(selectedReceiptCode, selectedCalculationProcessCode, selectedEmployeesCode));
    }
  };

  const handleSetDataToSign = (selectedEmployeesCode) => {
    let selectedRows = [];
    let amountInvalidMail = 0;

    selectedEmployeesCode.forEach((employeeCode) => {
      let collaborator = collaborators.find((item) => item["CODIGO DE EMPLEADO"] === employeeCode);

      if (collaborator) {
        if (collaborator["EMAIL"] === "-") {
          amountInvalidMail++;
        } else {
          selectedRows.push(collaborator.dataDoc);
        }
      }
    });

    if (amountInvalidMail > 0) {
      dispatch(openSnackbarAction({
        msg: getLocalizedErrorString("certificateNoMail").replace("{amount}", selectedRows.length).replace("{total}", selectedEmployeesCode.length),
        severity: "info",
        duration: 10000,
      }));
    }

    setDocumentsToSign([...selectedRows]);
    setPinDialogIsOpen(true);
  }

  const handleCloseConfirmDialog = () => {
    setPinDialogIsOpen(false);
  }

  const handleRowClick = (employeeRow) => {
    setUserDialogEmployeeCode(employeeRow.data["CODIGO DE EMPLEADO"]);
  };

  const handleCloseUserDialog = () => {
    setUserDialogEmployeeCode(null);
  };

  const state = {
    selectedReceiptCode,
    selectedCalculationProcessCode,
    userDialogEmployeeCode,
    pinDialogIsOpen,
  };

  const handlers = {
    handleSign,
    handlePreviewReceipts,
    handleDownloadDocuments,
    handleGenerate,
    handleRowClick,
    handleCloseUserDialog,
    handleCloseConfirmDialog,
    handleSelectReceiptClick,
    handleSelectCalculationProcess,
    handleSendReceipts,
  };

  return {
    isLoading: isLoading || exportingGridStatus,
    isProgressLoading,
    collaborators,
    receipts,
    calculationProcesses,
    dateFormat,
    locale,
    state,
    handlers,
  };
}

export default useReceipts;