import React from "react";
import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getCalculationProcessesAPI,
  getProcessesEmployeesAPI,
  createProcessAPI,
  modifyProcessAPI,
  deleteProcessAPI,
  execute_R8,
  getCalcProcessTraceabilityAPI,
  generateThirdPartyFileAPI,
  getAccountingEntriesAPI,
  downloadDesktopR8Api,
  approveProcessAPI,
} from "@icarius-connection/api";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  console.log(e.response)
  switch (e.response?.data?.status) {
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("processNoExist");
      break;
    case "DEFAULT_IN_USE":
      errorString = 'Solo es posible definir un proceso por defecto a la vez';
      break;
    case "DUPLICATED_TYPE":
      errorString = 'Ya existe un proceso de cálculo abierto con el mismo tipo de nómina';
      break;
    case "INVALID_DATA":
      errorString = (
        <>
          Ha ocurrido un error al intentar eliminar el proceso.
          <br />
          {e.response?.data?.error || "-"}
        </>
      );
      break;
    case "EXISTS_DOCUMENT_SIGNED":
      errorString = "El proceso de cálculo no puede eliminarse porque ya se han generados documentos con firma digital para dicho proceso";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: null }));
}

export const getCurrentProcessesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ROWS });
  try {
    let response = await getCalculationProcessesAPI("?status=current");

    let rows = response.data && response.data.result;
    let exitTypes = response.data && response.data.exitTypes;
    let thirdPartyFiles = response.data && response.data.thirdPartyFiles;
    let groups = response.data && response.data.groups;
    let dateFormat = response.data && response.data.date_format;
    let validationData = response.data && response.data.validation_data;
    let calendarList = response.data && response.data.calendarList;
    let templates = response.data && response.data.templates;
    let approvationParameters = response.data && response.data.approvation_parameters;
    let automaticProcessesEnabled = response.data && response.data.automaticProcessesEnabled;

    dispatch({
      type: actionTypes.GET_ROWS_FULFILLED,
      payload: {
        rows,
        exitTypes,
        dateFormat,
        validationData,
        templates,
        automaticProcessesEnabled,
        thirdPartyFiles,
        groups,
        calendarList,
        approvationParameters,
      }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getProcessesEmployeesAction = (typeCode, processCode, fdespro, fhaspro) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_EMPLOYEES });
  try {
    const isEdit = Boolean(processCode);
    let query;

    if (isEdit) {
      if (fdespro && fhaspro) {
        query = `?calculation_process_type=${typeCode}&calculation_process=${processCode}&fdespro=${fdespro}&fhaspro=${fhaspro}`;
      }
      else {
        query = `?calculation_process_type=${typeCode}&calculation_process=${processCode}`
      }
    } else {
      query = `?calculation_process_type=${typeCode}&fdespro=${fdespro}&fhaspro=${fhaspro}`;
    }

    let response = await getProcessesEmployeesAPI(query);
    let employees = response.data && response.data.result;

    if (response.data.partial) {
      dispatch(openSnackbarAction({
        msg: 'VISTA PARCIAL - Se visualiza a los colaboradores que pertenecen a sus grupos de acceso',
        severity: "info",
        duration: null
      }));
    }

    dispatch({
      type: actionTypes.GET_EMPLOYEES_FULFILLED,
      payload: { employees }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_EMPLOYEES_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const createProcessAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_PROCESS });
  try {
    let response = await createProcessAPI(data);

    dispatch({ type: actionTypes.CREATE_PROCESS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("processCreatedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_PROCESS_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e;
  }
};

export const modifyProcessAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY_PROCESS });
  try {
    let response = await modifyProcessAPI(data);

    dispatch({ type: actionTypes.MODIFY_PROCESS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("processEditedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_PROCESS_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e;
  }
};

export const deleteProcessAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_PROCESS });
  try {
    let response = await deleteProcessAPI(data);

    dispatch({ type: actionTypes.DELETE_PROCESS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("processDeletedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_PROCESS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const executeProcessAction = (code, motive, feceg, isFull) => async (dispatch) => {
  dispatch({ type: actionTypes.EXECUTE });
  try {

    const query = (motive && feceg) ? `?trace=FALSE&insert=1&process=${code}&motive=${motive}&feceg=${feceg}` : `?trace=FALSE&insert=1&process=${code}&full=${isFull.toString().toUpperCase()}`

    let response = await execute_R8(query);
    const { status, message } = response?.data;
    if (status === "OK" && message) {
      dispatch(openSnackbarAction({ msg: message, severity: "info", duration: null }));
    }

    dispatch({ type: actionTypes.EXECUTE_FULFILLED });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.EXECUTE_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getCalcProcessTraceabilityAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.CALC_PROCESS_TRACEABILITY });
  try {

    const query = `?process=${code}`;

    let response = await getCalcProcessTraceabilityAPI(query);
    const { status, message } = response?.data;
    if (status === "OK" && message) {
      dispatch(openSnackbarAction({ msg: message, severity: "info", duration: null }));
    }

    dispatch({ type: actionTypes.CALC_PROCESS_TRACEABILITY_FULFILLED });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CALC_PROCESS_TRACEABILITY_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const generateThirdPartyFileAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.GENERATE });
  try {
    let response = await generateThirdPartyFileAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GENERATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GENERATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Ejecución en proceso para la acción especial seleccionada', severity: "success" }));
    return response;
  } catch (e) {
    if (e.response.data.error === 'INVALID_EXTENSION') {
      dispatch(openSnackbarAction({ msg: 'Archivo de extensión invalida', severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.GENERATE_REJECTED, payload: e });
  }
};

export const getAccountingEntriesAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ACCOUNTING_ENTRIES_ROWS });
  try {
    let response = await getAccountingEntriesAPI(`?processCode=${code}`);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_ACCOUNTING_ENTRIES_ROWS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_ACCOUNTING_ENTRIES_ROWS_FULFILLED,
      payload: { data },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ACCOUNTING_ENTRIES_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const downloadDesktopR8Action = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    let response = await downloadDesktopR8Api(dataToSend);
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const approveProcessAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.APPROVE_PROCESS });
  try {
    let response = await approveProcessAPI(data);

    dispatch({ type: actionTypes.APPROVE_PROCESS_FULFILLED });
    dispatch(openSnackbarAction({ msg: 'La aprobación del proceso de cálculo ha finalizado con éxito', severity: "success", duration: null }));
    return response;
  } catch (e) {
    if (e?.response?.data?.error === "NO_CALCULATION_RESULTS") {
      dispatch(openSnackbarAction({ msg: 'No se puede aprobar el proceso porque no existen colaboradores procesados', severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.APPROVE_PROCESS_REJECTED, payload: e });
    return e;
  }
};