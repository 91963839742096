import React, { useEffect, useMemo } from "react";
import { downloadFromURL } from "@icarius-utils/download";
import { BASE_URL, ASSISTANCE_PWA_URL } from "@icarius-connection/endpoints";
import { useSelector, useDispatch } from "react-redux";
import { getCountry, getUserData } from "src/app/selectors";
import {
  IcariusSmartIcon,
  ExcelBigIcon,
  AssistanceCollaboratorIcon,
  AssistanceEmployerIcon,
  EncryptorIcon,
} from "@icarius-icons/";
import { roles } from "@icarius-utils/constants";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getUtilsAction } from "../actions";
import { getVersion, getIsLoading } from "../selectors";
import QR from "@icarius-common/qr";

const useUtils = () => {

  const dispatch = useDispatch();
  const country = useSelector(getCountry);
  const { level } = useSelector(getUserData);
  const version = useSelector(getVersion);
  const isLoading = useSelector(getIsLoading);

  useEffect(() => {
    dispatch(getUtilsAction());
  }, [dispatch])

  const data = useMemo(() => {
    const openDownloadStartedMessage = () => dispatch(openSnackbarAction({ msg: "La descarga ha comenzado...", severity: "info" }));

    const aiData = [
      {
        name: `ICARIUS SMART (${version?.SMART})`,
        icon: <IcariusSmartIcon />,
        ignorePathValidation: true,
        roles: [roles.manager, roles.employer],
        onClick: () => {
          downloadFromURL(
            BASE_URL + `/resources/utils/Icarius_SMART_${version?.SMART}.rar`,
            `Icarius_SMART_${version?.SMART}.rar`
          );
          openDownloadStartedMessage();
        }
      },
    ].filter((item) => {
      if (!item.roles.includes(level)) return false;
      return true;
    });

    const legalData = [
      {
        name: "Encuesta INE (Plantilla en Excel)",
        icon: <ExcelBigIcon />,
        ignorePathValidation: true,
        roles: [roles.employer],
        countries: ["CL"],
        onClick: () => {
          downloadFromURL(
            BASE_URL + '/resources/utils/ICARIUS - Encuesta INE - v2024.xlsx',
            'ICARIUS - Encuesta INE - v2024.xlsx'
          );
          openDownloadStartedMessage();
        }
      },
    ].filter((item) => {
      if (item.countries?.length && !item.countries.includes(country)) return false;
      if (!item.roles.includes(level)) return false;
      return true;
    });

    const othersData = [
      {
        name: `Encriptador (${version?.ENCRYPTOR})`,
        icon: <EncryptorIcon />,
        ignorePathValidation: true,
        roles: [roles.employer],
        onClick: () => {
          downloadFromURL(
            BASE_URL + `/resources/utils/Icarius_Encriptador_${version?.ENCRYPTOR}.rar`,
            `Icarius_Encriptador_${version?.ENCRYPTOR}.rar`
          );
          openDownloadStartedMessage();
        }
      },
    ].filter((item) => {
      if (!item.roles.includes(level)) return false;
      return true;
    });

    const assistanceData = [
      {
        name: `Registro con huellero - Colaborador (${version?.ASSIS_C})`,
        icon: <AssistanceCollaboratorIcon />,
        ignorePathValidation: true,
        roles: [roles.employer],
        onClick: () => {
          downloadFromURL(
            BASE_URL + `/resources/utils/ICARIUS_ASISTENCIA_COLABORADOR_${version?.ASSIS_C}.rar`,
            `Registro con huellero - Colaborador (${version?.ASSIS_C}).rar`
          );
          openDownloadStartedMessage();
        }
      },
      {
        name: `Registro con huellero - Empleador (${version?.ASSIS_E})`,
        icon: <AssistanceEmployerIcon />,
        ignorePathValidation: true,
        roles: [roles.employer],
        onClick: () => {
          downloadFromURL(
            BASE_URL + `/resources/utils/ICARIUS_ASISTENCIA_EMPLEADOR_${version?.ASSIS_C}.rar`,
            `Registro con huellero - Empleador (${version?.ASSIS_E}).rar`
          );
          openDownloadStartedMessage();
        }
      },
      {
        key: "PWA",
        name: "App para registro de asistencia",
        icon: <QR value={ASSISTANCE_PWA_URL} size={90} hideLabel />,
        ignorePathValidation: true,
        roles: [roles.collaborator, roles.manager, roles.employer],
        onClick: () => {
          window.open(ASSISTANCE_PWA_URL, '_blank');
        }
      },
    ].filter((item) => {
      if (!item.roles.includes(level)) return false;
      if (item.key === "PWA" && !ASSISTANCE_PWA_URL) return false;
      return true;
    });

    const auxData = [
      {
        group: 'Aplicaciones de analítica y AI',
        data: aiData,
      },
      {
        group: 'Archivos y documentos legales',
        data: legalData,
      },
      {
        group: 'Registro de asistencia',
        data: assistanceData,
      },
      {
        group: 'Utilitarios',
        data: othersData,
      }
    ].filter((item) => {
      if (Boolean(item.data.length)) return true;
      return false;
    })

    return auxData;
  }, [dispatch, level, country, version])

  return {
    data,
    isLoading,
  };
}

export default useUtils;