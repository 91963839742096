import React from "react";
import { Grid, Typography } from "@material-ui/core";

const SectionContainer = ({ title, subtitle, children }) => {

    return (
        <Grid container item xs={12} justify="center" style={{ marginTop: 50 }}>
            <Grid container item sm={12}>
                <Typography
                    className="whiteText"
                    style={{
                        fontSize: 28,
                        width: '100%',
                        textTransform: 'uppercase',
                    }}
                >
                    {title}
                </Typography>
                {
                    subtitle &&
                    <Typography
                        className={"whiteText"}
                        style={{
                            fontWeight: 300,
                            fontSize: '20px',
                            lineHeight: "20px",
                            marginBottom: 10,
                        }}
                    >
                        {subtitle}
                    </Typography>
                }
                <Grid container item xs={12} style={{ paddingTop: 20 }} spacing={2}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SectionContainer;