import React from "react";
import PhoneInput from "./phoneInput";
import AddressInput from "./addressInput";
import TextInput from "./textInput";
import NumberInput from "./numberInput";
import DateInput from "./dateInput";
import OptionsInput from "./optionsInput";
import ReadOnlyInput from "./readOnlyInput";
import MultilineInput from "./multilineInput";
import CheckInput from "./checkInput";

const DynamicInput = (props) => {

    const {
        fieldConfig,
        label,
        value,
        required,
        error,
        readOnlyType,
        addressHref, // para address
        handleChange,
    } = props;

    const getInput = () => {
        
        const labelToUse = fieldConfig?.audioPlayer ? (
            <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                {label} {fieldConfig.audioPlayer}
            </span>
        ) : label;

        if (!fieldConfig || !fieldConfig.editable) {
            return (
                <ReadOnlyInput
                    label={labelToUse}
                    value={value}
                    type={readOnlyType}
                    addressHref={addressHref}
                />
            )
        }

        if (fieldConfig.subtype === "phone") {
            return (
                <PhoneInput
                    fieldConfig={fieldConfig}
                    label={labelToUse}
                    value={value}
                    handleChange={handleChange}
                    error={error}
                />
            )
        }

        if (fieldConfig.subtype === "address") {
            return (
                <AddressInput
                    fieldConfig={fieldConfig}
                    label={labelToUse}
                    value={value}
                    handleChange={handleChange}
                    required={required}
                    addressHref={addressHref}
                    error={error}
                />
            )
        }

        if (fieldConfig.type === "check") {
            return (
                <CheckInput
                    fieldConfig={fieldConfig}
                    label={labelToUse}
                    value={value}
                    handleChange={handleChange}
                />
            )
        }

        if (fieldConfig.type === "text") {
            return (
                <TextInput
                    fieldConfig={fieldConfig}
                    label={labelToUse}
                    value={value}
                    handleChange={handleChange}
                    required={required}
                    error={error}
                />
            )
        }

        if (fieldConfig.type === "multiline") {
            return (
                <MultilineInput
                    fieldConfig={fieldConfig}
                    label={labelToUse}
                    value={value}
                    handleChange={handleChange}
                    required={required}
                    error={error}
                />
            )
        }

        if (fieldConfig.type === "integer" || fieldConfig.type === "float") {
            return (
                <NumberInput
                    fieldConfig={fieldConfig}
                    label={labelToUse}
                    value={value}
                    handleChange={handleChange}
                    required={required}
                    error={error}
                />
            )
        }

        if (fieldConfig.type === "date") {
            return (
                <DateInput
                    fieldConfig={fieldConfig}
                    label={labelToUse}
                    value={value}
                    handleChange={handleChange}
                    required={required}
                    error={error}
                />
            )
        }

        if (fieldConfig.type === "options") {
            return (
                <OptionsInput
                    fieldConfig={fieldConfig}
                    label={labelToUse}
                    value={value}
                    handleChange={handleChange}
                    required={required}
                    error={error}
                />
            )
        }

        return null;
    }

    return getInput();
}

export default DynamicInput;