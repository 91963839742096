import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from "@material-ui/core";
import { requestsIds } from "@icarius-utils/requestsIds";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const CustomButtonYes = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "#16520b",
        },
        backgroundColor: "#268a15",
        marginRight: 10,
        color: "white",
    },
})(Button);

const CustomButtonNo = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "#871313",
        },
        backgroundColor: "#df1b1b",
        color: "white",
    },
})(Button);

const ReplyDialog = (props) => {

    const {
        open,
        title,
        type,
        fireCalculationData,
        legalReason,
        handleCloseDialog,
        handleAccept,
    } = props;

    const isFiringType = type === requestsIds.id_type.FR

    const [comment, setComment] = useState("");
    const [option, setOption] = useState(isFiringType ? (legalReason || "") : "");

    const textIsInvalid = () => {
        return comment.length > 1023;
    };

    const validateAndConfirm = (accept) => {
        if (textIsInvalid(comment)) return;
        if (isFiringType && accept && option === "") return;
        handleConfirm(accept);
    };

    const handleConfirm = (accept) => {
        handleAccept && handleAccept(comment, accept, option);
    };

    const handleClose = (shouldComponentUpdate) => {
        handleCloseDialog && handleCloseDialog(shouldComponentUpdate);
    };

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            open={open}
            maxWidth={"xs"}
            fullWidth={true}
        >
            <div className={"dialog-container"}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                    <DialogTitleDivider />
                </DialogTitle>
                <CloseIcon className={"dialog-close-icon icon"} onClick={() => handleClose(false)} />
                <DialogContent style={{ paddingTop: 0 }}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12}>
                            {
                                isFiringType &&
                                <Grid container direction="column" justify="center" item style={{ paddingBottom: "15px" }}>
                                    <FormControl style={{ width: "100%" }}>
                                        <InputLabel id={`label-option`}>
                                            {getLocalizedString("firingLegalType")}
                                        </InputLabel>
                                        <Select
                                            required
                                            value={option}
                                            labelId={`label-option`}
                                            id={`select-option`}
                                            onChange={(e) => setOption(e.target.value)}
                                            margin={"none"}
                                        >
                                            {
                                                fireCalculationData.legal_reasons.map((item, index) => (
                                                    <MenuItem
                                                        className={"whiteText"}
                                                        key={item.code}
                                                        value={item.code}>
                                                        {item.value}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }

                            <TextField
                                fullWidth
                                label={getLocalizedString("insertComment")}
                                multiline={true}
                                rows={1}
                                rowsMax={3}
                                value={comment}
                                onChange={(e) => setComment(e.target.value.toString().slice(0, 1024))}
                            />
                            <Typography
                                style={{ paddingTop: 5 }}
                                className={"whiteText"}
                                variant="caption"
                                gutterBottom>
                                {`${getLocalizedString("optionalField")}. ${getLocalizedString("currentChar")} ${comment ? comment.length : 0}. ${getLocalizedString("maxCharRequests")}.`}
                            </Typography>
                            <DialogActions>
                                <CustomButtonNo
                                    variant="contained"
                                    onClick={() => validateAndConfirm(false)}
                                >
                                    {getLocalizedString("rejectRequest")}
                                </CustomButtonNo>
                                <CustomButtonYes
                                    variant="contained"
                                    onClick={() => validateAndConfirm(true)}
                                >
                                    {getLocalizedString("approveRequest")}
                                </CustomButtonYes>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
}

export default ReplyDialog;