import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  getMyRequestsRows,
  changeRequestStatus,
  getLicensePeriodsAction,
} from "../actions";
import {
  loading,
  getMyRequests,
  getDateFormat,
  getLocale,
  getTypesPermission,
  getTypesAdvances,
  getDecimalSeparator,
  getLicencePeriods,
  getTypes,
  getRecipients,
  getApprovants,
  getAdditionalLegalDays,
  getAllowHalfDayHolidays,
} from "../selectors";
import { getTheme } from "../../login/selectors";
import { getAppColor } from "../../../app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { getIsExportingGrid } from "src/app/selectors";
import ReplyDialog from "./dialogs/replyDialog";
import SecondGridDialog from "./dialogs/secondGridDialog";
import RequestDialog from "../../myRequests/components/modalRequests/RequestDialog";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CoincidenceDialog from "./dialogs/coincidenceDialog";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import { requestsIds } from "@icarius-utils/requestsIds";
import moment from "moment";
import ViewBalanceDialog from "../../myRequests/components/viewBalanceDialog";
import SelectApprovantDialog from "./dialogs/selectApprovantDialog";

class MyPending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      includesHistory: false,
      currentRequestWorkflow: [],
      requestType: null,
      canAnswerAppeal: null,
      canModifyAppeal: null,
      vacationCalculationData: {},
      permissionCalculationData: {},
      secondGridDialogIsOpen: false,
      replyDialogIsOpen: false,
      editDialogIsOpen: false,
      coincidencesDialogIsOpen: false,
      isReadOnly: false,
      viewBalanceIsOpen: false,
      approveResponseData: null,
      selectApprovantDialogIsOpen: false,
    };
  }

  render() {
    const {
      myRequestsRows,
      loadingRows,
      exportingGrid,
      color,
      dateFormat,
      locale,
      theme,
      typesPermission,
      typesAdvances,
      licencePeriods,
      types,
      recipients,
      approvants,
      additionalLegalDays,
      allowHalfDayHolidays,
    } = this.props;
    const helpItems = [
      <MenuItemWithIcon key={"a"} value={"/resources/ICARIUS - Solicitudes Colaborador.pdf"} text={getLocalizedString("requestsColabInstructivo")} type={"file"} />,
      <MenuItemWithIcon key={"2"} value={"https://www.youtube.com/embed/8fj60nDAJ4A"} text={getLocalizedString("requestsTitle")} type={"video"} />
    ]
    return (
      <CommonPage
        helpItems={helpItems}
        rowData={myRequestsRows}
        title={getLocalizedString("myPending")}
        gridTitle={getLocalizedString("myPending")}
        locale={locale}
        dateFormat={dateFormat}
        columnDefPage={paths.myPending}
        handleRowClick={this.handleRowClick}
        isLoading={loadingRows || exportingGrid}
        hasHelp
        hasExpand
        hasSelectAll
        hasSwitch
        switchValue={this.state.includesHistory}
        handleSwitchClick={this.handleSwitchClick}
        switchLabelOn={getLocalizedString("requestsInHistory")}
        switchLabelOff={getLocalizedString("requestsInProgress")}
      >
        {
          this.state.secondGridDialogIsOpen && !loadingRows && !exportingGrid &&
          <SecondGridDialog
            open={this.state.secondGridDialogIsOpen}
            data={this.state.currentRequestWorkflow}
            type={this.state.requestType}
            canAnswerAppeal={this.state.canAnswerAppeal}
            canModifyAppeal={this.state.canModifyAppeal}
            dateFormat={dateFormat}
            locale={locale}
            handleOpenCoincidencesDialog={this.handleOpenCoincidencesDialog}
            handleOpenEditDialog={this.handleOpenEditDialog}
            handleOpenReplyDialog={this.handleOpenReplyDialog}
            handleOpenViewDialog={this.handleOpenViewDialog}
            handleClose={this.handleCloseSecondGridDialog}
          />
        }
        {
          this.state.editDialogIsOpen && !loadingRows && !exportingGrid &&
          <RequestDialog
            open={this.state.editDialogIsOpen}
            usedDates={this.state.requestType !== requestsIds.id_type.ANT && this.state.vacationCalculationData?.used_dates}
            vacationCalculationData={this.state.vacationCalculationData}
            permissionCalculationData={this.state.permissionCalculationData}
            typesAdvances={typesAdvances}
            typesPermission={typesPermission}
            requestType={this.state.requestType}
            editData={this.state.currentRequestWorkflow[0]}
            isForEdit={true}
            isReadOnly={this.state.isReadOnly}
            requestCode={this.state.currentRequestWorkflow[0].code}
            recipients={recipients}
            handleViewBalance={this.handleOpenViewBalance}
            allowHalfDayHolidays={allowHalfDayHolidays}
            handleRequest={this.handleRequest}
            handleClose={this.handleCloseEditDialog}
            handleCloseViewDialog={this.handleCloseViewDialog}
          />
        }
        {
          this.state.replyDialogIsOpen && !loadingRows && !exportingGrid &&
          <ReplyDialog
            open={this.state.replyDialogIsOpen}
            handleCloseDialog={this.handleCloseReplyDialog}
            handleAccept={this.handleAccept}
            title={getLocalizedString("acceptRequest")}
            additionalLegalDays={additionalLegalDays}
            vacationCalculationData={this.state.vacationCalculationData}
            editData={this.state.currentRequestWorkflow[0]}
          />
        }
        {
          this.state.coincidencesDialogIsOpen && !loadingRows && !exportingGrid &&
          <CoincidenceDialog
            open={this.state.coincidencesDialogIsOpen}
            requestType={this.state.requestType}
            handleCloseDialog={this.handleCloseCoincidencesDialog}
            color={color}
            dateFormat={dateFormat}
            locale={locale}
            data={this.state.currentRequestWorkflow[0]}
            gridTheme={theme}
            types={types}
            licencePeriods={licencePeriods}
          />
        }
        {
          // ver saldos
          this.state.viewBalanceIsOpen && !loadingRows && !exportingGrid &&
          <ViewBalanceDialog
            open={this.state.viewBalanceIsOpen}
            data={this.state.currentRequestWorkflow[0].ccHoursAppeals}
            dateFormat={dateFormat}
            locale={locale}
            handleClose={this.handleCloseViewBalance}
          />
        }
        {
          this.state.selectApprovantDialogIsOpen &&
          <SelectApprovantDialog
            open={this.state.selectApprovantDialogIsOpen}
            handleAccept={this.acceptWithMultipleApprovation}
            handleClose={this.handleCloseSelectApprovantDialog}
            selectionData={approvants}
          />
        }
      </CommonPage>
    );
  }

  handleOpenViewBalance = () => {
    this.setState({ viewBalanceIsOpen: true });
  }

  handleCloseViewBalance = () => {
    this.setState({ viewBalanceIsOpen: false });
  }

  componentDidMount() {
    this.props.dispatch(getMyRequestsRows(this.state.includesHistory));
  }

  handleRequest = (data) => {
    this.props.dispatch(changeRequestStatus(data, this.state.requestType))
      .then(resp => this.handleCloseEditDialog(resp))
      .then(() => this.handleCloseSecondGridDialog())
  }

  handleAccept = (comment, approves) => {
    let data = {
      "code": this.state.currentRequestWorkflow[0].code,
      "id_status": approves ? "6" : "7",
      "comments": comment,
    };

    //Si rechaza la propuesta, pasa al estado 7
    if (!approves) {
      this.changeRequestStatusAndClose(data);
    } else {
      // si aprueba, pasa al estado 6, pero antes verificar si tiene aprobacion multiple
      if (this.state.currentRequestWorkflow[0].multipleApprovation) {
        // si es de multiple aprobación, abrir el modal
        this.setState({
          approveResponseData: data,
          selectApprovantDialogIsOpen: true,
        });
      } else {
        // sino, aprobar de una
        this.changeRequestStatusAndClose(data);
      }
    }
  }

  handleCloseSelectApprovantDialog = () => {
    this.setState({
      approveResponseData: null,
      selectApprovantDialogIsOpen: false,
    });
  }

  changeRequestStatusAndClose = (data) => {
    this.handleCloseSelectApprovantDialog();
    this.props.dispatch(changeRequestStatus(data, this.state.requestType))
      .then(resp => this.handleCloseReplyDialog(resp))
      .then(() => this.handleCloseSecondGridDialog());
  }

  acceptWithMultipleApprovation = (approvant) => {
    const dataToUse = { ...this.state.approveResponseData };
    dataToUse["extra_approvant"] = approvant;
    this.changeRequestStatusAndClose(dataToUse);
  }

  handleOpenReplyDialog = () => {
    this.setState({ replyDialogIsOpen: true });
  }

  handleCloseReplyDialog = (shouldReloadTable) => {
    if (typeof shouldReloadTable === "object" && shouldReloadTable.data.status === "OK") {
      this.setState({ replyDialogIsOpen: false },
        () => {
          this.props.dispatch(getMyRequestsRows(this.state.includesHistory));
        }
      );
    } else {
      this.setState({ replyDialogIsOpen: false });
    }
  }

  handleOpenCoincidencesDialog = () => {
    const startDate = moment(createDateFromDDMMYYYY(this.state.currentRequestWorkflow[0].start_date)).format("YYYY/MM/DD");
    const endDate = moment(createDateFromDDMMYYYY(this.state.currentRequestWorkflow[0].end_date)).format("YYYY/MM/DD");

    this.props.dispatch(getLicensePeriodsAction(startDate, endDate)).then(() =>
      this.setState({ coincidencesDialogIsOpen: true }));
  }

  handleCloseCoincidencesDialog = () => {
    this.setState({ coincidencesDialogIsOpen: false });
  }

  handleCloseEditDialog = (shouldReloadTable) => {
    if (typeof shouldReloadTable === "object" && shouldReloadTable.data.status === "OK") {
      this.setState({
        editDialogIsOpen: false,
        requestType: null,
        currentRequestWorkflow: [],
        vacationCalculationData: {},
        permissionCalculationData: {},
        secondGridDialogIsOpen: false,
      },
        () => {
          this.props.dispatch(getMyRequestsRows(this.state.includesHistory));
        }
      );
    } else {
      this.setState({
        editDialogIsOpen: false,
      });
    }
  }

  handleOpenEditDialog = () => {
    this.setState({ editDialogIsOpen: true })
  }

  handleOpenViewDialog = () => {
    this.setState({ isReadOnly: true, editDialogIsOpen: true })
  }

  handleCloseViewDialog = () => {
    this.setState({ isReadOnly: false, editDialogIsOpen: true })
  }

  handleSwitchClick = () => {
    this.setState({ includesHistory: !this.state.includesHistory },
      () => {
        this.props.dispatch(getMyRequestsRows(this.state.includesHistory));
      }
    );
  };

  handleRowClick = row => {
    this.setState({
      requestType: row.data.type,
      permissionCalculationData: row.data.permission_calculation_data,
      vacationCalculationData: row.data.vacations_calculation_data,
      currentRequestWorkflow: row.data.complete_workflow,
      canAnswerAppeal: row.data.canAnswerAppeal,
      canModifyAppeal: row.data.canModifyAppeal,
      secondGridDialogIsOpen: true,
    });
  };

  handleCloseSecondGridDialog = () => {
    this.setState({
      canAnswerAppeal: null,
      canModifyAppeal: null,
      secondGridDialogIsOpen: false,
    });
  }
}

export default connect(
  createStructuredSelector({
    myRequestsRows: getMyRequests,
    dateFormat: getDateFormat,
    locale: getLocale,
    decimalSeparator: getDecimalSeparator,
    typesPermission: getTypesPermission,
    typesAdvances: getTypesAdvances,
    exportingGrid: getIsExportingGrid,
    loadingRows: loading,
    color: getAppColor,
    theme: getTheme,
    licencePeriods: getLicencePeriods,
    types: getTypes,
    recipients: getRecipients,
    approvants: getApprovants,
    additionalLegalDays: getAdditionalLegalDays,
    allowHalfDayHolidays: getAllowHalfDayHolidays,
  })
)(MyPending);
