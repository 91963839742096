import NAME from "./constants";

export const getIsLoading = store => store[NAME].isLoading;
export const getData = store => store[NAME].data;
export const getEmployeeCreditData = store => store[NAME].creditData;
export const getEmployeeDebitData = store => store[NAME].debitData;
export const getEmployeeMiscData = store => store[NAME].miscData;
export const getVacationsSchema = store => store[NAME].vacationsSchema;
export const getLocale = store => store[NAME].locale;
export const getDateFormat = store => store[NAME].dateFormat;
export const getContractDate = store => store[NAME].contractDate;
export const getVacationsUpdateDate = store => store[NAME].vacationsUpdateDate;