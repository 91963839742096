import React, { useState } from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";

const FiltersDialog = (props) => {

  const {
    open,
    data,
    handleClose,
    handleConfirm,
    setFilterData,
    selects,
    nodesWithChildren,
  } = props;

  const [formData, setFormData] = useState({
    "Grado de responsabilidad": data["Grado de responsabilidad"] || "",
    "Desde un colaborador con personal a cargo": data["Desde un colaborador con personal a cargo"] || "",
  });

  const validateAndCreate = () => {
    setFilterData(formData);
    handleConfirm(formData);
  }

  const handleChange = (field, newValues) => {
    const aux = {
      "Grado de responsabilidad": "",
      "Desde un colaborador con personal a cargo": "",
    };

    aux[field] = newValues;
    setFormData(aux);
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {"Filtrar organigrama"}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container direction="column" style={{ maxHeight: 500, flexWrap: "nowrap" }} spacing={2}>
          <Grid container direction="row" justify="center" item>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-Grado de responsabilidad`}>
                {"Grado de responsabilidad"}
              </InputLabel>
              <Select
                value={formData["Grado de responsabilidad"]}
                labelId={`label-Grado de responsabilidad`}
                id={`select-Grado de responsabilidad`}
                onChange={(e) => handleChange("Grado de responsabilidad", String(e.target.value))}
                margin={"none"}
              >
                <MenuItem className={"whiteText"} value={''}>
                  {'Sin filtro'}
                </MenuItem>
                {
                  selects.responsabilityLevelSelect.map((item) => (
                    <MenuItem
                      className={"whiteText"}
                      key={item}
                      value={item}>
                      {item}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container direction="row" justify="center" item>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-Desde un colaborador con personal a cargo`}>
                {"Desde un colaborador con personal a cargo"}
              </InputLabel>
              <Select
                value={formData["Desde un colaborador con personal a cargo"]}
                labelId={`label-Desde un colaborador con personal a cargo`}
                id={`select-Desde un colaborador con personal a cargo`}
                onChange={(e) => handleChange("Desde un colaborador con personal a cargo", String(e.target.value))}
                margin={"none"}
              >
                <MenuItem className={"whiteText"} value={''}>
                  {'Sin filtro'}
                </MenuItem>
                {
                  nodesWithChildren.map((item) => (
                    <MenuItem
                      className={"whiteText"}
                      key={item["id"]}
                      value={item["id"]}>
                      {item.employeeName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("close")} />
        <ButtonDialogAction onClick={validateAndCreate} isAccept text={getLocalizedString("agree")} />
      </DialogActions>
    </Dialog>
  )
}

export default FiltersDialog;