import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Typography } from "@material-ui/core";
import ColorizeIcon from '@material-ui/icons/Colorize';
import { getAppColor } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { SaveIcon } from "@icarius-icons";
import { getInitialDataAction } from "src/app/actions";
import DynamicInput from "@icarius-common/dynamicInputs";
import { updateGeneralSettingsAction } from "../../actions";
import ChangeColorDialog from "../changeColorDialog";
import GenericSection from "./sections/genericSection";
import LogosAndCustomization from "./sections/logosAndCustomization";
import useHandleForm from "./useHandleForm";

const Form = ({ generalSettings }) => {

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const [changeColorDialogIsOpen, setChangeColorDialogIsOpen] = useState(false);

  const modify = (dataToSend) => {
    dispatch(updateGeneralSettingsAction(dataToSend))
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(getInitialDataAction());
        }
      });
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(generalSettings, modify);

  if (!Boolean(generalSettings) || !Object.keys(formData)?.length) return null;

  return (
    <div id={"main-content"} style={{ padding: 50, paddingTop: 30 }}>
      {
        generalSettings?.activationDeactivation &&
        <GenericSection
          title={getLocalizedString("activationDeactivation")}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.activationDeactivation}
        />
      }
      {
        generalSettings?.requests &&
        <GenericSection
          title={getLocalizedString("requests")}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.requests}
        />
      }
      {
        generalSettings?.identificationAndSocietyData &&
        <GenericSection
          title={getLocalizedString("identificationAndSocietyData")}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.identificationAndSocietyData}
        />
      }
      {
        generalSettings?.decimalFieldsDefinition &&
        <GenericSection
          title={getLocalizedString("decimalFieldsDefinition")}
          subtitle={"Decimales (0 a 5)"}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.decimalFieldsDefinition}
        />
      }
      {
        generalSettings?.digitalSign &&
        <GenericSection
          title={getLocalizedString("digitalSign")}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.digitalSign}
        />
      }
      {
        generalSettings?.timezones &&
        <GenericSection
          title={getLocalizedString("timezones")}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.timezones}
          fullSizeColumn
        />
      }
      {
        generalSettings?.geographicOrganization &&
        <GenericSection
          title={getLocalizedString("geographicStructureSettings")}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.geographicOrganization}
        />
      }
      {
        generalSettings?.logosAndCustomization &&
        <>
          <LogosAndCustomization
            title={getLocalizedString("logosAndCustomization")}
            sectionFields={generalSettings.logosAndCustomization}
            formData={formData}
            setFormValue={setFormValue}
          />
          <div style={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Button
              startIcon={<ColorizeIcon />}
              variant={"contained"}
              className="whiteText"
              onClick={() => setChangeColorDialogIsOpen(true)}
            >
              {"Configurar color corporativo"}
            </Button>
          </div>
        </>
      }
      {
        generalSettings?.permissions &&
        <GenericSection
          title={getLocalizedString("permissionsGeneralParameters")}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.permissions}
        />
      }
      {
        generalSettings?.colaboratorPermissions &&
        <GenericSection
          title={getLocalizedString("colaboratorPermissions")}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.colaboratorPermissions}
        />
      }
      {
        generalSettings?.peopleMaster &&
        <GenericSection
          title={getLocalizedString("peopleMasterGeneralParameters")}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.peopleMaster}
        />
      }
      {
        generalSettings?.hideFieldsPeopleMaster &&
        <GenericSection
          title={
            <span
              style={{
                fontSize: "1rem",
                textDecoration: "underline",
                textTransform: "none",
              }}
            >
              {getLocalizedString("hideFieldsPeopleMasterGeneralParameters")}
            </span>
          }
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.hideFieldsPeopleMaster}
        />
      }
      {
        generalSettings?.historicalDataDuration &&
        <GenericSection
          title={getLocalizedString("historicalDataDuration")}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.historicalDataDuration}
        />
      }
      {
        generalSettings?.mobileApps &&
        <GenericSection
          title={getLocalizedString("mobileApps")}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.mobileApps}
        />
      }
      {
        generalSettings?.calculationProcesses &&
        <>
          <GenericSection
            title={'De los procesos de cálculo de nómina'}
            formData={formData}
            setFormValue={setFormValue}
            sectionFields={generalSettings.calculationProcesses}
          />
          <div style={{ paddingTop: 8, width: "100%" }}>
            <DynamicInput
              fieldConfig={{
                name: "DELETE_PROCESS_SQL",
                type: "multiline",
                editable: true,
              }}
              label={getLocalizedString("DELETE_PROCESS_SQL")}
              value={formData["DELETE_PROCESS_SQL"]}
              handleChange={setFormValue}
            />
            <Typography variant="caption" className="whiteText" style={{ paddingLeft: 10 }}>
              {"La variable #CODES# contiene los códigos de procesos de cálculo que se eliminaran"}
            </Typography>
          </div>
        </>
      }
      {
        generalSettings?.incidences &&
        <GenericSection
          title={'De las incidencias'}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.incidences}
        />
      }
      {
        generalSettings?.orgchart &&
        <GenericSection
          title={'Del organigrama'}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.orgchart}
        />
      }
      {
        generalSettings?.observations &&
        <GenericSection
          title={'De gestión de tiempos'}
          subtitle={'¿Considera a las siguientes como "observaciones" para el análisis de asistencia?'}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.observations}
        />
      }
      {
        generalSettings?.benefits &&
        <GenericSection
          title={'De los beneficios'}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.benefits}
        />
      }
      {
        generalSettings?.publications &&
        <GenericSection
          title={'De las publicaciones'}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.publications}
        />
      }
      {
        generalSettings?.goalsAndObjectives &&
        <GenericSection
          title={getLocalizedString("goalsAndObjectives")}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.goalsAndObjectives}
        />
      }
      {
        generalSettings?.performanceRatings &&
        <GenericSection
          title={'De las calificaciones de desempeño'}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.performanceRatings}
        />
      }
      {
        generalSettings?.talentRanking &&
        <GenericSection
          title={'De la clasificación del talento (9-Boxes)'}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.talentRanking}
        />
      }
      {
        generalSettings?.talentRanking &&
        <GenericSection
          title={'De la analítica del desempeño y talento'}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.performanceAnalyitics}
        />
      }
      {
        generalSettings?.communicationChannelSettings &&
        <GenericSection
          title={'Del canal de comunicaciones'}
          formData={formData}
          setFormValue={setFormValue}
          sectionFields={generalSettings.communicationChannelSettings}
        />
      }
      <Grid container item xs={12} justify={"flex-end"}>
        <Button
          className="whiteText"
          style={{ cursor: "pointer" }}
          onClick={submit}
          variant="contained"
          startIcon={<SaveIcon htmlColor={color} />}
        >
          {getLocalizedString("save")}
        </Button>
      </Grid>
      {
        changeColorDialogIsOpen &&
        <ChangeColorDialog
          open={changeColorDialogIsOpen}
          companyColor={color}
          handleCloseDialog={() => setChangeColorDialogIsOpen(false)}
        />
      }
    </div>
  )
}

export default Form;
