import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { getFileName, getFileExtension, makeFile } from "@icarius-utils/fileUpload";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getTomorrow, formatDate, createDateFromDDMMYYYY } from "@icarius-utils/date";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { getLocalizedString } from "@icarius-localization/strings";
import { createAnnexInServer, updateAnnexInServer } from "@icarius-pages/annexesPage/actions";
import { getUserRole } from "src/app/selectors";
import { roles } from "@icarius-utils/constants";

const gridStyle = { height: 75 };

const CreateEditDialog = (props) => {

  const {
    open,
    instances,
    types,
    employeeCode,
    annex,
    handleClose,
  } = props;

  const dispatch = useDispatch();
  const isEdit = Boolean(annex);
  const role = useSelector(getUserRole);
  const isCollaborator = role === roles.collaborator;

  const [formData, setFormData] = useState(() => {
    return {
      Instancia: annex?.Instancia || "",
      Tipo: annex?.Tipo || "",
      descripcion: annex?.Texto || "",
      fileName: annex?.["Nombre archivo"] ? getFileName(annex["Nombre archivo"]) : "",
      fileExtension: annex?.["Nombre archivo"] ? getFileExtension(annex["Nombre archivo"]) : "",
      date: annex?.["Fecha de vencimiento"] ? (annex["Fecha de vencimiento"] === "31/12/9999" ? null : createDateFromDDMMYYYY(annex["Fecha de vencimiento"])) : null,
      switchVisibility: annex?.colaborator_visible || true,
    };
  });

  const setFormValue = (value, fieldName) => {
    if (fieldName === "file") {
      setFormData({
        ...formData,
        file: value.file,
        fileName: value.fileName,
        fileExtension: value.fileExtension,
      })
      return;
    }

    if (fieldName === "Instancia") {
      setFormData({
        ...formData,
        [fieldName]: value,
        Tipo: "",
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  }

  const textIsValid = () => {
    return formData.descripcion && formData.descripcion.length > 0 && formData.descripcion.length <= 250;
  };

  const dateIsValid = () => {
    const hoy = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    return formData.date === "" || formData.date === null || formData.date > hoy;
  };

  const dataIsComplete = () => {
    if (formData.Instancia && formData.Tipo && (isEdit || formData.file) && formData.fileName && formData.fileExtension && textIsValid()) return true;
    if (formData.Instancia === "OT" && (isEdit || formData.file) && formData.fileName && formData.fileExtension && textIsValid()) return true;
    return false;
  };

  const validateAndCreate = () => {
    if (textIsValid() && dataIsComplete() && dateIsValid()) {
      const annexToSend = {
        Code: annex ? annex.Codigo : null,
        Codigo: employeeCode,
        inslab: formData.Instancia,
        tipo: formData.Tipo,
        texto: formData.descripcion,
        file: formData.file || null,
        extension: formData.file ? formData.fileExtension : null,
        nombre: formData.file ? formData.fileName + "." + formData.fileExtension : null,
        fecha: !formData.date ? "31/12/9999" : formatDate(formData.date),
        colaborator_visible: formData.switchVisibility,
      };

      if (isEdit) {
        delete annexToSend.Codigo;
      } else {
        delete annexToSend.Code;
      }

      dispatch(isEdit ? updateAnnexInServer(annexToSend) : createAnnexInServer(annexToSend));
      handleClose();
    }
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {isEdit ? getLocalizedString("iconEditAnnex") : getLocalizedString("iconNewAnnex")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
          <Typography
            className={!dataIsComplete() ? "errorColor" : "whiteText"}
            variant="caption"
            gutterBottom
          >
            {getLocalizedString("fieldRequeriment")}
          </Typography>
          <Grid container alignItems="center" item xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-Instancia`}>{getLocalizedString("instance")}</InputLabel>
              <Select
                value={formData.Instancia}
                labelId={`label-Instancia`}
                id={`select-Instancia`}
                onChange={(e) => setFormValue(e.target.value, "Instancia")}
                margin={"none"}
              >
                {
                  instances.map((item) => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}
                    >
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container alignItems="center" item xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel
                id={`label-Tipo`}
                required={Boolean(formData.Instancia && formData.Instancia !== "OT")}
              >
                {getLocalizedString("type")}
              </InputLabel>
              <Select
                disabled={!formData.Instancia || formData.Instancia === "OT"}
                value={formData.Tipo}
                labelId={`label-Tipo`}
                id={`select-Tipo`}
                onChange={(e) => setFormValue(e.target.value, "Tipo")}
                margin={"none"}
              >
                {
                  (types[formData.Instancia] || []).map((subItem) => (
                    <MenuItem
                      className={"whiteText"}
                      key={subItem.key}
                      value={subItem.key}
                    >
                      {subItem.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} style={{ ...gridStyle, height: "", minHeight: 75, paddingTop: 10 }}>
            <TextField
              label={getLocalizedString("enterText")}
              value={formData.descripcion}
              margin={"none"}
              onChange={(e) => setFormValue(e.target.value.toString().slice(0, 250), "descripcion")}
              multiline={true}
              rows={1}
              rowsMax={3}
              fullWidth
            />
            <Typography
              style={{ paddingTop: 5 }}
              className={!formData.descripcion || formData.descripcion.length > 250 ? "errorColor" : "whiteText"}
              variant="caption"
              gutterBottom
            >
              {`${getLocalizedString("currentChar")} ${formData.descripcion?.length || 0}. ${getLocalizedString("maxCharAnnex")}.`}
            </Typography>
          </Grid>
          <Grid container alignItems="center" item xs={12} style={{ ...gridStyle, paddingTop: 10 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                fullWidth
                label={getLocalizedString("annexFechaVencimiento")}
                clearable
                clearLabel={getLocalizedString("clear")}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                disablePast
                minDate={getTomorrow()}
                format="dd/MM/yyyy"
                margin={"none"}
                invalidDateMessage=''
                minDateMessage=''
                maxDateMessage=''
                value={formData.date}
                onChange={(e) => setFormValue(e, "date")}
              />
            </MuiPickersUtilsProvider>
            <Typography className={!dateIsValid() ? "errorColor" : "whiteText"} variant="caption" gutterBottom>
              {getLocalizedString("annexDateHelper")}
            </Typography>
          </Grid>
          <Grid style={{ paddingTop: 15 }} container direction="row" justify="flex-start" item>
            {
              !isCollaborator &&
              <Grid container item xs={12} justify="flex-end" style={{ padding: 10 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.switchVisibility}
                      value={formData.switchVisibility}
                      onChange={(e) => setFormValue(e.target.checked, "switchVisibility")}
                    />
                  }
                  label={getLocalizedString("availableColaborador")}
                  labelPlacement="start"
                />
              </Grid>
            }
            <Grid container direction="column" alignItems="center" justify="center" item xs={12}>
              <input
                type="file"
                id="fileInputUpload"
                style={{ display: "none" }}
                onChange={(e) => {
                  const files = e.target.files;

                  makeFile(files[0], (myBase64) => {
                    const newFileValue = {
                      file: myBase64,
                      fileName: getFileName(files[0].name),
                      fileExtension: getFileExtension(files[0].name),
                    };

                    setFormValue(newFileValue, "file");
                  });
                }}
              />
              <label htmlFor="fileInputUpload">
                <Button
                  variant={"contained"}
                  disableRipple={true}
                  style={{ fontWeight: 500 }}
                  className="whiteText"
                  component={"span"}
                >
                  {getLocalizedString("selectAnnex")}
                </Button>
              </label>
              <Typography variant={"caption"} className={"whiteText"}>
                {formData.fileName ? formData.fileName + "." + formData.fileExtension : getLocalizedString("noAnnexYet")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction onClick={validateAndCreate} isAccept text={getLocalizedString("agree")} />
      </DialogActions>
    </Dialog>
  );
}

export default CreateEditDialog;