import React from "react";
import { connect } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { requestsIds } from "@icarius-utils/requestsIds";
import NewDisengagementContent from "./newDisengagementContent";
import NewPayRiseContent from "./newPayRiseContent";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import NewLoanContent from "@icarius-pages/myRequests/components/modalRequests/newLoanContent";
import DialogTransition from "@icarius-common/dialogTransition";

class RequestDialog extends React.Component {

  handleClose = (value) => {
    if(this.props.isReadOnly){
      this.props.handleCloseViewDialog();
    }
    if (this.props.isForEdit && typeof value === "boolean" && value) {
      this.props.handleClose(true);
    } else {
      this.props.handleClose();
    }
  };

  renderContent = () => {
    switch (this.props.requestType) {
      case requestsIds.id_type.FR:
        return (
          <NewDisengagementContent
            handleRequest={this.props.handleRequest}
            handleClose={this.handleClose}
            editData={this.props.editData}
            isForEdit={this.props.isForEdit}
            requestCode={this.props.requestCode}
            colaborators={this.props.colaborators}
            fireCalculationData={this.props.fireCalculationData}
            isReadOnly={this.props.isReadOnly}
            recipients={this.props.recipients}
            handleNoRecipient={this.props.handleNoRecipient}
          />
        );
      case requestsIds.id_type.RS:
        return (
          <NewPayRiseContent
            handleRequest={this.props.handleRequest}
            handleClose={this.handleClose}
            editData={this.props.editData}
            isForEdit={this.props.isForEdit}
            requestCode={this.props.requestCode}
            colaborators={this.props.colaborators}
            raiseCalculationData={this.props.raiseCalculationData}
            isReadOnly={this.props.isReadOnly}
            recipients={this.props.recipients}
            handleNoRecipient={this.props.handleNoRecipient}
          />
        );
      case requestsIds.id_type.ANT:
        return (
          <NewLoanContent
            isReadOnly={this.props.isReadOnly}            
            typesAdvances={this.props.typesAdvances}
            handleRequest={this.props.handleRequest}
            handleClose={this.handleClose}
            editData={this.props.editData}
            isForEdit={this.props.isForEdit}
            requestCode={this.props.requestCode}
            recipients={this.props.recipients}
            handleNoRecipient={this.props.handleNoRecipient}
            advanceCalculationData={this.props.advanceCalculationData}
          />
        );
      default:
        return "";
    }
  };

  shouldComponentUpdate = () => {
    return false;
  }

  render() {
    const { open } = this.props;

    const StyledDialog = withStyles({
      paper: {
        maxWidth: "95%",
        minWidth: "70%",
      },
    })(Dialog);

    return (
      <StyledDialog
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        open={open}
        scroll={"paper"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {(!this.props.isForEdit || this.props.isReadOnly) && `${getLocalizedString("requestCreateTitle")} ${this.props.requestType}`}
          {this.props.isForEdit && !this.props.isReadOnly && `${getLocalizedString("requestEditTitle")} ${this.props.requestType}`}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={this.handleClose} />
        {this.renderContent()}
      </StyledDialog>
    );
  }
}

export default connect()(RequestDialog);