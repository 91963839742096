import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getGeographicalDivisions } from "src/app/selectors";
import { MenuItem, Select, FormControl, IconButton, Tooltip } from "@material-ui/core";
import { GetAppIcon, VisibilityIcon, MailIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import ConfirmPinDialog from "@icarius-common/confirmPinDialog";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import { applyGeographicalDivision } from "@icarius-table/utils";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import UserDialog from "@icarius-pages/certificates/components/userDialog";
import useReceipts from "./useReceipts";

const Receipts = ({ match }) => {

  const isDigitalSignVersion = match.path === paths.digitalReceipts;

  const {
    isLoading,
    isProgressLoading,
    collaborators,
    receipts,
    calculationProcesses,
    dateFormat,
    locale,
    state,
    handlers,
  } = useReceipts(isDigitalSignVersion);

  const geographicalDivisions = useSelector(getGeographicalDivisions);

  const ownColumnDef = useMemo(() => {
    return applyGeographicalDivision(getColumnDefByPage(match.path), geographicalDivisions);
  }, [match, geographicalDivisions])

  const customHeader = (gridRef) => (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10, marginRight: 10 }}>
      <FormControl style={{ width: 275 }}>
        <Select
          value={state.selectedReceiptCode}
          onChange={e => handlers.handleSelectReceiptClick(e, gridRef)}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">
            {getLocalizedString("selectTypeOfDocument")}
          </MenuItem>
          {
            receipts.map((item, index) => {
              return (
                <MenuItem key={index} value={item.code}>
                  {item.name}
                </MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
      {
        state.selectedReceiptCode &&
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControl style={{ width: 275 }}>
            <Select
              value={state.selectedCalculationProcessCode}
              onChange={e => handlers.handleSelectCalculationProcess(e, gridRef)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="">
                {getLocalizedString("selectCalcMethod")}
              </MenuItem>
              {
                calculationProcesses?.map((item) => {
                  return (
                    <MenuItem
                      key={item.reference}
                      value={item.reference}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          <Tooltip title={getLocalizedString(isDigitalSignVersion ? "generateAndDownloadWithSign" : "generateAndDownloadNoSign")}>
            <IconButton onClick={() => handlers.handleDownloadDocuments(gridRef)}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          {
            !isDigitalSignVersion &&
            <Tooltip title={"Generar y enviar al correo"}>
              <IconButton onClick={() => handlers.handleSendReceipts(gridRef)}>
                <MailIcon />
              </IconButton>
            </Tooltip>
          }
          {
            isDigitalSignVersion &&
            <Tooltip title={getLocalizedString("previewDocuments")}>
              <IconButton onClick={() => handlers.handlePreviewReceipts(gridRef)}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          }
        </div>
      }
    </div>
  )

  const helpItems = [
    <MenuItemWithIcon
      key={"1"}
      value={"/resources/ICARIUS - Firma Digital de documentos.pdf"}
      text={getLocalizedString("digitalSignature")}
      type={"file"}
    />,
  ]

  const title = getLocalizedString(isDigitalSignVersion ? "receiptsDigitalFullTitle" : "receiptsFullTitle");

  return (
    <CommonPage
      isLoading={isLoading}
      hideLoading={isProgressLoading}
      helpItems={helpItems}
      customHeader={customHeader}
      title={title}
      gridTitle={title}
      rowData={collaborators}
      locale={locale}
      dateFormat={dateFormat}
      ownColumnDef={ownColumnDef}
      handleRowClick={handlers.handleRowClick}
      hasExpand
      hasHelp
    >
      {
        Boolean(state.userDialogEmployeeCode) &&
        <UserDialog
          open={Boolean(state.userDialogEmployeeCode)}
          isLoading={isLoading}
          employee={collaborators.find((employee) => employee["CODIGO DE EMPLEADO"] === state.userDialogEmployeeCode)}
          hideLoading={isProgressLoading}
          handleGenerate={handlers.handleGenerate}
          handleClose={handlers.handleCloseUserDialog}
        />
      }
      {
        state.pinDialogIsOpen &&
        <ConfirmPinDialog
          open={state.pinDialogIsOpen}
          text={getLocalizedString("confirmAcceptRequestedDocuments")}
          requiresSelection={receipts.find(receipt => receipt.code === state.selectedReceiptCode)?.multipleApprovation}
          selectionData={receipts.find(receipt => receipt.code === state.selectedReceiptCode)?.approvants}
          handleClose={handlers.handleCloseConfirmDialog}
          handleConfirm={handlers.handleSign}
        />
      }
    </CommonPage>
  );
}

export default Receipts;