import React from "react";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import DefaultImage from "@icarius-assets/images/no_profile_dark.png";
import { Grid, Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { makeFile } from "@icarius-utils/fileUpload";

const onMediaFallback = (event) => event.target.src = DefaultImage;

const ImageInput = ({ label, value, name, handleChange }) => {
  
  return (
    <Grid container item xs={12} alignItems="center" style={{ height: "164px" }}>
      <Grid container direction="column" alignItems="center" item xs={12}>
        <input
          type="file"
          name={name}
          id={`${name}`}
          accept="image/*"
          style={{ display: "none" }}
          onChange={e => {
            let originalFile = e.target.files[0];

            makeFile(originalFile, (og64) => handleChange({ og64, originalFile }, name))
          }}
        />
        <label htmlFor={`${name}`}>
          <img
            alt="no img"
            style={{ cursor: "pointer", height: "120px", border: "2px solid var(--profileBorder)" }}
            src={value && value.includes("base64") ? value : IMAGES_ENDPOINT + value}
            onError={onMediaFallback}
          />
        </label>
        <Typography variant={"subtitle1"} className={"field"}>
          <span id={name} className={"whiteText"} style={{ fontWeight: 400 }}>{label}</span>
        </Typography>
        {
          (name === "lightWebsiteLogo" || name === "darkWebsiteLogo") &&
          <>
            <Typography variant={"subtitle1"} className={"field"}>
              <span id={name} className={"whiteText"} style={{ fontWeight: 300 }}>{getLocalizedString("tooltipWebLogo")}</span>
            </Typography>
          </>
        }
        {
          name === "templateLogo" &&
          <>
            <Typography variant={"subtitle1"} className={"field"}>
              <span id={name} className={"whiteText"} style={{ fontWeight: 700 }}>{getLocalizedString("tooltipTemplateLogoLine1")}</span>
            </Typography>
            <Typography variant={"subtitle1"} className={"field"}>
              <span id={name} className={"whiteText"} style={{ fontWeight: 300 }}>{getLocalizedString("tooltipTemplateLogoLine2")}</span>
            </Typography>
            <Typography variant={"subtitle1"} className={"field"}>
              <span id={name} className={"whiteText"} style={{ fontWeight: 300 }}>{getLocalizedString("tooltipTemplateLogoLine3")}</span>
            </Typography>
          </>
        }
        {
          name === "emailLogo" &&
          <>
            <Typography variant={"subtitle1"} className={"field"}>
              <span id={name} className={"whiteText"} style={{ fontWeight: 700 }}>{getLocalizedString("tooltipEmailLogoLine1")}</span>
            </Typography>
            <Typography variant={"subtitle1"} className={"field"}>
              <span id={name} className={"whiteText"} style={{ fontWeight: 300 }}>{getLocalizedString("tooltipEmailLogoLine2")}</span>
            </Typography>
          </>
        }
        {
          name === "signLogo" &&
          <>
            <Typography variant={"subtitle1"} className={"field"}>
              <span id={name} className={"whiteText"} style={{ fontWeight: 700 }}>{'Imagen para firma no digital'}</span>
            </Typography>
            <Typography variant={"subtitle1"} className={"field"}>
              <span id={name} className={"whiteText"} style={{ fontWeight: 300 }}>{'*.jpg *.png *.bmp'}</span>
            </Typography>
            <Typography variant={"subtitle1"} className={"field"}>
              <span id={name} className={"whiteText"} style={{ fontWeight: 300 }}>{'max 75x300 pixeles'}</span>
            </Typography>
          </>
        }
        {
          name === "loginLogo" &&
          <>
            <Typography variant={"subtitle1"} className={"field"}>
              <span id={name} className={"whiteText"} style={{ fontWeight: 700 }}>{'Imagen de login'}</span>
            </Typography>
            <Typography variant={"subtitle1"} className={"field"}>
              <span id={name} className={"whiteText"} style={{ fontWeight: 300 }}>{'*.jpg *.png *.bmp'}</span>
            </Typography>
            <Typography variant={"subtitle1"} className={"field"}>
              <span id={name} className={"whiteText"} style={{ fontWeight: 300 }}>{'recomendado 4096 x 2670 / 2048 x 1335 pixeles'}</span>
            </Typography>
          </>
        }
      </Grid>
    </Grid>
  );
};

export default ImageInput;
