import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { downloadBlob, previewFromURL } from "@icarius-utils/download";
import {
  getCollaboratorsAndRegularCertificatesAPI,
  downloadCertificatesAPI,
  getCollaboratorsWithDocumentsToSignAPI,
  signAndDownloadApi,
  getCertificatesWithSignAPI,
  previewDocumentsAPI
} from "@icarius-connection/api";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";

export const getRegularCertificatesAndCollaboratorsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_REGULAR_CERTIFICATES_AND_COLLABORATORS });
  try {
    let response = await getCollaboratorsAndRegularCertificatesAPI();
    let collaborators = response.data && response.data.colaborators;
    let regularCertificates = response.data && response.data.certificates;
    let receiverList = response.data && response.data.receiverList;
    let dateFormat = response.data.date_format;
    let locale = response.data.currency_localization;

    if (collaborators.length <= 0) {
      dispatch({ type: actionTypes.GET_REGULAR_CERTIFICATES_AND_COLLABORATORS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("noEmployeesFound"), severity: "error" }));
      return;
    }

    if (regularCertificates.length <= 0) {
      dispatch({ type: actionTypes.GET_REGULAR_CERTIFICATES_AND_COLLABORATORS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("certificatesNotAvailable"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_REGULAR_CERTIFICATES_AND_COLLABORATORS_FULFILLED,
      payload: {
        regularCertificates,
        collaborators,
        receiverList,
        locale,
        dateFormat,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REGULAR_CERTIFICATES_AND_COLLABORATORS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getCertificatesWithSignAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_CERTIFICATES_WITH_SIGN });
  try {
    let response = await getCertificatesWithSignAPI();
    let certificatesWithSign = response.data && response.data.certificates;
    let receiverList = response.data && response.data.receiverList;

    if (certificatesWithSign.length <= 0) {
      dispatch({ type: actionTypes.GET_CERTIFICATES_WITH_SIGN_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("certificatesNotAvailable"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_CERTIFICATES_WITH_SIGN_FULFILLED,
      payload: {
        certificatesWithSign,
        receiverList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_CERTIFICATES_WITH_SIGN_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getCollaboratorsForCertificateAction = (certificate = "", reference, rol) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_COLLABORATORS_FOR_CERTIFICATE });

  const endpointToUse = !certificate ? getCollaboratorsAndRegularCertificatesAPI : getCollaboratorsWithDocumentsToSignAPI;

  try {
    let response = await endpointToUse(!certificate ? "" : `?code=${certificate}&reference=${reference}&role=${rol}`);

    let collaborators = response.data && (response.data.colaborators || response.data.destinataries);
    let dateFormat = response.data.date_format;
    let locale = response.data.currency_localization;
    let status = response.data && response.data.status;

    if (status === "OK") {
      if (collaborators.length <= 0) {
        dispatch({
          type: actionTypes.GET_COLLABORATORS_FOR_CERTIFICATE_FULFILLED,
          payload: {
            collaborators: [],
          }
        });

        if (!certificate) {
          dispatch(openSnackbarAction({ msg: getLocalizedErrorString("noEmployeesFound"), severity: "error" }));
        }
        return;
      }

      dispatch({
        type: actionTypes.GET_COLLABORATORS_FOR_CERTIFICATE_FULFILLED,
        payload: {
          collaborators,
          locale,
          dateFormat,
        },
      });
    } else {
      dispatch({ type: actionTypes.GET_COLLABORATORS_FOR_CERTIFICATE_REJECTED });
    }

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_COLLABORATORS_FOR_CERTIFICATE_REJECTED, payload: e });

    if (e.response?.statusText) {
      switch (e.response.statusText) {
        case "Unauthorized":
          dispatch(openSnackbarAction({ msg: getLocalizedString("certificateUnauth"), severity: "error" }));
          break;
        default:
          dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
      }
    }

    return e.response.status;
  }
};

export const previewDocumentsAction = (docs, receiver) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES });
  try {
    let response = await previewDocumentsAPI({ docs, receiver });
    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES_FULFILLED });
    previewFromURL(RESOURCES_ENDPOINT + response.data.path);

    return response;
  } catch (e) {
    if (e?.response?.data?.status === "VALIDATION_ERROR" && e?.response?.data?.error === "TEMPLATE_NOT_FOUND") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("documentInvalidTemplate"), severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }

    dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES_REJECTED, payload: e });
  }
};

export const downloadCertificateAction = (code, colaborators, receiver, shouldDisableSocket) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES });
  try {
    let response = await downloadCertificatesAPI({
      code,
      receiver,
      colaborator_codes: colaborators,
      disable_socket: Boolean(shouldDisableSocket),
    });

    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const signAndDownloadAction = (docs, password, receiver) => async (dispatch) => {
  dispatch({ type: actionTypes.SIGN_AND_DOWNLOAD });
  try {
    dispatch(openSnackbarAction({
      msg: getLocalizedString("settingDocuments"),
      severity: "info",
      duration: null,
    }));

    let response = await signAndDownloadApi({ docs, password, receiver });
    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: actionTypes.SIGN_AND_DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response.status;
    }

    if (response.status !== 200) {
      dispatch({ type: actionTypes.SIGN_AND_DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return response.status;
    }

    dispatch({
      type: actionTypes.SIGN_AND_DOWNLOAD_FULFILLED,
      payload: { docs }
    });

    return response.status;
  } catch (e) {
    dispatch({ type: actionTypes.SIGN_AND_DOWNLOAD_REJECTED, payload: e });

    if (e?.response?.data?.status === "VALIDATION_ERROR" && e.response?.data?.rejectedValues[0].error === "TEMPLATE_NOT_FOUND") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("documentInvalidTemplate"), severity: "error" }));
    } else {
      switch (e.response?.statusText) {
        case "Unauthorized": //pin incorrecto
          dispatch(openSnackbarAction({ msg: getLocalizedString("certificateWrongPing"), severity: "error" }));
          break;
        case "Forbidden": //usuario no habilitado
          dispatch(openSnackbarAction({ msg: getLocalizedString("certificateUnauth"), severity: "error" }));
          break;
        case "Unprocessable Entity":
          dispatch(openSnackbarAction({ msg: getLocalizedString("certificateWrongDocument"), severity: "error" }));
          break;
        default:
          dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
      }
    }
    return e.response.status;
  }
};