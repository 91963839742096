import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import LabelForm from "./LabelForm";
import { CloseIcon } from '@icarius-icons/';
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTitleDivider from '@icarius-common/dialogTitleDivider';

const LabelDialog = (props) => {

    const {
        open,
        tags,
        handleCreateLabel,
        handleRemoveLabel,
        handleEditLabel,
        handleClose,
    } = props;

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            onClose={handleClose}
            PaperComponent={PaperDraggable}
            maxWidth={"xs"}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {getLocalizedString("tagTitle")}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent style={{ paddingTop: 0 }}>
                <LabelForm
                    data={tags}
                    handleCreateLabel={handleCreateLabel}
                    handleRemoveLabel={handleRemoveLabel}
                    handleEditLabel={handleEditLabel}
                />
            </DialogContent>
        </Dialog>
    )
}

export default LabelDialog;