import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getProvinceByRegionAction,
  getRegionByCountryAction,
  getCountiesByProvinceAction,
} from "@icarius-pages/myPersonalData/actions";
import { myPersonalDataFields } from "@icarius-pages/myPersonalData/constants";
import { formatDateYYYYMMDD } from "@icarius-utils/date";

const MAX_LENGTH_LONG = 250;
const MAX_LENGTH_MEDIUM = 100;
const MAX_LENGTH_SMALL = 60;

const useHandleForm = (data, modifyCallback, invalidDataCallback, editableFields) => {

  const dispatch = useDispatch();
  const OPTIONS_FIELDS = editableFields?.filter((item) => item.type === "options").map((item) => item.name);
  const USER_FIELDS = editableFields?.filter((item) => item.is_user_field).map((item) => item.name);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (["VIS_CELULAR", "VIS_DIRECCION", "VIS_ESTADOCIVIL", "VIS_FECHANACIMIENTO"].includes(fieldName)) {
        return data?.[fieldName] || false;
      }

      // este esta dentro de los OPTIONS_FIELDS pero se maneja distinto
      if (fieldName === "ASISTENTE DE AYUDA") {
        return data?.[fieldName] || "H";
      }

      if (OPTIONS_FIELDS.includes(fieldName)) {
        if (!data?.[fieldName]) return "";

        const fieldConfig = editableFields?.find((item) => item.name === fieldName);
        return fieldConfig?.options?.find((item) => item.value === data?.[fieldName])?.id || "";
      }

      return data?.[fieldName] || "";
    }

    const fieldNames = [
      ...myPersonalDataFields.map((item) => item.name),
      "VIS_CELULAR",
      "VIS_DIRECCION",
      "VIS_ESTADOCIVIL",
      "VIS_FECHANACIMIENTO",
      "ASISTENTE DE AYUDA",
      ...(editableFields?.filter((item) => item.is_user_field) || []).map((item) => item.name), // agregar los userFields
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(() => createInitialData());
  const [fieldsWithError, setFieldsWithError] = useState([]);

  const getIsRequired = (fieldName) => {
    if (USER_FIELDS.includes(fieldName)) return editableFields.find((item) => item.name === fieldName).required;
    return false;
  }

  const dataIsValid = () => {
    let isValid = true;
    const auxFieldsWithErrors = [];

    (USER_FIELDS || []).forEach((editableField) => {
      let value = formData[editableField.name];
      // si no tiene valor
      if ((!value && value !== 0) || value?.length === 0 || /^\s+$/.test(value)) {
        if (getIsRequired(editableField.name)) {
          auxFieldsWithErrors.push(editableField.name)
          isValid = false;
        }
      } else {
        //si tiene valor, pregunto por min y max segun el type
        switch (editableField.type) {
          case "text":
            if (editableField.max && value.length > editableField.max) {
              auxFieldsWithErrors.push(editableField.name)
              isValid = false;
            }

            break;
          case "integer":
          case "float":
            if (editableField.max && value > editableField.max) {
              auxFieldsWithErrors.push(editableField.name)
              isValid = false;
            }

            if (editableField.min !== null && value < editableField.min) {
              auxFieldsWithErrors.push(editableField.name)
              isValid = false;
            }
            break;

          case "date":
            if (!(value instanceof Date && !isNaN(value))) {
              auxFieldsWithErrors.push(editableField.name)
              isValid = false;
            } else {
              if (editableField.min) {
                if (value < new Date(formatDateYYYYMMDD(editableField.min))) {
                  auxFieldsWithErrors.push(editableField.name)
                  isValid = false;
                }
              }

              if (editableField.max) {
                if (value > new Date(formatDateYYYYMMDD(editableField.max))) {
                  auxFieldsWithErrors.push(editableField.name)
                  isValid = false;
                }
              }
            }
            break;
          case "options":
            const optionOk = editableField?.options?.find((item) => item.id === value);
            if (!optionOk) {
              auxFieldsWithErrors.push(editableField.name)
              isValid = false;
            }
            break;
          default: break;
        }
      }
    });

    if (!formData["CELULAR"]) formData["CELULAR"] = "";

    if (formData["CELULAR"] && formData["CELULAR"]?.length > MAX_LENGTH_SMALL) {
      auxFieldsWithErrors.push("CELULAR")
      isValid = false;
    }

    if (!formData["DIRECCION"] || formData["DIRECCION"]?.length > MAX_LENGTH_LONG) {
      auxFieldsWithErrors.push("DIRECCIÓN")
      isValid = false;
    }

    if (formData["CODIGO POSTAL"] && formData["CODIGO POSTAL"]?.length > MAX_LENGTH_SMALL) {
      auxFieldsWithErrors.push("CODIGO POSTAL")
      isValid = false;
    }

    if (formData["LINKEDIN"] && formData["LINKEDIN"]?.length > MAX_LENGTH_MEDIUM) {
      auxFieldsWithErrors.push("LINKEDIN")
      isValid = false;
    }

    setFieldsWithError(auxFieldsWithErrors);
    return isValid;
  };

  const submit = () => {
    if (dataIsValid()) {
      let dataToSend = {};

      if (editableFields.length > 0) {
        editableFields.forEach((item) => dataToSend[item.name] = formData[item.name] || '');

        dataToSend["VIS_CELULAR"] = formData["VIS_CELULAR"];
        dataToSend["VIS_DIRECCION"] = formData["VIS_DIRECCION"];
        dataToSend["VIS_ESTADOCIVIL"] = formData["VIS_ESTADOCIVIL"];
        dataToSend["VIS_FECHANACIMIENTO"] = formData["VIS_FECHANACIMIENTO"];
        dataToSend["ASISTENTE DE AYUDA"] = formData["ASISTENTE DE AYUDA"];

        modifyCallback(dataToSend);
      }

      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if ("PAIS" === fieldName) {
      if (value === "AR") dispatch(getProvinceByRegionAction(value));
      if (value !== "AR") dispatch(getRegionByCountryAction(value));

      setFormData({
        ...formData,
        [fieldName]: value,
        "COMUNA": "",
        "PROVINCIA": "",
        "REGION": "",
      })
      return;
    }

    if ("REGION" === fieldName) {
      if (value) dispatch(getProvinceByRegionAction(value));
      setFormData({
        ...formData,
        [fieldName]: value,
        "COMUNA": "",
        "PROVINCIA": "",
      })
      return;
    }

    if ("PROVINCIA" === fieldName) {
      if (value) dispatch(getCountiesByProvinceAction(value));
      setFormData({
        ...formData,
        [fieldName]: value,
        "COMUNA": "",
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    formData,
    setFormValue,
    submit,
    getIsRequired,
    fieldsWithError,
  };
}

export default useHandleForm;