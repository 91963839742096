import React from 'react';
import { ListItem, IconButton, Input } from '@material-ui/core';
import { useNotesForm } from "@icarius-pages/notes/components/useNotesForm";
import { getLocalizedString } from "@icarius-localization/strings";
import { CheckBoxIcon } from "@icarius-icons";

const TagFormAddListItem = ({ onListItemAdd }) => {
    const {
        form,
        handleChange,
        resetForm,
    } = useNotesForm({ text: "" });

    const isFormInValid = () => {
        return form.text === '' || form.text.length === 0 || form.text.length > 70;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isFormInValid()) return;

        onListItemAdd({
            labelCode: Math.ceil(Math.random() * 100000).toString(),
            nombre: form.text,
        })
            .then((resp) => resp?.data === 200 && resetForm());
    }

    return (
        <ListItem className="p-0" dense>
            <Input
                className={"flex flex-1 mx-8"}
                name="text"
                value={form.text}
                onChange={handleChange}
                placeholder={getLocalizedString("addLabel")}
                disableUnderline
                autoFocus
                onInput={(e) => e.target.value = e.target.value.toString().slice(0, 70)}
            />
            <IconButton
                className="w-32 h-32 mx-4 p-0"
                aria-label="Check"
                disabled={isFormInValid()}
                onClick={(e) => handleSubmit(e)}
            >
                <CheckBoxIcon fontSize="small" />
            </IconButton>
        </ListItem>
    );
}

export default TagFormAddListItem;
