import * as actionTypes from "./actionTypes";

const initialState = {
  isLoadingInitial: false,
  isLoadingColaborators: false,
  isLoadingDownloadSend: false,
  isLoadingSign: false,
  locale: "es",
  dateFormat: "yyyy/mm/dd",
  collaborators: [],
  calculationProcesses: [],
  receipts: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_INITIAL:
      return { ...state, isLoadingInitial: true };
    case actionTypes.GET_INITIAL_FULFILLED:
      return {
        ...state,
        isLoadingInitial: false,
        receipts: action.payload.receipts,
        calculationProcesses: action.payload.calculationProcesses,
        collaborators: action.payload.collaborators,
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.GET_INITIAL_REJECTED:
      return { ...state, isLoadingInitial: false };

    case actionTypes.GET_COLABORATORS:
      return { ...state, isLoadingColaborators: true };
    case actionTypes.GET_COLABORATORS_FULFILLED:
      return {
        ...state,
        isLoadingColaborators: false,
        collaborators: action.payload.collaborators,
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.GET_COLABORATORS_REJECTED:
      return { ...state, isLoadingColaborators: false };

    case actionTypes.SIGN_AND_DOWNLOAD:
      return { ...state, isLoadingSign: true };
    case actionTypes.SIGN_AND_DOWNLOAD_FULFILLED:
      let newCollaborators = [...state.collaborators]
      const generatedDocuments = action.payload.docs;

      newCollaborators = newCollaborators.filter((collaborator) => !generatedDocuments.find((generatedDocument) => (
        collaborator.dataDoc.code === generatedDocument.code &&
        collaborator.dataDoc.tipoDoc === generatedDocument.tipoDoc &&
        collaborator.dataDoc.codRef === generatedDocument.codRef &&
        collaborator.dataDoc.dataCode === generatedDocument.dataCode &&
        collaborator.dataDoc.destinataries === generatedDocument.destinataries &&
        collaborator.dataDoc.approvants === generatedDocument.approvants))
      );

      return {
        ...state,
        isLoadingSign: false,
        collaborators: newCollaborators,
      };
    case actionTypes.SIGN_AND_DOWNLOAD_REJECTED:
      return { ...state, isLoadingSign: false };

    case actionTypes.DOWNLOAD:
      return { ...state, isLoadingDownloadSend: true };
    case actionTypes.DOWNLOAD_FULFILLED:
      return {
        ...state,
        isLoadingDownloadSend: false,
      };
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, isLoadingDownloadSend: false };

    case actionTypes.SEND:
      return { ...state, isLoadingDownloadSend: true };
    case actionTypes.SEND_FULFILLED:
      return {
        ...state,
        isLoadingDownloadSend: false,
      };
    case actionTypes.SEND_REJECTED:
      return { ...state, isLoadingDownloadSend: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}