import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAnnexesTypes } from "@icarius-pages/annexesPage/selectors";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { getAppColor } from "src/app/selectors";
import { createDateFromDDMMYYYY, formatDate } from "@icarius-utils/date";
import { IconButton, Grid } from "@material-ui/core";
import { getFileExtension } from "@icarius-utils/fileUpload";
import { BASE_URL } from "@icarius-connection/endpoints";
import { CloudDownloadIcon, VisibilityIcon } from "@icarius-icons";
import { downloadAnnexInServer } from "@icarius-pages/annexesPage/actions";
import { previewFromURL } from "@icarius-utils/download";
import 'react-vertical-timeline-component/style.min.css';

const Timeline = (props) => {

  const { data, getAnnexInstanceName } = props;

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);
  const types = useSelector(getAnnexesTypes);

  const getTypeName = (instance, typeToMatch) => {
    return types[instance].find(type => type.key === typeToMatch)?.value;
  };

  const onPreview = (item) => {
    previewFromURL(BASE_URL + item["url"]);
  };

  const orderedData = useMemo(() => {
    return [...data].sort((a, b) => {
      const aDate = createDateFromDDMMYYYY(a['Fecha de actualizacion']);
      const bDate = createDateFromDDMMYYYY(b['Fecha de actualizacion']);

      if (aDate < bDate) return -1;
      if (aDate > bDate) return 1;
      return 0;
    })
  }, [data])

  const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  return (
    <VerticalTimeline lineColor={color}>
      {
        orderedData.map(item => {
          const date = createDateFromDDMMYYYY(item['Fecha de actualizacion']);
          const month = date.getMonth();

          return (
            <VerticalTimelineElement
              key={item['Codigo']}
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'var(--modalBg)', color: 'var(--mainText)', boxShadow: 'none' }}
              contentArrowStyle={{ borderRight: '7px solid  var(--drawer)' }}
              date={`${monthNames[month]} - ${formatDate(date)}`}
              iconStyle={{ background: color }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h3 className="vertical-timeline-element-title" style={{ color: color }}>
                  {getAnnexInstanceName(item['Instancia'])}
                </h3>
              </div>
              <h3 className="vertical-timeline-element-title">
                {getTypeName(item['Instancia'], item['Tipo'])}
              </h3>
              <p style={{ margin: 0, fontWeight: 300 }}>
                {item['Texto']}
              </p>
              <p style={{ margin: 0, fontWeight: 500, fontStyle: 'italic' }}>
                {item['Nombre archivo']}
              </p>
              <Grid container item xs={12}>
                <Grid item>
                  <IconButton onClick={() => dispatch(downloadAnnexInServer(item["Codigo"]))}>
                    <CloudDownloadIcon htmlColor={color} />
                  </IconButton>
                </Grid>
                <Grid item>
                  {
                    getFileExtension(item["Nombre archivo"]) === "pdf" &&
                    <IconButton onClick={() => onPreview(item)}>
                      <VisibilityIcon htmlColor={color} />
                    </IconButton>
                  }
                </Grid>
              </Grid>
            </VerticalTimelineElement>
          )
        })
      }
    </VerticalTimeline>
  );
}

export default Timeline;
