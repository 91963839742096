import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  DialogContent,
  DialogActions,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { dateIsAfterToday, formatDate, createDateFromDDMMYYYY } from "@icarius-utils/date";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import moment from "moment-business-days";
import CantCreateRequestMessage from "./cantCreateRequestMessage";
import MyVacationsDialog from "@icarius-common/myVacationsDialog";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { requestsIds } from "@icarius-utils/requestsIds";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";

const gridStyle = { minHeight: 75 };

const timesPerDayArray = [
  { FldValue: "0.5", Descr: requestsIds.id_time_per_day["0.5"] },
  { FldValue: "1", Descr: requestsIds.id_time_per_day["1.0"] },
];

const vacationsTypeArray = [
  { FldValue: "UV", Descr: requestsIds.id_subtype.UV },
  { FldValue: "VA", Descr: requestsIds.id_subtype.VA },
];

const NewVacationContent = (props) => {

  const {
    isForEdit,
    isReadOnly,
    startHolidaysOnNonWorkingDay,
    allowHalfDayHolidays,
    validateDateRange,
    vacationCalculationData,
    editData,
    requestCode,
    recipients,
    hideViewAccountability,
    handleNoRecipient,
    handleRequest,
    handleClose,
  } = props;

  useEffect(() => {
    if (!isReadOnly && !isForEdit && recipients.vacation.length === 0) {
      handleNoRecipient();
      handleClose();
    }
  }, [isReadOnly, isForEdit, recipients, handleNoRecipient, handleClose])

  const dispatch = useDispatch();
  const [myVacationsDialogIsOpen, setMyVacationsDialogIsOpen] = useState(false);
  const [formData, setFormData] = useState(() => {
    const timePerDay = (() => {
      if (!allowHalfDayHolidays && !isForEdit && !isReadOnly) return "1";
      if (editData?.id_time_per_day) return parseFloat(editData?.id_time_per_day);
      return "";
    })();

    return {
      receptor: recipients?.vacation?.length === 1 ? recipients.vacation[0].code : "", //si tiene un valor solo, inicializar con ese
      type: editData?.id_subtype || "",
      date: editData?.start_date ? createDateFromDDMMYYYY(editData.start_date) : null,
      timePerDay: timePerDay,
      amountOfDays: editData?.duration_days || "",
      dateEnd: editData?.end_date ? createDateFromDDMMYYYY(editData.end_date) : null,
      description: editData?.comments || "",
    };
  })

  const setFormValue = (value, fieldName) => {
    if (fieldName === "amountOfDays") {
      setFormData((prev) => ({
        ...formData,
        [fieldName]: value,
        dateEnd: calculateEndVacationDate(prev.date, value)
      }));
      return;
    }

    if (fieldName === "type") {
      setFormData({
        ...formData,
        [fieldName]: value,
        dateEnd: null,
        amountOfDays: "",
      });
      return;
    }

    if (fieldName === "date") {
      setFormData((prev) => ({
        ...formData,
        [fieldName]: value,
        dateEnd: calculateEndVacationDate(value, prev.amountOfDays),
      }));
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  const handleCloseAndReload = (shouldReloadTable) => {
    if (isForEdit && shouldReloadTable) {
      handleClose(true);
    } else {
      handleClose();
    }
  };

  const handleCloseMyVacationsDialog = () => setMyVacationsDialogIsOpen(false);
  const handleOpenMyVacationsDialog = () => setMyVacationsDialogIsOpen(true);

  const calculateNextDay = () => {
    // A partir del siguiente dia
    let i = 1;
    let fechaBase = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + i);

    return fechaBase;
  }

  const textIsValid = () => {
    if (isForEdit) return formData.description.length > 0 && formData.description.length <= 1024;
    return !formData.description || (formData.description.length >= 0 && formData.description.length <= 1024);
  };

  const dataIsComplete = () => {
    if (!isForEdit && !isReadOnly && !formData.receptor) return false;
    if (formData.type && formData.amountOfDays && formData.timePerDay && textIsValid() && dateIsAfterToday(formData.date)) return true;
    return false;
  };

  const validateAndCreate = () => {
    if (textIsValid() && dataIsComplete()) {
      if (!startHolidaysOnNonWorkingDay && !moment(formData.date).isBusinessDay()) {
        dispatch(openSnackbarAction({
          duration: null,
          msg: "La fecha de inicio o fecha desde, no puede ser un día Sábado, Domingo o Feriado",
          severity: "error",
        }));
        return;
      }

      if (validateDateRange(formData.date, formData.dateEnd)) {
        const request = {
          code: requestCode,
          id_type: "VAC",
          id_status: "3",
          id_subtype: formData.type,
          id_time_per_day: formData.timePerDay.toString(),
          duration_days: formData.amountOfDays,
          start_date: formatDate(formData.date),
          end_date: formatDate(formData.dateEnd),
          comments: formData.description,
          receptor: formData.receptor,
        }

        if (!isForEdit) {
          delete request.code;
          delete request.id_status;
        } else {
          delete request.id_type;
          delete request.id_subtype;
          delete request.receptor;
        }

        handleRequest(request);
        if (!isForEdit) {
          handleCloseAndReload(true);
        }
      } else {
        dispatch(openSnackbarAction({ msg: getLocalizedString("datesCollide"), severity: "error" }));
      }
    } else {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("completeWithValidInformation"), severity: "error" }));
    }
  };

  const createVacationDaysArray = (typeVacation, maxAmount) => {
    let auxArray = [];
    let cont = 1;
    let totalDays;

    if (maxAmount) {
      do {
        auxArray.push({ FldValue: cont, Descr: cont });
        cont++;
      } while (cont <= maxAmount)
    } else {
      switch (typeVacation) {
        case "UV":
          totalDays = vacationCalculationData.available_legal_vacation_days;
          if (vacationCalculationData.additional_legal_days) {
            totalDays += vacationCalculationData.additional_legal_days;
          }

          break;
        case "VA": totalDays = vacationCalculationData.available_extra_vacation_days;
          break;
        default: break;
      }

      for (cont; cont <= totalDays; cont++) {
        auxArray.push({ FldValue: cont, Descr: cont });
      }
    }

    return auxArray;
  }

  const calculateEndVacationDate = (startDate, amountOfDays) => {
    if (startDate && amountOfDays) {
      if (vacationCalculationData.does_workweek_include_saturdays) {
        moment.updateLocale('es', {
          workingWeekdays: [1, 2, 3, 4, 5, 6]
        });
      } else {
        moment.updateLocale('es', {
          workingWeekdays: [1, 2, 3, 4, 5]
        });
      }

      if (!vacationCalculationData.do_vacations_include_holidays_and_weekends) {
        moment.updateLocale('es', {
          holidays: vacationCalculationData.holiday_calendar,
          holidayFormat: 'DD/MM/YYYY'
        });

        let initialDay = moment(startDate, "DD/MM/YYYY");
        // tengo que avanzar hasta el proximo dia habil, en caso de que este no lo sea
        if (!initialDay.isBusinessDay()) {
          initialDay = initialDay.nextBusinessDay();
        }

        return initialDay.businessAdd(amountOfDays - 1, 'days').toDate();
      } else {
        return moment(startDate, "DD/MM/YYYY").add(amountOfDays - 1, 'days').toDate();
      }
    }

    return null;
  }

  const vacacionDaysArray = (() => {
    if (!isForEdit && !isReadOnly) return createVacationDaysArray(formData.type);
    return createVacationDaysArray(formData.type, editData.duration_days);
  })();

  if (!isForEdit && !isReadOnly && !vacationCalculationData.can_user_appeal) return <CantCreateRequestMessage />;

  return (
    <>
      {
        myVacationsDialogIsOpen &&
        <MyVacationsDialog
          open={myVacationsDialogIsOpen}
          handleClose={handleCloseMyVacationsDialog}
        />
      }
      <>
        <DialogContent style={{ paddingTop: 0 }}>
          <Grid container direction="column" justify="center">
            {
              !isForEdit && !isReadOnly && !hideViewAccountability &&
              <Typography
                className={"whiteText"}
                variant="subtitle1"
                style={{ cursor: "pointer", textDecoration: "underline", zIndex: 100 }}
                onClick={handleOpenMyVacationsDialog}
                gutterBottom
              >
                {getLocalizedString("viewVacationAccountability")}
              </Typography>
            }
            {
              !isForEdit && !isReadOnly &&
              <Grid container item xs={12} alignItems="center" style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-receptor`}>{getLocalizedString("requestReceptor")}</InputLabel>
                  <Select
                    disabled={isForEdit || isReadOnly}
                    value={formData.receptor}
                    labelId={`label-receptor`}
                    id={`select-receptor`}
                    onChange={(e) => setFormValue(e.target.value, "receptor")}
                    margin={"none"}
                  >
                    {
                      recipients.vacation.map((item) => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.code}
                          value={item.code}
                        >
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
            <Grid container item xs={12} alignItems="center" style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required={!isReadOnly} id={`label-type`}>{getLocalizedString("vacationsType")}</InputLabel>
                <Select
                  disabled={isForEdit || isReadOnly}
                  value={formData.type}
                  labelId={`label-type`}
                  id={`select-type`}
                  onChange={(e) => setFormValue(e.target.value, "type")}
                  margin={"none"}
                >
                  {
                    vacationsTypeArray.map((item) => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.FldValue}
                        value={item.FldValue}
                      >
                        {item.Descr}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} alignItems="center" style={{ marginTop: 10 }}>
              <Grid container item xs={12} sm={6} alignItems="flex-start" style={gridStyle}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    required={!isReadOnly}
                    disabled={isReadOnly}
                    label={getLocalizedString("startDate")}
                    clearable
                    clearLabel={getLocalizedString("clear")}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    disablePast={!isForEdit}
                    minDate={!isReadOnly && calculateNextDay()}
                    format="dd/MM/yyyy"
                    margin="none"
                    invalidDateMessage=''
                    minDateMessage=''
                    maxDateMessage=''
                    value={formData.date}
                    onChange={(e) => setFormValue(e, "date")}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
                {
                  !isReadOnly &&
                  <Typography
                    className={!dateIsAfterToday(formData.date) ? "errorColor" : "whiteText"}
                    variant="caption"
                    gutterBottom>
                    {getLocalizedString("startDateValidation")}
                  </Typography>
                }
              </Grid>
              <Grid container item xs={12} sm={6} alignItems="flex-start" className="secondFieldPadding" style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required={!isReadOnly} id={`label-timePerDay`}>{getLocalizedString("timePerDay")}</InputLabel>
                  <Select
                    disabled={isReadOnly || !allowHalfDayHolidays}
                    value={formData.timePerDay}
                    labelId={`label-timePerDay`}
                    id={`select-timePerDay`}
                    onChange={(e) => setFormValue(e.target.value, "timePerDay")}
                    margin={"none"}
                  >
                    {
                      timesPerDayArray.map((item) => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.FldValue}
                          value={item.FldValue}
                        >
                          {item.Descr}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <Grid container item xs={12} sm={6} alignItems="center" style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required={!isReadOnly} id={`label-amountOfDays`}>{getLocalizedString("amountOfDays")}</InputLabel>
                  <Select
                    disabled={isReadOnly}
                    value={formData.amountOfDays}
                    labelId={`label-amountOfDays`}
                    id={`select-amountOfDays`}
                    onChange={(e) => setFormValue(e.target.value, "amountOfDays")}
                    margin={"none"}
                  >
                    {
                      vacacionDaysArray.map((item) => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.FldValue}
                          value={item.FldValue}
                        >
                          {item.Descr}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item xs={12} sm={6} className="secondFieldPadding">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    disabled
                    label={getLocalizedString("endDate")}
                    format="dd/MM/yyyy"
                    margin="none"
                    value={formData.dateEnd}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            {
              (!isForEdit && !isReadOnly) && formData.type === "UV" &&
              <Grid container item style={{ paddingBottom: 15 }}>
                <Typography
                  style={{ paddingTop: 5, color: 'red', fontWeight: 700 }}
                  variant="caption"
                  gutterBottom
                >
                  {`Saldo disponible en la cuenta corriente: ${vacationCalculationData.available_legal_vacation_days}`}
                </Typography>
              </Grid>
            }
            {
              ((isForEdit && !isReadOnly) || (isReadOnly)) &&
              formData.type === "UV" && formData.amountOfDays > vacationCalculationData.available_legal_vacation_days &&
              <Grid container item style={{ paddingBottom: 15 }}>
                <Typography
                  style={{ paddingTop: 5, color: 'red', fontWeight: 700 }}
                  variant="caption"
                  gutterBottom
                >
                  {
                    formData.amountOfDays === 1 ?
                      `El día solicitado supera el saldo disponible en la cuenta corriente en ${formData.amountOfDays - vacationCalculationData.available_legal_vacation_days} día${(formData.amountOfDays - vacationCalculationData.available_legal_vacation_days) > 1 ? "s" : ""}` :
                      `Los ${formData.amountOfDays} días solicitados superan el saldo disponible en la cuenta corriente en ${formData.amountOfDays - vacationCalculationData.available_legal_vacation_days} día${(formData.amountOfDays - vacationCalculationData.available_legal_vacation_days) > 1 ? "s" : ""}`}
                </Typography>
              </Grid>
            }
            {
              isForEdit && !isReadOnly &&
              <Grid container item style={{ paddingBottom: 15 }}>
                <Typography
                  style={{ paddingTop: 5 }}
                  className={"whiteText"}
                  variant="caption"
                  gutterBottom>
                  {getLocalizedString("howToSelectMoreDays")}
                </Typography>
              </Grid>
            }
            <Grid container item xs={12} alignItems="center" style={{ marginTop: 10 }}>
              <TextField
                required={isForEdit && !isReadOnly}
                label={getLocalizedString("detail")}
                disabled={isReadOnly}
                margin={"none"}
                value={formData.description}
                onChange={(e) => setFormValue(e.target.value.toString().slice(0, 1024), "description")}
                multiline={true}
                rows={1}
                rowsMax={3}
                fullWidth
              />
              {
                !isReadOnly &&
                <Typography
                  style={{ paddingTop: 5 }}
                  className={!textIsValid() ? "errorColor" : "whiteText"}
                  variant="caption"
                  gutterBottom>
                  {!isForEdit && `${getLocalizedString("optionalField")}. `}
                  {`${getLocalizedString("currentChar")} ${formData.description ? formData.description.length : 0}. ${getLocalizedString("maxCharRequests")}.`}
                </Typography>
              }
            </Grid>
          </Grid>
        </DialogContent>
        {
          isReadOnly &&
          <div style={{ marginTop: 32 }} />
        }
        {
          !isReadOnly &&
          <DialogActions>
            <ButtonDialogAction
              onClick={handleCloseAndReload}
              text={getLocalizedString("disagree")}
            />
            <ButtonDialogAction
              onClick={validateAndCreate}
              text={getLocalizedString("agree")}
              isAccept
            />
          </DialogActions>
        }
      </>
    </>
  );
}

export default NewVacationContent;