import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { withStyles } from "@material-ui/core/styles";
import DialogTransition from "@icarius-common/dialogTransition";

const styles = () => ({
  cssTextAreaInput: {
    fontSize: "1.3rem",
    fontWeight: "100",
  },

  cssTitleInput: {
    fontSize: "1.5rem",
    fontWeight: "400",
  },
});

const DuplicateDialog = (props) => {

  const {
    open,
    data,
    handleSubmit,
    handleClose,
    classes,
  } = props;

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  const dispatch = useDispatch();

  const validateAndConfirm = () => {
    if (text !== "" && title !== "") {
      handleSubmit({ ...data, texto: text, titulo: title });
      handleClose();
    } else {
      dispatch(openSnackbarAction({ msg: getLocalizedString("fieldRequeriment"), severity: "error" }));
    }
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"md"}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("duplicate")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ marginTop: -25 }}>
        <Grid container justify="center" alignItems="center" direction="column">
          <div className="p-8" style={{ width: "100%" }}>
            <TextField
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value.toString().slice(0, 100))}
              placeholder={getLocalizedString("title")}
              className="font-bold"
              InputProps={{
                classes: {
                  root: classes.cssTitleInput,
                },
              }}
            />
          </div>
          <div className="p-8" style={{ width: "100%" }}>
            <TextField
              fullWidth
              value={text}
              onChange={(e) => setText(e.target.value)}
              multiline={true}
              rows={4}
              placeholder={getLocalizedString("writeHereYourNote")}
              rowsMax={4}
              InputProps={{
                classes: {
                  root: classes.cssTextAreaInput,
                },
              }}
            />
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={validateAndConfirm} isAccept text={getLocalizedString("duplicate")} />
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(DuplicateDialog);