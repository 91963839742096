import React from "react";
import QRCode from "qrcode.react";
import { getLocalizedString } from "@icarius-localization/strings";
import { Typography } from "@material-ui/core";

const QR = ({ value, hideLabel, size }) => {
  return (
    <div className={"qr-container"}>
      {
        !hideLabel && 
        <Typography variant={"subtitle1"} className={"whiteText"}>
          {getLocalizedString("contactQr")}
        </Typography>
      }
      <QRCode className={"borderQRCode"} value={value || ""} size={size} />
    </div>
  )
}

export default QR;
