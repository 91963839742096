import React from "react";
import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import Loader from "@icarius-common/loader";
import Content from "./content";

const StyledDialog = withStyles({
  paper: {
    minWidth: "90%",
  },
})(Dialog);

const EmployeeVacationsDialog = (props) => {

  const {
    open,
    isLoading,
    creditData,
    debitData,
    miscData,
    vacationsSchema,
    dateFormat,
    handleClose,
    employeeCode,
    employeeName,
    contractDate,
    vacationsUpdateDate,
  } = props;

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <Loader open={isLoading} />
      <Content
        dateFormat={dateFormat}
        debitData={debitData}
        miscData={miscData}
        creditData={creditData}
        vacationsSchema={vacationsSchema}
        employeeCode={employeeCode}
        employeeName={employeeName}
        contractDate={contractDate}
        vacationsUpdateDate={vacationsUpdateDate}
      />
    </StyledDialog>
  );
}

export default EmployeeVacationsDialog;
