import React, { useState } from 'react';
import { useMediaQuery, Grid, TextField } from '@material-ui/core';
import { getLocalizedString } from "@icarius-localization/strings";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DefaultImage from "@icarius-assets/images/no_profile_dark.png";
import { useSelector, useDispatch } from "react-redux";
import { getAppColor, getAvatar } from "src/app/selectors";
import { createNoteCommentAction } from "../../actions";
import { XS_DEVICE_WIDTH_BREAKPOINT } from "@icarius-utils/constants";

const onMediaFallback = event => event.target.src = DefaultImage;

const NewCommentBox = ({ codNota }) => {

    const [comment, setComment] = useState("");

    const dispatch = useDispatch();
    const avatar = useSelector(getAvatar);
    const color = useSelector(getAppColor);

    const useFullSizeCommentBox = useMediaQuery(`(max-width:${XS_DEVICE_WIDTH_BREAKPOINT}px)`);

    const handleGenerate = () => {
        // Si tengo algo, entonces lo mando
        if (comment !== "" && codNota) {
            dispatch(createNoteCommentAction(codNota, comment));
            setComment("");
        }
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={2} sm={1}>
                    {
                        avatar && avatar !== "-" && avatar !== "" ?
                            <img src={IMAGES_ENDPOINT + avatar} alt="error" onError={onMediaFallback} className={"employee-image"} style={{ height: 50, width: 50 }} />
                            : <div className={"employee-image"} style={{ background: color, height: 50, width: 50 }} />
                    }
                </Grid>
                <Grid item xs={10} sm={11}>
                    <Grid container>
                        <Grid item xs={12} sm={10} style={{ paddingLeft: 20, paddingRight: useFullSizeCommentBox ? 0 : 20 }}>
                            <TextField
                                fullWidth
                                placeholder={getLocalizedString("writeHereYourComment")}
                                multiline={true}
                                rows={2}
                                value={comment}
                                onChange={(e) => setComment(e.target.value.toString())}
                            />
                        </Grid>
                        <Grid container item xs={12} sm={2} justify="flex-end" style={{ paddingTop: 10 }}>
                            <ButtonDialogAction
                                onClick={handleGenerate}
                                text={getLocalizedString("commentAction")}
                                isAccept
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default NewCommentBox;
