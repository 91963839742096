import { useMemo, useState } from "react";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { useDispatch } from "react-redux";

const MAX_LENGTH_LONG = 250;

const useHandleForm = (data, modifyCallback) => {

  const [formData, setFormData] = useState(() => {
    const auxValues = {};

    Object.keys(data).forEach((section) => {

      data[section].forEach((sectionFields) => {
        Object.keys(sectionFields).forEach((sectionColumnIndex) => {

          const fieldName = Object.keys(sectionFields[sectionColumnIndex])[0];
          if (sectionFields?.[sectionColumnIndex]?.[fieldName]?.["value"] !== undefined) {
            auxValues[fieldName] = sectionFields[sectionColumnIndex][fieldName]["value"];
          }
        });
      })
    });

    return auxValues;
  });

  const IMAGE_FIELDS = useMemo(() => {
    return Object.values(data)
      .flat(2)
      .filter((item) => Object.keys(item)?.length && Object.values(item)[0].type === "image")
      .map((item) => Object.keys(item)[0]);
  }, [data]);

  const TEXT_FIELDS = useMemo(() => {
    return Object.values(data)
      .flat(2)
      .filter((item) => Object.keys(item)?.length && Object.values(item)[0].type === "text")
      .map((item) => Object.keys(item)[0]);
  }, [data]);

  const INTEGER_FIELDS = useMemo(() => {
    return Object.values(data)
      .flat(2)
      .filter((item) => Object.keys(item)?.length && Object.values(item)[0].type === "integer")
      .map((item) => Object.keys(item)[0]);
  }, [data]);

  const dispatch = useDispatch();

  const fileIsValidImage = (file) => {
    return (
      file.type.includes("/png") ||
      file.type.includes("/jpg") ||
      file.type.includes("/jpeg") ||
      file.type.includes("/bmp") ||
      file.type.includes("/gif")
    )
  }

  const valuesAreValid = () => {
    let errorMessage = "";
    if (!(formData["CLIENTE"].length > 0 && formData["CLIENTE"].length <= MAX_LENGTH_LONG)) {
      errorMessage = getLocalizedString("fillClientGeneralSettings")
    }

    if (formData["STOP"] === true) {
      if (!(formData["STOPMSG"].length > 0 && formData["STOPMSG"].length <= MAX_LENGTH_LONG)) {
        errorMessage = getLocalizedString("fillStopMsgGeneralSettings")
      }

      if (!(formData["STOPTIME"].length > 0 && formData["STOPTIME"].length <= MAX_LENGTH_LONG)) {
        errorMessage = getLocalizedString("fillStopTimeGeneralSettings")
      }
    }

    if (!(formData["EMAILRRHH"].length > 0 && formData["EMAILRRHH"].length <= MAX_LENGTH_LONG)) {
      errorMessage = getLocalizedString("fillHREmailGeneralSettings")
    }

    if (!(formData["GO_DTO_RRHH"].length > 0 && formData["GO_DTO_RRHH"].length <= MAX_LENGTH_LONG)) {
      errorMessage = getLocalizedString("fillHRDTOGeneralSettings")
    }

    if (!(formData["GO_EMAIL"].length > 0 && formData["GO_EMAIL"].length <= MAX_LENGTH_LONG)) {
      errorMessage = getLocalizedString("fillContactEmailGeneralSettings")
    }

    const PORMAXANT_regex = /^((100)|(\d{1,2}))%?$/;
    if (!(formData["PORMAXANT"].length > 0 && formData["PORMAXANT"].length <= 4 && PORMAXANT_regex.test(formData["PORMAXANT"]))) {
      errorMessage = getLocalizedString("fillMaxPercentageAdvance")
    }

    if (formData["FIRMADIGITAL"] === true) {
      if (formData["DISCLAMER"].length === 0) {
        errorMessage = getLocalizedString("fillDisclaimerGeneralSettings")
      }
      if (!(formData["FIRMACOL"].length > 0 && formData["FIRMACOL"].length <= MAX_LENGTH_LONG)) {
        errorMessage = getLocalizedString("fillFirmaColGeneralSettings")
      }
    }

    if (formData["GEO_DIVISION1"].length === 0) {
      errorMessage = "El primer nivel de organización geográfica es obligatorio"
    }

    if (formData["GEO_DIVISION2"].length === 0) {
      errorMessage = "El segundo nivel de organización geográfica es obligatorio"
    }

    if (!(formData["GEO_DIVISION1"].length > 0 && formData["GEO_DIVISION1"].length <= MAX_LENGTH_LONG)) {
      errorMessage = "Verifique el primer nivel de organización geográfica"
    }

    if (!(formData["GEO_DIVISION2"].length > 0 && formData["GEO_DIVISION2"].length <= MAX_LENGTH_LONG)) {
      errorMessage = "Verifique el segundo nivel de organización geográfica"
    }

    if (formData["GEO_DIVISION3"]?.length > 0 && formData["GEO_DIVISION3"]?.length >= MAX_LENGTH_LONG) {
      errorMessage = "Verifique el tercer nivel de organización geográfica"
    }

    if (errorMessage === "") return true;

    dispatch(openSnackbarAction({ msg: errorMessage, severity: "error" }));
    return false;
  };

  const submit = () => {
    let dataToSend = {};

    if (data) {
      Object.keys(data).forEach((key) => {

        data[key].forEach((g) => {

          Object.keys(g).forEach((innerKey) => {

            const propName = Object.keys(g[innerKey])[0];
            dataToSend[propName] = formData[propName] || "";
          });
        })
      });
    }

    if (valuesAreValid(dataToSend)) {
      modifyCallback(dataToSend);
    }
  };

  const setFormValue = (value, fieldName) => {
    if (IMAGE_FIELDS.includes(fieldName)) {
      const { originalFile, og64 } = value;

      if (originalFile.size > 5 * 1024 * 1024) {
        dispatch(openSnackbarAction({ msg: getLocalizedString("imageSizeExceeded"), severity: "error" }));
        return;
      }

      if (!fileIsValidImage(originalFile)) {
        dispatch(openSnackbarAction({ msg: getLocalizedString("fileMustBeImage"), severity: "error" }));
        return;
      }

      setFormData({
        ...formData,
        [fieldName]: og64,
      });
      return;
    }

    if (INTEGER_FIELDS.includes(fieldName)) {
      setFormData({
        ...formData,
        [fieldName]: value.toString().slice(0, 3),
      });
      return;
    }

    if (TEXT_FIELDS.includes(fieldName)) {
      if (fieldName === "CLIENTE") {
        setFormData({
          ...formData,
          [fieldName]: value.toUpperCase(),
        });
        return;
      }

      setFormData({
        ...formData,
        [fieldName]: value.toString().slice(0, 511),
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  return {
    formData,
    setFormValue,
    submit,
  }
}

export default useHandleForm;
