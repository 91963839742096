import React from "react";
import { Grid } from "@material-ui/core";
import DynamicInput from "@icarius-common/dynamicInputs";
import PlayButton from "@icarius-common/audioPlayer/components/playButton";
import { getLocalizedString } from "@icarius-localization/strings";
import SectionContainer from "./sectionContainer";

const GenericSection = (props) => {

    const {
        title,
        subtitle,
        sectionFields,
        formData,
        setFormValue,
        fullSizeColumn,
    } = props;

    const getAudioPlayer = (name) => {
        switch (name) {
            case "DISCLAMER":
                return (
                    <PlayButton audioName="Consentimiento para firma digital" title="Consentimiento para firma digital" />
                );
            case "GRUPOEMP":
                return (
                    <PlayButton audioName="Grupo para acceso de administradores" title="Grupo para acceso de administradores" />
                );
            default: return null;
        }
    }

    return (
        <SectionContainer
            title={title}
            subtitle={subtitle}
        >
            {
                sectionFields.map((fieldsByColumn, columnIndex) => {

                    return (
                        <Grid item xs={12} md={fullSizeColumn ? 12 : 6} key={columnIndex}>
                            {
                                fieldsByColumn.map((item, itemIndex) => {
                                    const field = Object.values(item)[0];
                                    const fieldName = Object.keys(item)[0];


                                    if (!field || !field.type) {
                                        return (
                                            <Grid
                                                key={itemIndex}
                                                container item alignItems="center" xs={12}
                                                style={{ paddingBottom: 15 }}
                                            >
                                                <div style={{ height: 48 }} />
                                            </Grid>
                                        )
                                    }

                                    if (fieldName === "DELETE_PROCESS_SQL") return null; // se hardcodea por fuera

                                    return (
                                        <Grid
                                            key={itemIndex}
                                            container item alignItems="center" xs={12}
                                            style={{ paddingBottom: 15 }}
                                        >
                                            <DynamicInput
                                                fieldConfig={{
                                                    name: fieldName,
                                                    type: field.type,
                                                    editable: true,
                                                    options: field.options,
                                                    audioPlayer: getAudioPlayer(fieldName),
                                                }}
                                                label={field.name || getLocalizedString(fieldName)}
                                                value={formData[fieldName]}
                                                handleChange={setFormValue}
                                            />
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    )

                })
            }
        </SectionContainer>
    )
}

export default GenericSection;