import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogTitle, DialogContent, Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import paths from "@icarius-localization/paths";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { PrintIcon, FormatListBulleted } from "@icarius-icons";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  createDateFromDDMMYYYY,
  formatDateYYYYMMDD,
} from "@icarius-utils/date";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getUserData } from "src/app/selectors";
import CommonPage from "@icarius-common/commonPage";
import HolidaysDateRenderer from "@icarius-table/renderersAndFilters/holidaysDateRenderer";
import TabMenu from "@icarius-common/tabMenu";
import { zeroPad } from "@icarius-utils/format";
import { downloadVacationsReceiptAction, downloadVacationsSummaryAction, deleteEmployeeVacationsAction } from "../../actions";
import BalanceData from "../balanceData";
import CreateEditDialog from "../createEditDialog";
import GraphsDialog from "../graphsDialog";

const SecondGridDialogContent = (props) => {

  const [optionToShow, setOptionToShow] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [graphsDialogIsOpen, setGraphsDialogIsOpen] = useState(false);
  const [graphDialogData, setGraphDialogData] = useState(null);

  const ownGridRef = useRef();
  const userData = useSelector(getUserData);

  const {
    dateFormat,
    debitData,
    creditData,
    miscData,
    playAudioTitle,
    vacationsSchema,
    vacationRequestButton,
    employeeName,
    employeeCode,
    vacationsUpdateDate,
    contractDate,
  } = props;

  const dispatch = useDispatch();

  const createGraphData = (gridRef) => {
    let canGetEveryRowData = true;
    let filteredRows = [];

    gridRef.api.forEachNodeAfterFilter((row) => {
      if (!(row && row.data)) {
        canGetEveryRowData = false;
      } else {
        filteredRows.push(row);
      }
    });

    if (!canGetEveryRowData || !filteredRows.length) return null;

    const legalOthersVacations = {
      title: "Vacaciones legales y otros",
      data: {
        "Total utilizadas": miscData.credit_total_used_legal_vacations - miscData.credit_total_unused_legal_days,
        "Saldo Pendiente": miscData.debit_legal_available_days,
        "Total reservadas para uso a futuro": miscData.credit_total_unused_legal_days,
        "Total de vacaciones legales y otros": miscData.credit_total_legal_days + miscData.credit_total_other_days,
      },
    };

    const additionalVacations = {
      title: "Vacaciones adicionales",
      data: {
        "Total utilizadas": miscData.credit_total_used_additional_vacations - miscData.credit_total_unused_additional_days,
        "Saldo Pendiente": miscData.debit_additional_available_days,
        "Total reservadas para uso a futuro": miscData.credit_total_unused_additional_days,
        "Total de vacaciones adicionales": miscData.credit_total_additional_days,
      },
    };

    return [legalOthersVacations, additionalVacations];
  };

  const handleDownloadPDF = () => {
    dispatch(downloadVacationsSummaryAction({ employeeCode }));
  };

  const handleOpenGraphs = (gridRef) => {
    setGraphDialogData(createGraphData(gridRef));
    setGraphsDialogIsOpen(true);
  };

  const handleCloseGraphs = () => {
    setGraphsDialogIsOpen(false);
    setGraphDialogData(null);
  };

  const handleOpenDetail = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos un item", severity: "warning" }));
      return;
    }

    const dataToSend = selectedRows.map((item) => {
      return ({
        date: formatDateYYYYMMDD(createDateFromDDMMYYYY(item.start_date)),
        employeeCode,
      })
    })

    dispatch(downloadVacationsReceiptAction(dataToSend));
  };

  const handleOpenDeleteDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una fila", severity: "warning" }));
      return;
    }

    let formData = selectedRows[0];
    let dataToSend = {
      'year': formData["year"],
      'month': formData["months"] || formData["month"],
      'legalDays': formData["legal_days"] || formData["legalDays"],
      'additionalDays': formData["additional_days"] || formData["additionalDays"],
      'otherDays': formData["other_days"] || formData["otherDays"],
      'description': formData["description"],
      'userCode': employeeCode
    };

    if (dataToSend.month) {
      dataToSend.month = zeroPad(dataToSend.month, 2);
    }

    dispatch(openDialogAction({
      title: "Atención",
      msg: "¿Está seguro que quiere eliminar el período de vacaciones?",
      onConfirm: () => dispatch(deleteEmployeeVacationsAction(dataToSend)),
    }));
  }

  const handleCloseCreateEditDuplicateDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedRow(null);
  }

  const handleOpenCreateDialog = () => {
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una fila", severity: "warning" }));
      return;
    }

    setSelectedRow(selectedRows[0]);
    setEditDialogIsOpen(true);
  }

  const isEmployer = userData?.level === "E";

  const downloadButton = () =>
    <CustomIconButton
      title={'Imprimir cuenta corriente'}
      onClick={handleDownloadPDF}
    >
      <FormatListBulleted />
    </CustomIconButton>

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handleOpenCreateDialog(ownGridRef.current)}
      type={"add"}
    />
  )

  const modifyButton = () => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handleOpenEditDialog(ownGridRef.current)}
      type={"edit"}
    />
  )

  const deleteButton = () => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handleOpenDeleteDialog(ownGridRef.current)}
      type={"delete"}
    />
  )

  const chartButton = () => creditData.length > 0 &&
    <CustomIconButton
      title={getLocalizedString("charts")}
      onClick={() => handleOpenGraphs(ownGridRef.current)}
      type={"graphics"}
    />

  const detailButton = () => optionToShow === 1 &&
    <CustomIconButton
      title={'Generar comprobante de uso'}
      onClick={() => handleOpenDetail(ownGridRef.current)}
    >
      <PrintIcon />
    </CustomIconButton>

  let customItems = (() => {
    let auxCustomItems = [detailButton, downloadButton, chartButton];

    if (vacationRequestButton) {
      auxCustomItems = [vacationRequestButton, ...auxCustomItems];
    }

    if (isEmployer && optionToShow === 0) {
      auxCustomItems = [createButton, modifyButton, deleteButton, ...auxCustomItems];
    }

    return auxCustomItems;
  })();

  const helpItems = [
    <MenuItemWithIcon
      key={"a"}
      value={"/resources/ICARIUS - Cuenta corriente de vacaciones.pdf"}
      text={getLocalizedString("ctaCteVacacionesInstructivo")}
      type={"file"}
    />,
  ];

  const customHeader = () => <Typography className="whiteText" style={{ fontSize: "16px", fontWeight: "500" }}>
    {contractDate ? "Fecha de contratación: " + contractDate : "Sin fecha de contratación registrada "} - {vacationsUpdateDate ? "Fecha de actualización de la Cta.Cte.: " + vacationsUpdateDate : "Sin fecha de actualización registrada"}
  </Typography>;

  return (
    <>
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        <div style={{ width: "95%", display: "flex", alignItems: "center", gap: 10 }}>
          {`${getLocalizedString("vacationsTitleLong")}${employeeName ? `: ${employeeName}` : ''}`}
          {Boolean(playAudioTitle) && playAudioTitle}
        </div>
      </DialogTitle >
      <DialogContent>
        <TabMenu
          options={['Días ganados', 'Días utilizados', 'Saldo']}
          optionSelected={optionToShow}
          onClick={setOptionToShow}
        />
        {
          optionToShow !== 2 &&
          <CommonPage
            customHeader={customHeader}
            ownGridRef={ownGridRef}
            gridTitle={getLocalizedString("loansTitleLong")}
            columnDefPage={
              optionToShow === 0
                ? paths.vacationsDetailCredit
                : paths.vacationsDetailDebit
            }
            rowData={optionToShow === 0 ? creditData : debitData}
            helpItems={helpItems}
            menuItems={customItems}
            dateFormat={dateFormat}
            gridHeight={'65vh'}
            ignoreProcessesbyPath
            hasHelp
            frameworkComponents={{ HolidaysDateRenderer }}
          />
        }
        {
          optionToShow === 2 &&
          <div style={{ margin: '30px 0px' }}>
            <BalanceData data={miscData} />
          </div>
        }
      </DialogContent>
      {
        graphsDialogIsOpen &&
        <GraphsDialog
          open={graphsDialogIsOpen && creditData.length > 0}
          data={graphDialogData}
          handleClose={handleCloseGraphs}
        />
      }
      {
        (createDialogIsOpen || editDialogIsOpen) &&
        <CreateEditDialog
          open={(createDialogIsOpen || editDialogIsOpen)}
          data={selectedRow}
          handleClose={handleCloseCreateEditDuplicateDialog}
          userCode={employeeCode}
          vacationsSchema={vacationsSchema}
        />
      }
    </>
  );
}

export default SecondGridDialogContent;