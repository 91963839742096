import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAudioPlayerIsOpen, getAudioName, getTitle, getAssistantVoiceType } from "../selectors";
import { closeAudioPlayerAction } from "../actions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import ReactAudioPlayer from 'react-audio-player';
import { CloseIcon } from "@icarius-icons";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { AUDIO_ENDPOINT } from "@icarius-connection/endpoints";

const AudioPlayer = () => {

  const dispatch = useDispatch();

  const open = useSelector(getAudioPlayerIsOpen);
  const audioName = useSelector(getAudioName);
  const title = useSelector(getTitle);
  const voiceType = useSelector(getAssistantVoiceType);

  const path = AUDIO_ENDPOINT + "/" + (voiceType === "H" ? "Hombre_" : "Mujer_") + audioName + ".mp3";

  const handleClose = () => {
    dispatch(closeAudioPlayerAction());
  };

  return (
    <Dialog
      open={open}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
      onClose={handleClose}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title || "Audio"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <ReactAudioPlayer src={path} controls />
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default AudioPlayer;