import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Grid,
    TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const CancelDialog = (props) => {

    const {
        open,
        code,
        handleAccept,
        handleCloseDialog,
    } = props;

    const [comment, setComment] = useState("");

    const textIsInvalid = () => {
        return comment.length > 1024;
    };

    const validateAndConfirm = () => {
        if (textIsInvalid()) return;
        handleConfirm();
    };

    const handleConfirm = () => {
        const request = {
            code: code,
            id_status: "2",
            comments: comment,
        }

        handleAccept(request);
        handleCloseDialog();
    };

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            open={open}
            maxWidth={"xs"}
            fullWidth={true}
        >
            <div className={"dialog-container"}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {getLocalizedString("cancelRequestTitle")}
                    <DialogTitleDivider />
                </DialogTitle>
                <CloseIcon className={"dialog-close-icon icon"} onClick={handleCloseDialog} />
                <DialogContent style={{ paddingTop: 0 }}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={getLocalizedString("insertComment")}
                                multiline={true}
                                rows={1}
                                rowsMax={3}
                                value={comment}
                                onChange={(e) => setComment(e.target.value.toString().slice(0, 1024))}
                            />
                            <Typography
                                style={{ paddingTop: 5 }}
                                className={"whiteText"}
                                variant="caption"
                                gutterBottom
                            >
                                {`${getLocalizedString("optionalField")}. ${getLocalizedString("currentChar")} ${comment ? comment.length : 0}. ${getLocalizedString("maxCharRequests")}.`}
                            </Typography>
                            <DialogActions>
                                <ButtonDialogAction
                                    onClick={validateAndConfirm}
                                    text={getLocalizedString("submit")}
                                    isAccept
                                />
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
}

export default CancelDialog;