import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import TagFormList from "../taglist/TagFormList";

const LabelForm = (props) => {

    const {
        data,
        handleCreateLabel,
        handleRemoveLabel,
        handleEditLabel,
    } = props;

    const [tags, setTags] = useState(data);

    const handleChecklistChange = (tags) => {
        setTags(tags);
    }

    const addItem = (item) => {
        if (!item) return;
        return handleCreateLabel(item)
    }

    const editItem = (item) => {
        if (!item) return;
        handleEditLabel(item)
    }

    const removeItem = (labelCode) => {
        if (!labelCode) return;
        handleRemoveLabel(labelCode)
    }

    return (
        <Grid container direction="row" justify="center">
            <TagFormList
                tags={tags}
                addItem={addItem}
                editItem={editItem}
                removeItem={removeItem}
                onCheckListChange={handleChecklistChange}
            />
        </Grid>
    )
}

export default LabelForm;